<template>
  <div class="price-analysis">
    <div class="price-analysis__container">
      <div class="row only-print p-4">
        <div class="col text-center">
          <img class="logo" style="width:100px;" :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/logo.png')"/>
        </div>
      </div>
      <div class="row only-print">
        <div class="col">
          <p style="color: #605F5F;font-size: 2.5em; padding: 0px;margin: 0px">Nome do Produto:</p>
          <span style="color: #605F5F;font-size: 2.5em;">{{filtroProduto ? filtroProduto : "Todos"}}</span>
        </div>
        <div class="col">
          <p style="color: #605F5F;font-size: 2.5em; padding: 0px;margin: 0px">Requisitante:</p>
          <span style="color: #605F5F;font-size: 2.5em;">{{getNomeRequisitante ? getNomeRequisitante : "Todos"}}</span>
        </div>
        <div class="col">
          <p style="color: #605F5F;font-size: 2.5em; padding: 0px;margin: 0px">Fornecedor:</p>
          <span style="color: #605F5F;font-size: 2.5em;">{{getNomeFornecedor ? getNomeFornecedor : "Todos"}}</span>
        </div>
        <div class="col">
          <p style="color: #605F5F;font-size: 2.5em; padding: 0px;margin: 0px">Categoria:</p>
          <span style="color: #605F5F;font-size: 2.5em;">{{getNomeCategoria ? getNomeCategoria : "Todos"}}</span>
        </div>
      </div>
      <div class="page-buttons-container__top not-print">
        <div class="price-analysis__actions-container">
          <div class="reports-page__title-button-total action-buttons-width">
              <!-- <img class="preorder-seller-title-button-excel-icon" src="../../../assets/database-icon.png" alt="excel"> -->
              Valor Total: R$ {{formartNumber(totalPedido)}}

          </div>
          <div :class="{'report-total-minus': diffTotal < 0, 'report-total-plus': diffTotal >= 0}" class="reports-page__title-button-total action-buttons-width">
            <div style="display: flex; align-items: center;">
              <!-- <img class="preorder-seller-title-button-excel-icon" src="../../../assets/database-icon.png" alt="excel"> -->
              {{ diffTotal < 0 ? 'Economia' : 'Aumento' }}: R$ {{formartNumber(diffTotal)}}
            </div>
          </div>
          <p class="report-extra-info-colum reports-page__title-button-total report-extra-info-purple">Itens Comprados: {{ total_items }}</p>
          <p class="report-extra-info-colum reports-page__title-button-total report-extra-info-yellow">Itens com Rebaixa: {{ varLess }}<br> Itens com Aumento: {{ varBig }}</p>
          <p class="report-extra-info-colum reports-page__title-button-total report-extra-info-red">Primeira Compra: {{ countFirst }}<br> Preço Mantido: {{ varEqual }}</p>





        </div>
      </div>
      <div class="page-buttons-container__bottom not-print">
        <StandardInput class="standard-input-width"
                        :class="{'desktop': !showMore}"
                         :title="'Cotação'"
                         :action="setQuotationValue"
                         :width="'26.5vw; margin-right: 1vw;'"
                         :type="'text'"
                         :placeholder="'Digite o nº da cotação'"/>
        <StandardInput class="standard-input-width"
                        :class="{'desktop': !showMore}"
                       :title="'Empresa'"
                       :action="setRequisitanteValue"
                       :width="'22%'"
                       :type="'select'"
                       :list="$store.state.requisitantesFiltroArray"
                       :placeholder="'Selecione a empresa'"/>
        <StandardInput class="standard-input-width"
                        :title="'Variação de Custo'"
                        :action="setValuePrice"
                        :width="'22%'"
                        :type="'select'"
                        :list="priceEffectsArray"
                        :placeholder="'Selecione a variação'"/>
        <StandardInput class="standard-input-width"
                       :title="'Fornecedor'"
                        :class="{'desktop': !showMore}"
                       :action="setFornecedorValue"
                       :width="'22%'"
                       :type="'select'"
                       :list="$store.state.fornecedorFiltroArray"
                       :placeholder="'Selecione o fornecedor'"/>
      </div>


      <div style="margin-top: -4vh;" class="price-analysis__actions-container not-print">
        <StandardInput class="standard-input-width action-buttons-width desktop"
                       :title="'Categoria'"
                       :action="setCategoriaValue"
                       :width="'22%'"
                       :type="'select'"
                       :list="$store.state.categoriasFiltroArray"
                       :placeholder="'Selecione a categoria'"/>
        <StandardInput class="standard-input-width action-buttons-width"
                            :title="'Produto'"
                            :action="setValue"
                            :width="'22%'"
                            :type="'text'"
                            :placeholder="'Digite o nome'"/>
          <Button class="action-buttons-width" title="Imprimir produtos" :text="'Imprimir'" :iconName="'print'" :action="print"/>
          <div @click="getProdutosExcel" class="preorder-seller-title-button-excel action-buttons-width">
            <div style="display: flex; align-items: center;">
              <img class="preorder-seller-title-button-excel-icon" src="@/assets/excel.svg" alt="excel">
              Exportar Excel
            </div>
          </div>
          <Button class="action-buttons-width" title="Limpar Filtros" :text="'Limpar Filtros'" :iconName="'cancel'" :action="reset_filter"/>
      </div>


      <div v-if="showMore" class="page-buttons-container__bottom not-print">
        <StandardInput class="standard-input-width mobile"
                       :title="'Categoria'"
                       :action="setCategoriaValue"
                       :width="'100%'"
                       :type="'select'"
                       :list="$store.state.categoriasFiltroArray"
                       :placeholder="'Selecione a categoria'"/>

              <div style="width: 22%;"></div>
      </div>


      <div class="page-selected-filters-container not-print">
        <div v-if="filtroCotacao" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroCotacao }}</div>
          <span @click="reset_filter()"
            class="material-icons page-selected-filters-icon"
            >close</span
          >
        </div>
      </div>


      <!-- <div class="page-selected-filters-container">
        <div v-if="filtroProduto" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroProduto }}</div>
          <span v-on:click="setValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="filtroCotacao" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroCotacao }}</div>
          <span v-on:click="setQuotationValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
      </div> -->
      <cc-loader-full v-if="loading"/>
      <div v-else>
        <no-data-available v-if="produtos.length == 0 && !loading" :text="'Nenhum resultado encontrado'" />
        <div class="container-fluid">
        <div v-if="!tipoCategoria && !tipoFornecedor && !showTable" class="page-table-container">
          <div v-if="produtos.length > 0 && !loading" class="page-table-content">
            <div class="page-table-header">
              <div class="page-table-header-text category-width">EAN</div>
              <div class="page-table-header-text qty-width">
                <cc-label-sort :showicon="ordernar=='p.pro_descricao'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Descrição'" @click="setFiltroOrder('p.pro_descricao', 'desc')"/>
              </div>

              <div class="page-table-header-text actual-price-width total-val-width-print">Qtd.</div>
              <div class="page-table-header-text actual-price-width">Prc. Atual</div>
              <div class="page-table-header-text total-val-width">Marca</div>
              <div class="page-table-header-text last-price-width">Prc. Ult. Compra</div>
              <div class="page-table-header-text total-val-width">Marca Ult. Compra</div>
              <div class="page-table-header-text actual-price-width not-print">Diferença</div>
              <div class="page-table-header-text actual-price-width actual-price-width-print only-print">Dif.</div>

              <div class="page-table-header-text actual-price-width">
                <cc-label-sort :showicon="ordernar=='variacao'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Variação'" @click="setFiltroOrder('variacao', 'desc')"/>
              </div>
              <div class="page-table-header-text new-price-width">Novo Preço</div>
            </div>
            <div v-for="(produto, idx) in produtos"
                :class="getSpecialBackground(idx)"
                :key="'pri-fil-' + idx">
              <div class="page-table-row-container">
                <!-- desktop -->
                <div class="page-table-row desktop">
                  <div class="page-table-desc-column category-width">
                    {{produto.pro_ean}}
                  </div>
                  <div class="page-table-desc-column qty-width">
                    {{produto.pro_descricao}}
                  </div>
                  <div class="page-table-desc-column actual-price-width">
                    {{produto.quantidade}}
                  </div>
                  <div class="page-table-desc-column actual-price-width">
                    {{produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produto.valorUnitario) : formartNumber(produto.valorUnitario)}}
                  </div>
                  <div class="page-table-desc-column total-val-width">
                    {{produto.marca ? produto.marca : "--"}}
                  </div>

                  <div class="page-table-desc-column last-price-width">
                    <span v-show="produto.preco_ultima_compra" @click="show_orders(produto)" title="Histórico de Pedidos"
                        class="material-icons-outlined makeorder-page-table-uniprice-icon not-print">assignment</span>
                    {{ formartNumber(produto.preco_ultima_compra)}}
                  </div>
                  <div class="page-table-desc-column total-val-width">
                    {{produto.marca_ultima_compra ? produto.marca_ultima_compra : "--"}}
                  </div>
                  <div class="page-table-desc-column actual-price-width">
                    {{ formartNumber(produto.diff) }}
                  </div>
                  <div class="page-table-desc-column actual-price-width" :class="getDiffColor(produto.diff)">
                    {{ formartNumber(produto.variacao) }}
                  </div>
                  <div class="page-table-desc-column new-price-width"></div>
                </div>

                <!-- mobile -->
                <div class="page-table-row mobile">
                  <div style="font-size: 1.2em;" class="price-analysis-mobile-header">{{produto.pro_descricao}}</div>
                  <div class="mobile-price-row">
                    <div >
                      EAN: {{produto.pro_ean}}
                    </div>
                    <div>
                      Quant.: {{produto.quantidade}}
                    </div>
                  </div>
                  <div class="mobile-price-row">
                    <div style="width: 30%;">
                      <div class="price-analysis-mobile-header">Prc. Atual</div>
                      {{produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produto.valorUnitario) : formartNumber(produto.valorUnitario)}}
                    </div>
                    <div>
                      <div class="price-analysis-mobile-header">Prc. Ult. Compra</div>
                      <span v-show="produto.preco_ultima_compra" @click="show_orders(produto)" title="Histórico de Pedidos"
                        class="material-icons-outlined makeorder-page-table-uniprice-icon not-print">assignment</span>
                        {{produto.preco_ultima_compra_generico ? formartNumber(produto.preco_ultima_compra_generico) : formartNumber(produto.preco_ultima_compra)}}
                    </div>
                    <div style="width: 30%;" class="mobile-align-rigth">
                      <div class="price-analysis-mobile-header">Diferença</div>
                      {{ formartNumber(produto.diff) }}
                    </div>
                  </div>
                  <div class="mobile-price-row">
                    <div style="width: 30%;">
                      <div class="price-analysis-mobile-header">Marca</div>
                      {{produto.marca ? produto.marca : "--"}}
                    </div>
                    <div>
                      <div class="price-analysis-mobile-header">Marca Ult. Compra</div>
                      {{produto.marca_ultima_compra ? produto.marca_ultima_compra : "--"}}
                    </div>
                    <div style="width: 30%;" class="mobile-align-rigth" :class="getDiffColor(produto.diff)">
                      <div class="price-analysis-mobile-header">Variação</div>
                      {{ formartNumber(produto.variacao) }}
                    </div>
                  </div>
                </div>
                <div class="page-table-row" v-show="produto.loading" :key="'prod-loader-' + produto.pro_id">
                  <cc-loader style="margin: 10px auto;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fold-table" v-if="tipoCategoria">
          <div class="table">
            <div class="row-table header-table">
              <div class="cell-table  cell-table-header">Categoria</div>
            </div>
            <div class="table-row" v-for="produto in produtos" :key="'pri-fil-catl-' + produto.cat_id">
              <div class="row-table view" :class="{'open': imprimindo}" :ref="'view-' + produto.cat_id" @click="clickTable(produto.cat_id)">
                <div class="cell-table cell-table-row">{{produto.cat_descricao}}</div>
              </div>
              <div class="sec-table fold" :class="{'open': imprimindo}" :ref="'fold-' + produto.cat_id">
                <div class="row-table header-table">
                  <div class="cell-table cell-table-row maxWidth140">EAN</div>
                  <div class="cell-table cell-table-row">Descrição</div>
                  <div class="cell-table cell-table-row maxWidth140">Prc. Ult. Compra</div>
                  <div class="cell-table cell-table-row maxWidth140">Prc. Atual</div>
                  <div class="cell-table cell-table-row maxWidth140 only-print-cell">Novo Preço</div>
                </div>
                <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
                  <div class="cell-table cell-table-row maxWidth140">{{produtoInt.pro_ean}}</div>
                  <div class="cell-table cell-table-row">{{produtoInt.pro_descricao}}</div>
                  <div class="cell-table cell-table-row maxWidth140">{{formartNumber(produtoInt.preco_ultima_compra)}}</div>
                  <div class="cell-table cell-table-row maxWidth140">{{produtoInt.cli_novoPedido == 1 && produtoInt.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorUnitario) : formartNumber(produtoInt.valorUnitario)}}</div>
                  <div class="cell-table cell-table-row maxWidth140 only-print-cell">&ensp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="fold-table" v-if="tipoFornecedor">
          <div class="table">
            <div class="row-table header-table">
              <div class="cell-table">Fornecedor</div>
            </div>
            <div v-for="produto in produtos" :key="'pri-fil-forn-' + produto.for_id">
              <div class="row-table view" :class="{'open': imprimindo}" :ref="'view-' + produto.for_id" @click="clickTable(produto.for_id)">
                <div class="cell-table">{{produto.nomeFornecedor}}</div>
              </div>
              <div class="sec-table fold" :class="{'open': imprimindo}" :ref="'fold-' + produto.for_id">
                <div class="row-table header-table">
                  <div class="cell-table maxWidth140">EAN</div>
                  <div class="cell-table">Descrição</div>
                  <div class="cell-table maxWidth140">Prc. Ult. Compra</div>
                  <div class="cell-table maxWidth140">Prc. Atual</div>
                  <div class="cell-table maxWidth140 only-print-cell">Novo Preço</div>
                </div>
                <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
                  <div class="cell-table maxWidth140">{{produtoInt.pro_ean}}</div>
                  <div class="cell-table">{{produtoInt.pro_descricao}}</div>
                  <div class="cell-table maxWidth140">{{formartNumber(produtoInt.preco_ultima_compra)}}</div>
                  <div class="cell-table maxWidth140">{{produtoInt.cli_novoPedido == 1 && produtoInt.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorUnitario) : formartNumber(produtoInt.valorUnitario)}}</div>
                  <div class="cell-table maxWidth140 only-print-cell">&ensp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>
    <tr v-if="!showTable" class="seemore-button-containear not-print">
      <td class="text-center" colspan="6">
        <Paginacao classes="orange justify-end"
                   :items_by_page_value="items_by_page_value"
                   :total="total_items"
                   :page="page"
                   v-on:change="updatePage($event)">
        </Paginacao>
      </td>
    </tr>
    <ProductOrdersModal :product_id="current_product.pro_id" :product_name="current_product.pro_descricao"
            v-if="current_product" @close="current_product = false" />
    <a id="download-excel" style="display:none;"></a>
  </div>
    <!-- <div>
        <div class="container-fluid">
            <div class="row only-print p-4">
                <div class="col text-center">
                    <img class="logo" style="width:100px;" src="/images/logotipo.png"/>
                </div>
            </div>
            <div class="row only-print">
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Nome Produto:</p>
                    <span>{{filtroProduto ? filtroProduto : "Todos"}}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Requisitante:</p>
                    <span>{{getNomeRequisitante ? getNomeRequisitante : "Todos"}}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Fornecedor:</p>
                    <span>{{getNomeFornecedor ? getNomeFornecedor : "Todos"}}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Categoria:</p>
                    <span>{{getNomeCategoria ? getNomeCategoria : "Todos"}}</span>
                </div>
            </div>

            <div class="row pt-4 not-print">
                <div class="col">
                    <label class="label-control">Cotação</label>
                    <cc-search v-model="filtroCotacao" :noicon="true" />
                </div>
                <div class="col">
                    <label class="label-control">Busca por produtos</label>
                    <cc-search v-model="filtroProduto" :noicon="true" />
                </div>
                <div class="col">
                    <label class="label-control">Variação de custo</label>
                    <cc-select-v2 placeholder="Filtro Preço Produto" :tracker="'id'" :text="'label'" :options="price_effects" v-model="filtroProdutoPreco"></cc-select-v2>
                </div>
                <div class="col">
                    <label class="label-control">Empresa</label>
                    <cc-select-v2 :tracker="'loj_id'" :text="'loj_descricao'" :options="$store.state.requisitantesFiltro" v-model="filtroRequisitante"></cc-select-v2>
                </div>
                <div class="col">
                    <label class="label-control">Fornecedor</label>
                    <cc-select-v2 :tracker="'for_id'" :text="'for_nome'" :options="$store.state.fornecedorFiltro" v-model="filtroFornecedor"></cc-select-v2>
                </div>
                <div class="col">
                    <label class="label-control">Categoria</label>
                    <cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="$store.state.categoriasFiltro" v-model="filtroCategoria"></cc-select-v2>
                </div>
            </div>
        </div>

        <div class="row pb-4 not-print pl-2">
            <div class="col-5 d-flex align-bottom justify-content-around filter-controls pl-4">
                <a id="download-excel" style="display:none;"></a>
                <cc-button :classes="'fixed secondary'" @click="getProdutos(1, false)" :icon="'fa fa-search fa-lg'" />
                <cc-button :icon="'fa fa-print'" :classes="'fixed'" :content="'Imprimir'" @click="print" />
                <cc-button :icon="'far fa-file-excel'" :classes="'fixed'" @click="getProdutosExcel()" :content="'Exportar Excel'" />
                <cc-button :classes="'fixed danger-outline'" :icon="'far fa-trash-alt'" @click="reset_filter()" :content="'Limpar Filtros'"  />
            </div>
            <div class="col pt-3 d-flex justify-content-end pr-4">
                <div class="totalDiv">
                    <b>Total: R$ {{formartNumber(totalPedido)}}</b>
                </div>
            </div>
        </div>


        <div class="container-fluid">

            <no-data-available v-if="produtos.length == 0 && !loading" :text="'Nenhum resultado encontrado'" />

            <div v-if="!tipoCategoria && !tipoFornecedor" class="fold-table">
                <table class="table mb-3" v-if="produtos.length > 0 && !loading">
                    <thead>
                        <tr>
                            <th class="text-center">EAN</th>
                            <th>
                                <cc-label-sort :showicon="ordernar=='p.pro_descricao'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Descrição'" @click="setFiltroOrder('p.pro_descricao', 'desc')"/>
                            </th>
                            <th>Marca</th>
                            <th class="text-center">Prc. Ult. Compra</th>
                            <th class="text-center">Prc. Atual</th>
                            <th class="text-center">Novo Preço</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(produto,index) in produtos" :key="'pri-fil-' + index">
                            <td class="text-center">{{produto.pro_ean}}</td>
                            <td>{{produto.pro_descricao}}</td>
                            <td>{{produto.marca ? produto.marca : "-"}}</td>
                            <td class="text-center">{{produto.preco_ultima_compra_generico ? formartNumber(produto.preco_ultima_compra_generico) : formartNumber(produto.preco_ultima_compra)}}</td>
                            <td class="text-center">{{produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produto.valorUnitario) : formartNumber(produto.valorUnitario)}}</td>
                            <td class="text-center"></td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div class="fold-table" v-if="tipoCategoria">
                <div class="table">
                  <div class="row-table header-table">
                  <div class="cell-table  cell-table-header">Categoria</div>
                </div>

                <div class="table-row" v-for="produto in produtos" :key="'pri-fil-catl-' + produto.cat_id">
                  <div class="row-table view" :class="{'open': imprimindo}" :ref="'view-' + produto.cat_id" @click="clickTable(produto.cat_id)">
                    <div class="cell-table cell-table-row">{{produto.cat_descricao}}</div>
                  </div>
                  <div class="sec-table fold" :class="{'open': imprimindo}" :ref="'fold-' + produto.cat_id">
                  <div class="row-table header-table">
                    <div class="cell-table cell-table-row maxWidth140">EAN</div>
                    <div class="cell-table cell-table-row">Descrição</div>
                    <div class="cell-table cell-table-row maxWidth140">Prc. Ult. Compra</div>
                    <div class="cell-table cell-table-row maxWidth140">Prc. Atual</div>
                    <div class="cell-table cell-table-row maxWidth140 only-print-cell">Novo Preço</div>
                  </div>
                  <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
                    <div class="cell-table cell-table-row maxWidth140">{{produtoInt.pro_ean}}</div>
                    <div class="cell-table cell-table-row">{{produtoInt.pro_descricao}}</div>
                    <div class="cell-table cell-table-row maxWidth140">{{formartNumber(produtoInt.preco_ultima_compra)}}</div>
                    <div class="cell-table cell-table-row maxWidth140">{{produtoInt.cli_novoPedido == 1 && produtoInt.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorUnitario) : formartNumber(produtoInt.valorUnitario)}}</div>
                    <div class="cell-table cell-table-row maxWidth140 only-print-cell">&ensp;</div>
                  </div>
                </div>
              </div>
            </div>
        </div>


        <div class="fold-table" v-if="tipoFornecedor">
          <div class="table">
            <div class="row-table header-table">
              <div class="cell-table">Fornecedor</div>
            </div>

            <div v-for="produto in produtos" :key="'pri-fil-forn-' + produto.for_id">
              <div class="row-table view" :class="{'open': imprimindo}" :ref="'view-' + produto.for_id" @click="clickTable(produto.for_id)">
                <div class="cell-table">{{produto.nomeFornecedor}}</div>
              </div>
              <div class="sec-table fold" :class="{'open': imprimindo}" :ref="'fold-' + produto.for_id">
                <div class="row-table header-table">
                  <div class="cell-table maxWidth140">EAN</div>
                  <div class="cell-table">Descrição</div>
                  <div class="cell-table maxWidth140">Prc. Ult. Compra</div>
                  <div class="cell-table maxWidth140">Prc. Atual</div>
                  <div class="cell-table maxWidth140 only-print-cell">Novo Preço</div>
                </div>
                <div class="row-table" v-for="(produtoInt,index) in produto.produtos" :key="index">
                  <div class="cell-table maxWidth140">{{produtoInt.pro_ean}}</div>
                  <div class="cell-table">{{produtoInt.pro_descricao}}</div>
                  <div class="cell-table maxWidth140">{{formartNumber(produtoInt.preco_ultima_compra)}}</div>
                  <div class="cell-table maxWidth140">{{produtoInt.cli_novoPedido == 1 && produtoInt.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorUnitario) : formartNumber(produtoInt.valorUnitario)}}</div>
                  <div class="cell-table maxWidth140 only-print-cell">&ensp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="box-footer clearfix not-print" v-if="produtos.length > 0 && !loading">
        <cc-paginacao :paginaAtual="paginaAtual" :totalPaginas="totalPaginas" :funcao="getProdutos"></cc-paginacao>
      </div>
    </div> -->
</template>

<script>
import { filtroData } from "@/mixins/filtroData";
import { mascaraCNPJ } from "@/mixins/mascaraCNPJ";
import numeral from "numeral";
import Paginacao from "@/components/cliente/base-components/Pagination";
import QuotationService from "@/services/QuotationService";
import * as CONSTANTS from "@/constants/constants";
import moment from 'moment';
import Button from "@/components/ui/buttons/Standard-Button.vue"
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import ProductOrdersModal from '@/modals/products/orders';
import { mapState } from "vuex";

export default {
    props:['cotacaoInput'],
    components: {
      Paginacao,
      Button,
      StandardInput,
      ProductOrdersModal
    },
    mixins: [filtroData, mascaraCNPJ],
    data() {
        return {
            showTable: false,
            page: 1,
            items_by_page_value: 30,
            total_items: 0,
            image:"/images/search.png",
            image2:"/images/calendar.png",
            paginaAtual: 1,
            totalPaginas: 1,
            date_picker_options: {
                format: "DD/MM/YYYY",
                locale: "pt-br",
                useCurrent: false
            },
            itensPorPagina: 0,
            totalPedido: 0,
            diffTotal: 0,
            totalItens: 0,
            produtos: [],
            current_product: undefined,
            tipoCategoria: false,
            tipoFornecedor: false,
            showMore: false,
            imprimindo: false,
            filtroProduto: "",
            filtroCotacao: "",
            filtroProdutoPreco: -1,
            filtroRequisitante: -1,
            filtroFornecedor: -1,
            filtroCategoria: -1,
            classOrder: "fa-sort-asc",
            ordernar: "p.pro_descricao",
            tipoOrder: "asc",
            loading: false,
            dateInit: undefined,
            dateEnd: undefined,
            countFirst: 0,
            varEqual: 0,
            varBig: 0,
            varLess: 0,
            price_effects: [
                { label: 'Todos', value: -1 },
                { label: 'Aumento de preco', id: 'aumento_preco' },
                { label: 'Rebaixa de preco', id: 'rebaixa_preco' },
                { label: 'Preço Igual', id: 'preco_igual' },
                { label: 'Primeira Compra', id: 'primeira_compra' },
            ],
            priceEffectsArray: [],
            quotationService: new QuotationService(),
            client: null
        }
    },
    watch:{
      dateInit (value){
        if (value && this.dateEnd) this.getProdutos(1, false);
      },
      dateEnd (value) {
        if (value && this.dateInit) this.getProdutos(1, false);
      }
    },
    computed: {
      ...mapState(["whitelabel"]),
        getNomeFiltroProduto() {
        switch (this.filtroProdutoPreco) {
            case "aumento_preco":
            return "Aumento de preco";
            break;
            case "rebaixa_preco":
            return "Rebaixa de preco";
            break;
            case "preco_igual":
            return "Preço Igual";
            break;
            case "primeira_compra":
            return "Primeira Compra";
            break;
        }
        return "";
        },
        getNomeCategoria() {
        let lojaSelect = this.$store.state.categoriasFiltro.filter(
            item => item.cat_id == this.filtroCategoria
        );
        if (lojaSelect.length == 0) {
            return "";
        }
        return lojaSelect[0].cat_descricao;
        },
        getNomeRequisitante() {
        let requisitanteSelect = this.$store.state.requisitantesFiltro.filter(
            item => item.loj_id == this.filtroRequisitante
        );

        if (requisitanteSelect.length == 0) {
            return "";
        }
        return requisitanteSelect[0].loj_descricao;
        },
        getNomeFornecedor() {
        let fornecedorSelect = this.$store.state.fornecedorFiltro.filter(
            item => item.for_id == this.filtroFornecedor
        );
        if (fornecedorSelect.length == 0) {
            return "";
        }
        return fornecedorSelect[0].for_nome;
        }
    },
    methods: {
        getSpecialBackground(idx) {
          if (idx % 2 != 0) return 'page-table-line-special'
        },
        show_orders(product) {
            this.current_product = product
        },
        getDiffColor(diff){
          if(diff > 0) return 'diff-up'
          else if (diff < 0) return 'diff-down'
        },
        openTable() {
          this.showTable = !this.showTable
        },
        setValue(value){
          this.filtroProduto = value
          this.getProdutos(this.page)
        },
        setValuePrice(value){
          this.filtroProdutoPreco = value
          this.getProdutos(this.page)
        },
        setQuotationValue(value) {
          this.filtroCotacao = value
          this.getProdutos(this.page)
        },
        setRequisitanteValue(value){
          this.filtroRequisitante = value
          this.getProdutos(1)
        },
        setFornecedorValue(value){
          this.filtroFornecedor = value
          this.getProdutos(1)
        },
        setCategoriaValue(value){
          this.filtroCategoria = value
        },
        print() {
            this.imprimir = true;
            this.getProdutos(1,false,true);
        },
        reset_filter() {
            this.filtroRequisitante = -1;
            this.filtroData = null;
            this.filtroProduto = null;
            this.filtroCategoria = -1;
            this.filtroFornecedor = -1
            this.filtroProdutoPreco = -1
            this.filtroCotacao = undefined
            this.total_items = 0
            this.produtos = []
        },
        open_datepicker() {
            document.getElementsByClassName('vdatetime-input')[0].click()
        },
        imprimir() {
            this.imprimindo = true;
            this.horaAtual = moment().format("HH:mm");
        },
        setFiltroOrder(order, tipo) {
            if (order == this.ordernar && tipo == this.tipoOrder && tipo == "asc") {
                this.tipoOrder = "desc";
            } else if (
                order == this.ordernar &&
                tipo == this.tipoOrder &&
                tipo == "desc"
            ) {
                this.tipoOrder = "asc";
            } else {
                this.tipoOrder = tipo;
            }
            this.classOrder = "fa-sort-" + this.tipoOrder;

            this.ordernar = order;

            if (this.produtos.length > 0) {
                this.getProdutos(this.paginaAtual, false);
            }
        },
    clickTable(index) {
      this.$refs["view-" + index][0].classList.toggle("open");
      this.$refs["fold-" + index][0].classList.toggle("open");
    },
    formartNumber(n) {
      if (n == 0 || !n) return '--'
      if (n) {
        return numeral(n.toString().replace(".", ",")).format("0,0.00");
      } else {
        return n;
      }
    },
    getPercentual(produto) {
      return (
        parseFloat(produto.valorTotal * 100 / this.totalPedido).toFixed(2) + "%"
      );
    },
    getProdutos(pagina, variable, imprimir = false) {
      if (this.filtroCotacao || (this.dateEnd && this.dateInit)) {
        this.loading = true;
        var requestUrl =
          `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?groupBy=ped.id_produto,pe.id_vendedor&ordenar=` +
          this.ordernar +
          "&tipoorder=" +
          this.tipoOrder +
          "&page=" +
          pagina;

        var telaImpressao;
        if (this.tipoCategoria) {
          requestUrl += "&tipo=categoria";
        }
        if (this.tipoFornecedor ) {
          requestUrl += "&tipo=fornecedor";
        }
        if (this.filtroCotacao ) {
          requestUrl += "&numerocotacao=" + this.filtroCotacao;
        }
        if (this.filtroProduto ) {
          requestUrl += "&nomeproduto=" + this.filtroProduto;
        }
        if (this.filtroRequisitante != -1) {
          requestUrl += "&requisitante=" + this.filtroRequisitante.loj_id;
        }
        if (this.filtroFornecedor != -1) {
          requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id;
        }
        if (this.filtroCategoria != -1) {
          requestUrl += "&categoria=" + this.filtroCategoria;
        }
        if (this.filtroProdutoPreco != -1) {
          requestUrl += "&filtropreco=" + (this.filtroProdutoPreco ? this.filtroProdutoPreco.id : null);
        }
        if (this.dateEnd && this.dateInit) {
          requestUrl += "&dateIni=" + moment(this.dateInit).format().split('T')[0] + "&dateFim=" + moment(this.dateEnd).format().split('T')[0];
        }
        if (imprimir) {
          requestUrl += "&imprimir=" + true;
          // telaImpressao = window.open("about:blank");

          // var telaImpressao = window.open("about:blank");
        }
        this.getVarInfo()
        axios
          .get(requestUrl, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          })
          .then(({ data }) => {
            this.loading = false;

            if (imprimir) {
              this.totalPedido = data.totalProdutos.valorTotal
              this.diffTotal = data.totais.totalqtd
                ? data.totalProdutos.valorTotal
                : 0;
              this.produtos = data.produtos;

              this.imprimindo = false;
              // setTimeout(() => {
              //   var conteudoModal = window.document.getElementById(
              //     "modalVisualizarBody"
              //   ).innerHTML;

              //   var conteudoImpressao =
              //     "<html>" +
              //     "<head>" +
              //     '<link rel="stylesheet" href="/css/app.css">' +
              //     '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
              //     '<link rel="stylesheet" href="https://adminlte.io/themes/AdminLTE/dist/css/AdminLTE.min.css">' +
              //     '<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" integrity="sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u" crossorigin="anonymous">' +
              //     "<style>" +
              //     " table { page-break-inside:auto !important }tr{ page-break-inside:avoid !important; page-break-after:auto !important }td{ page-break-inside:avoid; page-break-after:auto !important}thead {display: table-row-group !important  }tfoot { display:table-footer-group!important }" +
              //     "</style>" +
              //     "<title>" +
              //     "</title>" +
              //     "</head>" +
              //     "<body>" +
              //     conteudoModal +
              //     "<script>" +
              //     "setTimeout(function() {" +
              //     "window.print();" +
              //     "}, 500);" +
              //     "</" +
              //     "script>" +
              //     "</body>";

              //   telaImpressao.document.write(conteudoImpressao);
              //   // var telaImpressao = window.open("about:blank");

              //   this.imprimindo = false;
              // }, 1000);

              setTimeout(() => {
                // setTimeout(() => {
                //   this.imprimindo = false;
                // }, 3000);
                window.print();
              }, 1000);
            } else {
              this.totalPedido = data.totalProdutos.valorTotal
                ? data.totalProdutos.valorTotal
                : 0;
              this.diffTotal = data.totais.totalqtd
              this.paginaAtual = data.produtos.current_page;
              this.totalPaginas = data.produtos.last_page;
              this.total_items = data.produtos.total;
              this.itensPorPagina = data.produtos.per_page;
              this.totalItens = data.produtos.total;
              if (this.tipoCategoria || this.tipoFornecedor) {
                this.produtos = data.produtos;
              } else {
                this.produtos = data.produtos.data;
              }
            }
            this.produtos = this.produtos.map(produto => {
              console.log(123);

              let diff = 0
              if (produto.preco_ultima_compra) diff = (produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? produto.valorUnitario : produto.valorUnitario) - produto.preco_ultima_compra
              console.log(diff);
              return {
                ...produto,
                diff,
                variacao: diff * produto.quantidade
              }
            }
            )
            // setando produtos
          })
          .catch(error => {
            this.loading = false;
            if (error.response.status === 401) {
              if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                // location.href = CONSTANTS.ROUTE_LOGIN;
                window.location.assign(CONSTANTS.ROUTE_LOGIN)
              }
            } else if (error.response.status === 500) {
              if (error.response.data.mensagem) {
                this.mensagemErro = error.response.data.mensagem;
              } else {
                this.mensagemErro =
                  "Não foi possível abrir os dados gerenciais";
              }
            }
          });
      } else {
        alert("Digite o numero da cotação ou insira uma data.");
      }
    },
    getVarInfo(){
      this.quotationService.getVariationInfo(this.filtroCotacao).then(resp =>{
        this.countFirst = resp.data.count_preco_ultima_compra_nula
        this.varEqual = resp.data.count_variacao_igual_0
        this.varBig = resp.data.count_variacao_maior_0
        this.varLess = resp.data.count_variacao_menor_0
      })
    },
    updatePage(new_page) {
      this.page = new_page;
      this.getProdutos(this.page);
    },
    getProdutosExcel() {
      if (this.filtroCotacao) {
        this.loading = true;
        this.getInfoClient(this.filtroCotacao);
        var requestUrl =
          `${CONSTANTS.API_URL}/getprodutosrelatorioclienteexcel?groupBy=ped.id_produto,pe.id_vendedor&ordenar=` +
          this.ordernar +
          "&tipoorder=" +
          this.tipoOrder;
        var telaImpressao;
        if (this.tipoCategoria != "") {
          requestUrl += "&tipo=categoria";
        }
        if (this.tipoFornecedor != "") {
          requestUrl += "&tipo=fornecedor";
        }
        if (this.filtroCotacao != "") {
          requestUrl += "&numerocotacao=" + this.filtroCotacao;
        }
        if (this.filtroProduto != "") {
          requestUrl += "&nomeproduto=" + this.filtroProduto;
        }
        if (this.filtroRequisitante != -1) {
          requestUrl += "&requisitante=" + this.filtroRequisitante;
        }
        if (this.filtroFornecedor != -1) {
          requestUrl += "&fornecedor=" + this.filtroFornecedor;
        }
        if (this.filtroCategoria != -1) {
          requestUrl += "&categoria=" + this.filtroCategoria;
        }
        if (this.filtroProdutoPreco != -1) {
          requestUrl += "&filtropreco=" + this.filtroProdutoPreco;
        }
        requestUrl += "&template=analise";
        axios
          .get(requestUrl, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            responseType: 'blob'
          })
          .then((response) => {
            var file = response.data;
            var fileURL = URL.createObjectURL(file);
            var el = document.getElementById("download-excel");
            el.download = this.getFileName();
            el.href = fileURL;
            el.click();
            setTimeout(function() {
              window.URL.revokeObjectURL(fileURL);
            }, 100);
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            if (error.response.status === 401) {
              if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                location.href = CONSTANTS.ROUTE_LOGIN;
              }
            } else if (error.response.status === 500) {
              if (error.response.data.mensagem) {
                this.mensagemErro = error.response.data.mensagem;
              } else {
                this.mensagemErro =
                  "Não foi possível abrir os dados gerenciais";
              }
            }
          });
      } else {
        alert("Digite o numero da cotação");
      }
    },
    getInfoClient(quotation){
        this.quotationService.get_info_client(quotation)
            .then(response => response.data).then((data) => {
                this.client = data.client;
            }).catch(() => {
                ErrorHandlerService.handle(error, this.$store);
            })
    },
    getFileName(){
        return this.client.cli_nome_grupo.toUpperCase() + "-" + this.filtroCotacao + "-"+moment().format("DD/MM/YYYY")+".xls";
    }
  },
  mounted() {
        if(this.cotacaoInput){
            this.filtroCotacao = this.cotacaoInput;
            this.getProdutos(1, false);
        }
        this.price_effects.forEach(el => {
          this.priceEffectsArray.push({
            text: el.label,
            value: el
          })
        })
  }
};
</script>
<style lang="scss" scoped>
.product-analysis__add-buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 1.5em;
}
.product-analysis__add-button-container {
  margin-left: 1em;
}
.product-analysis__total-value {
  padding: 0.55em;
  border-radius: 8px;
  background-color: var(--primary-color);
}
.product-analysis__total-value-text {
  color: #FFF;
  font-weight: 600;
}
.clients-analysis__page-filter-container {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 2em;
}
.clients-analysis__page-filter-wrapper {
  margin-right: 10px;
  width: 20%;
}
.input-title {
  font-weight: 400;
  font-size: 1.7em;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-container {
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.date-container {
  align-items: center;
  border-radius: 8px !important;
  margin-top: 5px;
  display: flex;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.input-value {
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: #B3B3B3 !important;
}
.input-value:focus {
  border: none;
  outline: none;
}
.input-icon {
  color: #fff;
  background-color: var(--primary-color);
  margin-left: 5px;
  padding: 0.3em;
  border-radius: 8px;
  cursor: pointer;/*
  position: relative;
  left: -30px; */
}
.page-table-container{
  margin-top: 20px;
}
.page-table-header{
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  color: #605F5F;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  padding: 1vh 0;
}
.price-analysis-mobile-header{display: none;}
.page-table-header-text{
  font-weight: 400;
  color: #605F5F;
  padding-left: 10px;
}
.page-table-row {
  display: flex;
  font-weight: 300;
  color: #605F5F;
  cursor: pointer;
}
.page-table-desc-column {
  padding: 0.8em;
  border-right: 1px solid #DDE5E9;
}
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
}
.requested-products__actions-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 0px 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  height: 7.5vh;
  width: 8vw;
  cursor: pointer;
}
.requested-products__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.requested-products__actions-title {
  font-weight: 600;
  font-size: 18px;
  color: #505050;
}
.category-width {
  width: 9%;
  border-left: 1px solid #DDE5E9;;
}
.qty-width {
  flex: 1;
}
.total-val-width {
  width: 12%;
}
.last-price-width {
  width: 10%;
}
.actual-price-width {
  width: 8%;
}
.new-price-width {
  width: 16%;
  display: none;
}
.page-buttons-container {
  display: flex;
  margin: 2em 0em;
}
.page-buttons-container__top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 2em 0em;
}
.page-buttons-container__middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2em;
}
.page-buttons-container__middle-left {
  display: flex;
  justify-content: space-between;
}
.page-buttons-container__middle-right {
  display: flex;
}
.page-buttons-container__bottom {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 2em;
}
.page-buttons-container__left-container {
  display: flex;
}
.page-buttons-container__right-container {
  display: flex;
  align-items: center;
  margin-top: 0.7em;
}
.page-buttons-wrapper {
  width: 20vw;
  margin-right: 1em;
}
.page-buttons-wrapper__title {
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #898989;
}
.action-button {
  width: 15vw;
}
.page-filters__total-value {
  padding: 0.55em;
  border-radius: 8px;
  background-color: var(--primary-color);
}
.page-filters__total-value-text {
  color: #FFF;
  font-weight: 600;
}
.page-filters-container {
  display: flex;
  justify-content: space-between;
}
.page-filters__left-container {
  display: flex;
}
.page-filters__left-wrapper {
  margin-right: 1em;
}
.page-filters__left-title-wrapper {}
.page-filters__left-title {
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__left-select-wrapper {
  display: flex;
  width: 20vw;
}
.page-filters__right-container {
  display: flex;
}
.page-filters__right-wrapper {
  margin-left: 1em;
  width: 22%;
}
.page-selected-filters-containear {
  display: flex;
  margin-top: 14px;
}
.page-selected-filters {
  background: #fcf9f7;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}
.page-filters__right-title {
  font-weight: 400;
  font-size: 1.5em;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.page-filters__total-value {
  display: flex;
  padding: 1.8em;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #E5E5E5;
  background-color: #fff;
}
.page-filters__total-value-text {
  color: #202020;
  font-weight: 600;
  letter-spacing: 0.05em;
}
.action-buttons-width {
  margin-right: 3vw;
  text-wrap: nowrap;
}
.page-filters__total-value__img {
  margin-right: 10px;
}
.page-selected-filters-container {
  display: flex;
  margin-top: 14px;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.preorder-seller-title-button-excel {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E5E5E5;
  border-radius: 9.5px;
  font-weight: 600;
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 1.3em;
  cursor: pointer;
}
.preorder-seller-title-button-excel-icon {
  margin-right: 1vw;
}

.reports-page__title-button-total {
  background: rgba(23, 162, 184, 0.1);
  border-radius: 9.5px;
  font-weight: 600;
  font-size: 1.3em;
  color: #5281FF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5vw;
  height: 7.5vh;
}
.report-total-plus{
  background-color: lightcoral;
  color: white;
}
.report-total-minus{
  background-color: lightgreen;
  color: white;
}
.page-table-line-special {
  background: #F7F7F7;
}
.price-analysis__inputs-container {
  display: flex;
}
.price-analysis__actions-container {
  display: flex;
  align-items: flex-end;
  padding-top: 20px;
}
.report-extra-info-colum{
  margin-bottom: 0;
  margin-right: 3vw;
  line-height: 3.5vh;
  font-size: 1.2em;
  color: #605F5F;
}
.report-extra-info-yellow{
  background: rgba(42, 205, 114, 0.1);
  color: #30aa4cce;
}
.report-extra-info-purple{
  background: rgba(255, 184, 136, 0.1);
  color: #eeab7e;
}
.report-extra-info-red{
  background: rgba(221, 136, 255, 0.1);
  color: rgba(171, 7, 171, 0.354);
}
.diff-up {
    color: lightcoral;
    font-weight: bold;
}

.diff-down {
    font-weight: bold;
    color: lightgreen;
}
.makeorder-page-table-uniprice-icon {
    color: #4267B2;
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}
.mobile{display: none;}


  @media only screen and (max-width: 800px) {
    .clients-analysis__page-filter-container {
      flex-direction: column;
    }
    .mobile{display: block;}
    .desktop{display: none;}
    .mobile-price-row{
      display: flex;
      justify-content: space-between;
    }
    .mobile-align-rigth{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
    }
    .input-container {
      width: 95vw;
    }
    .date-container {
      width: 101vw;
    }
    .input-title {
      width: 100vw;
    }
    .date-icon {
    }
    .page-filters-container {
          flex-direction: column;
        }
        .page-buttons-container {
          flex-direction: column;
          align-items: flex-start;

        }
        .page-buttons-container__left-container {
          flex-direction: column;
        }
        .page-buttons-wrapper {
          width: 100vw;
        }
        .page-buttons-wrapper__select {
        }
        .page-buttons-container__right-container {
          flex-direction: column;

        }
        .page-filters__right-container {
          flex-direction: column;
        }
        .end-date {
          width: 99%;
        }
        .page-filters__left-select-wrapper {
          width: 100vw;
        }
        .page-filters__right-wrapper {
          margin-left: 0em;
        }
        .page-filters__total-value {
         width: 100vw;
        }
        .page-filters__left-container {
          display: flex;
          flex-direction: column;
        }
        .button-containear {
          margin: 5px 0px;
        }
        .page-buttons-wrapper {
          margin: 5px 0px;
        }
        .page-filters__left-select-wrapper {
          margin: 5px 0px;
        }
        .page-filters__right-wrapper {
          margin: 5px 0px;
          width: 99%;
        }
        .standard-input-width {
          width: 99% !important;
          margin: 5px;
        }
        .action-buttons-width {
          width: 55vw !important;
          margin: 5px;
          text-align: center;
        }
        .report-extra-info-purple{
          width: 55vw;
          text-align: center;
        }
        .report-extra-info-yellow{
          text-align: center;
          width: 55vw;
        }
        .report-extra-info-red{
          text-align: center;
          width: 55vw;
          margin: 0 auto;
        }
        .page-buttons-container__top {
          flex-direction: column;
        }
        .page-buttons-container__middle {
          flex-direction: column;
        }
        .page-buttons-container__middle-left {
          flex-direction: column;
        }
        .page-buttons-container__middle-right {
          flex-direction: column;
        }
        .page-buttons-container__bottom {
          flex-direction: column;
          margin-bottom: 0;
        }
        .price-analysis__inputs-container {
          flex-direction: column;
        }
        .price-analysis__actions-container {
          flex-wrap: wrap;
          gap: 1vh 0;
        }
        .page-table-header{display: none}
        .page-table-row{
          flex-wrap: wrap;
          gap: 1vh;
          margin-bottom: 3vh;
          font-size: 1.3em
        }
        .page-table-desc-column{
          border: none;
          flex: unset;
          padding: 0;
        }
        .price-analysis-mobile-header{
          display: flex;
          font-weight: 700;
          font-size: 1.1em;
          color: #605F5F !important;
        }
        .category-width {
          width: 12%;
        }
        .qty-width {
          width: 70%;
          font-weight: bold;
        }
        .total-val-width{
          width: 30%
        }
        .last-price-width{
          width: 30%;
          display: flex;
          flex-wrap: wrap;
        }
        .actual-price-width{
          width: 30%
        }
}


@import '@/sass/commons/_variables';

.totalDiv {
    width: fit-content;
    color: white;
    height: 100%;
    background-color: $secondary-color;
    text-align: right;
    padding: 0.7rem 1.5rem !important;
}
@media print {
  .page-table-row-container{font-size: 1em;}
  .category-width {
    width: 13%;
  }
  .total-val-width {
    width: 13%;
  }
  .last-price-width {
    width: 12%;
  }
  .actual-price-width {
    width: 10%;
  }
  .new-price-width {
    width: 9%;
    display: block;
  }
}
</style>
