<template>
    <div>
        <!-- <div v-if="isLoading" class="loader-containear">
            <cc-loader />
        </div> -->
        <cc-loader-full v-if="isLoading" />
        <div class="dashboard-page">
            <div class="dashboard-page-guide">Dashboard</div>
            <div class="dashboard-title-container">
                <div class="dashboard-page-title">Dashboard</div>
                <div style="display: flex; align-items: flex-end; gap: 2vw;">
                    <StandardInput class="desktop"  type="select" :action="setCompany" 
                        :placeholder="'Selecione a empresa'" :list="arraySelectLoja" />
                    <div >
                        <span class="dashboard-table-right-1-text">Inicio</span>
                        <datetime v-model="fromDate" value-zone="UTC-3" zone="UTC-3"  ref="datepicker"  auto type="date" class="form-control datetime-width dashboard-date-input"></datetime>
                    </div>
                    <div >
                        <span class="dashboard-table-right-1-text">Fim</span>
                        <datetime v-model="toDate" value-zone="UTC-3" zone="UTC-3"  ref="datepicker"  auto  type="date" class="form-control datetime-width dashboard-date-input"></datetime>
                    </div>
                    <span @click="cleanFilters" class="material-icons-outlined dashboard-clean-filter">close</span>
                </div>
                
            </div>

            <div class="dashboard-table-left-heade">
                <div class="dashboard-table-left-heade-nunber">
                    <div class="dashboard-table-left-heade-nunber-name">Nº de Ordens de Compra</div>
                    <div class="dashboard-table-left-heade-nunber-value">{{ cotNumber.value }}</div>
                </div>
                <div class="dashboard-table-left-heade-products">
                    <div class="dashboard-table-left-heade-products-name">Produtos Comprados</div>
                    <div class="dashboard-table-left-heade-products-value">{{ buysNumber.value }}</div>
                </div>
                <div class="dashboard-table-left-heade-supliers">
                    <div class="dashboard-table-left-heade-supliers-name">Fornecedores</div>
                    <div class="dashboard-table-left-heade-supliers-value">{{ supliersNumber.value }}</div>
                </div>
                <div class="dashboard-table-left-heade-cads">
                    <div class="dashboard-table-left-heade-supliers-name">Produtos Cadastrados</div>
                    <div class="dashboard-table-left-heade-supliers-value">{{ cadsNumber.value }}</div>
                </div>
            </div>
            <div class="dashboard-table-right-1">
                <div class="dashboard-colum-size">
                    <div class="dashboard-table-right-1-text">Compras dos últimos 6 meses</div>
                    <div v-if="numbersListX.all" class="dashboard-table-right-1-value-row">
                        <div class="dashboard-table-right-1-value-container">
                            <div class="dashboard-table-right-1-value"
                                :style="'width: ' + numbersListX.all.percent + '%'"></div>
                        </div>
                        <div class="dashboard-table-row-right">{{ numbersListX.all.value.toLocaleString('pt-br', {
            style:
                'currency', currency: 'BRL'
        }) }}</div>
                    </div>
                </div>
                <div class="dashboard-colum-size">
                    <div class="dashboard-table-right-1-text">Compras do último mês</div>
                    <div v-if="numbersListX.last" class="dashboard-table-right-1-value-row">
                        <div class="dashboard-table-right-1-value-container">
                            <div class="dashboard-table-right-1-value"
                                :style="'width: ' + numbersListX.last.percent + '%'"></div>
                        </div>
                        <div class="dashboard-table-row-right">{{ numbersListX.last.value.toLocaleString('pt-br', {
            style:
                'currency', currency: 'BRL'
        }) }}</div>
                    </div>
                </div>

                <div class="dashboard-colum-size">
                    <div class="dashboard-table-right-1-text">Compras do mês atual</div>
                    <div v-if="numbersListX.now" class="dashboard-table-right-1-value-row">
                        <div class="dashboard-table-right-1-value-container">
                            <div class="dashboard-table-right-1-value"
                                :style="'width: ' + numbersListX.now.percent + '%'"></div>
                        </div>
                        <div class="dashboard-table-row-right">{{ numbersListX.now.value.toLocaleString('pt-br', {
            style:
                'currency', currency: 'BRL'
        }) }}</div>
                    </div>
                </div>

            </div>
            <div class="dashboard-table-left">
                <div class="dashboard-table-container">
                    <div class="dashboard-table-title">Requisitante</div>
                    <div class="dashboard-table-content">
                        <div class="dashboard-table-content-piechart">
                            <vc-donut :sections="sections" :size="15" unit="vh"></vc-donut>
                        </div>
                        <div v-if="sections.length > 0" class="dashboard-table-list">
                            <div v-for="(store, idx) in requests" :key="idx" class="dashboard-table-row">
                                <div style="display: flex; align-items: center;">
                                    <div class="dashboard-point" :style="'background-color: ' + getPointColor(idx)">
                                    </div>
                                    <div style="margin-left: 0.5vw;">{{ store.loj_descricao }}</div>
                                </div>
                                <div class="dashboard-table-row-right2">
                                    {{ store.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="dashboard-table-container second-container">
                    <div class="dashboard-table-title">Categoria</div>
                    <div class="dashboard-table-content">
                        <div class="dashboard-table-content-piechart">
                            <vc-donut :sections="categoriesChartValues" :total="categoriesChartValuesTotal" :size="15"
                                unit="vh"></vc-donut>
                        </div>
                        <div v-if="categoriesChartValues.length > 0" class="dashboard-table-list">
                            <div v-for="(category, idx) in categoryList" :key="idx" class="dashboard-table-row">
                                <div style="display: flex; align-items: center;">
                                    <div class="dashboard-point" :style="'background-color: ' + getPointColor(idx)">
                                    </div>
                                    <div style="margin-left: 0.5vw;">{{ category.cat_descricao }}</div>
                                </div>
                                <div class="dashboard-table-row-right2">
                                    {{ category.qtd_total.toLocaleString('pt-br', {
                                        style: 'currency', currency: 'BRL'
                                    }) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="dashboard-table-container second-container">
                    <div class="dashboard-table-title">Compradores</div>
                    <div class="dashboard-table-content">
                        <div class="dashboard-table-content-piechart">
                            <vc-donut :sections="buyerChartValues" :total="buyerChartValuesTotal" :size="15"
                                unit="vh"></vc-donut>
                        </div>
                        <div v-if="buyerChartValues.length > 0" class="dashboard-table-list">
                            <div v-for="(buyer, idx) in buyerList" :key="idx" class="dashboard-table-row">
                                <div style="display: flex; align-items: center;">
                                    <div class="dashboard-point" :style="'background-color: ' + getPointColor(idx)">
                                    </div>
                                    <div style="margin-left: 0.5vw;">{{ buyer.usu_nome }}</div>
                                </div>
                                <div class="dashboard-table-row-right2">
                                    {{ buyer.total_value.toLocaleString('pt-br', {
                                        style: 'currency', currency: 'BRL'
                                    }) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dashboard-table-right">
                <div class="dashboard-table-right-2">
                    <div class="dashboard-table-title">Compras por mês</div>
                    <div class="dashboard-bar-table">
                        <div v-for="(bar, idx) in barChartValues" :key="idx" class="dashboard-bar-table-colum-colum">
                            <div class="dashboard-bar-table-text">{{ bar.value.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL'
                            }) }}</div>
                            <div class="dashboard-bar-table-colum" :style="'height: ' + bar.percent + '%'"></div>
                        </div>
                    </div>
                    <div class="dashboard-bar-table-legends"
                        style="font-weight: 500; font-size: 1.3em; color: #A1A1A1;">
                        <div v-for="(bar, idx) in numbersList" :key="idx" class="dashboard-colum-info">
                            {{ bar.DATA }}
                            <div  v-if="bar.extraInfo" style="cursor: pointer;" @click="detailDate = bar.extraInfo">
                                <p style="display: flex; justify-content: space-between;">1# compra: <span style="background-color: rgb(199, 151, 197);" class="dashboard-colum-info-value">{{ bar.extraInfo.comprados_0 || '--' }}</span></p>
                                <p style="display: flex; justify-content: space-between;">Qtd. comprados: <span style="background-color: gray;" class="dashboard-colum-info-value">{{ bar.extraInfo.produtos_ids && bar.extraInfo.produtos_ids.split(',').length }}</span></p>
                                <p style="display: flex; justify-content: space-between;">Qtd. cadastrados: <span style="background-color: lightblue;" class="dashboard-colum-info-value">{{ bar.extraInfo.quantidade_produtos || '--' }}</span> </p>
                                <p style="text-align: center;">
                                    Lucro/Prejuízo: <br>
                                    <span v-if="bar.extraInfo.total_lucro" :style="bar.extraInfo.total_lucro > 0 ? 'background-color: #CD2A2A80' : 'background: rgba(48, 170, 76, 0.5)'" class="dashboard-colum-info-value">{{  bar.extraInfo.total_lucro | twoDecimal   }}</span>
                                    <span v-else style="background: rgba(48, 170, 76, 0.5)" class="dashboard-colum-info-value">--</span>
                                </p>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div class="dashboard-bar-table-legends-mobile">
                        <div style="display: flex;">
                            <span style="width: 15%;"></span>
                            <span style="width: 15%;color: #606060; font-weight: bold;">1# compra:</span>
                            <span style="width: 21%;color: #606060; font-weight: bold;">Qtd. comprados:</span>
                            <span style="width: 23%;color: #606060; font-weight: bold;">Qtd. cadastrados:</span>
                            <span style="width: 25%;color: #606060; font-weight: bold;">Lucro/Prejuízo:</span>
                        </div>
                        <div v-for="(bar, idx) in numbersList" :key="idx" class="dashboard-table-row">
                            <template v-if="bar.extraInfo" >
                                <span style="width: 15%;color: #606060; font-weight: bold;">{{ bar.DATA }}</span>
                                <span style="width: 15%;">
                                    <span style="background-color: rgb(199, 151, 197);"class="dashboard-colum-info-value">{{ bar.extraInfo.comprados_0 || '--' }}</span>
                                </span>
                                <span style="width: 21%;">
                                    <span style="background-color: gray;"class="dashboard-colum-info-value">{{ bar.extraInfo.produtos_ids && bar.extraInfo.produtos_ids.split(',').length }}</span>
                                </span>
                                <span style="width: 23%;">
                                    <span style="background-color: lightblue;"class="dashboard-colum-info-value">{{ bar.extraInfo.quantidade_produtos || '--' }}</span>
                                </span>
                                <span @click="detailDate = bar.extraInfo" style="width: 25%;">
                                    <span v-if="bar.extraInfo.total_lucro" :style="bar.extraInfo.total_lucro > 0 ? 'background-color: #CD2A2A80' : 'background: rgba(48, 170, 76, 0.5)'" class="dashboard-colum-info-value">{{  bar.extraInfo.total_lucro | twoDecimal   }}</span>
                                            <span v-else style="background: rgba(48, 170, 76, 0.5)" class="dashboard-colum-info-value">--</span>
                                </span>
                            </template>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="dashboard-ranks-container">
                <div class="dashboard-ranks1">
                    <div class="dashboard-ranks-title">Ranking de Produtos</div>
                    <div class="dashboard-ranks-header" style="color: #30AA4C;">
                        <div class="dashboard-ranks-header-rank"></div>
                        <div class="dashboard-ranks-header-suplier">Produtos</div>
                        <div class="dashboard-ranks-header-quantity">Quantidade</div>
                        <div class="dashboard-ranks-header-price">Preço</div>
                    </div>
                    <div :class="getSpecialBackground(idx)" class="dashboard-ranks-row"
                        v-for="(product, idx) in productsRank" :key="idx">
                        <div class="dashboard-ranks-header-rank">
                            <div class="dashboard-ranks-header-rank-icon-green">{{ idx + 1 }}º</div>
                        </div>
                        <div class="dashboard-ranks-header-suplier">{{ product.pro_descricao }}</div>
                        <div class="dashboard-ranks-header-quantity">{{ product.qtd_total }}</div>
                        <div class="dashboard-ranks-header-price" style="font-weight: 600;">
                            {{ product.valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                        </div>
                    </div>
                </div>
                <div class="dashboard-ranks2">
                    <div class="dashboard-ranks-title">Ranking de Fornecedores</div>
                    <div class="dashboard-ranks-header" style="color: #17A2B8;">
                        <div class="dashboard-ranks-header-rank"></div>
                        <div class="dashboard-ranks-header-suplier">Fornecedor</div>
                        <div class="dashboard-ranks-header-price">Preço</div>
                    </div>
                    <div :class="getSpecialBackground(idx)" class="dashboard-ranks-row"
                        v-for="(suplier, idx) in supliersRank" :key="idx">
                        <div class="dashboard-ranks-header-rank">
                            <div class="dashboard-ranks-header-rank-icon-blue">{{ idx + 1 }}º</div>
                        </div>
                        <div class="dashboard-ranks-header-suplier">{{ suplier.for_nomeFantasia}}</div>
                        <div class="dashboard-ranks-header-price" style="font-weight: 600;">
                            {{suplier.valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}</div>
                    </div>
                </div>
            </div>
        </div>

        <LegendInfoModal v-if="detailDate" :categories="categoryList" :date="detailDate" :close="()=> detailDate=''"/>
    </div>
</template>

<script>
import DashboardService from '@/services/v3/dashboard/DashboardService.js'
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import * as CONSTANTS from "@/constants/constants";
import currencyPrecision from '../../mixins/currencyPrecision';
import LegendInfoModal from './LegendInfoModal.vue';
import ClientServiceV3 from "@/services/v3/ClientService.js"
import moment from 'moment';

export default {
    name: 'DashboardPage',
    components: {
        StandardInput,
        StandardInputListFiltered,
        LegendInfoModal
    },
    mixins: [currencyPrecision],
    data() {
        return {
            meses: [
                { text: 'Janeiro', value: 'Janeiro' },
                { text: 'Fevereiro', value: 'Fevereiro' },
                { text: 'Março', value: 'Março' },
                { text: 'Abril', value: 'Abril' },
                { text: 'Maio', value: 'Maio' },
                { text: 'Junho', value: 'Junho' },
                { text: 'Julho', value: 'Julho' },
                { text: 'Agosto', value: 'Agosto' },
                { text: 'Setembro', value: 'Setembro' },
                { text: 'Outubro', value: 'Outubro' },
                { text: 'Novembro', value: 'Novembro' },
                { text: 'Dezembro', value: 'Dezembro' }
            ],
            sections: [],
            detailDate: '',
            arraySelectLoja: [{text: 'Todos', value: null}],
            productsRank: [],
            numbersList: [],
            numbersListX: { all: { value: 0, percent: 0, percentTop: 100 }, now: { value: 0, percent: 0, percentTop: 0 }, last: { value: 0, percent: 0, percentTop: 0 } },
            barChartValues: [],
            barChartXValues: [],
            cotNumber: { rof: 0, value: 0 },
            buysNumber: { rof: 0, value: 0 },
            supliersNumber: { rof: 0, value: 0 },
            cadsNumber: { rof: 0, value: 0 },
            dashboardService: new DashboardService(),
            svc: new ClientServiceV3(),
            categoryList: [],
            categoriesChartValues: [],
            categoriesChartValuesTotal: 1,
            buyerList: [],
            buyerChartValues: [],
            buyerChartValuesTotal: 1,
            colorList: ['#FFB888', '#BDD770', '#C797C5', '#B5CDE4', '#93CECE', '#0b2754', '#540b13', '#72e878', '#706211', '#fc0320'],
            supliersRank: [],
            requests: [],
            isLoading: true,
            loadingStage: {},
            selectedStore: undefined,
            fromDate: undefined,
            toDate: undefined
        };
    },
    watch: {
        fromDate() {
            if (this.fromDate && this.toDate) this.clean() & this.init()
        },
        toDate() {
            if (this.fromDate && this.toDate) this.clean() & this.init()
        }
    },
    methods: {
        setCompany(value) {
            if (value) this.selectedStore = value.loj_id
            else this.selectedStore = null
            this.clean()
            this.init()
        },
        cleanFilters () {
            this.selectedStore = null
            this.fromDate = null
            this.toDate = null
            this.clean()
            this.init()
        },
        clean () {
            this.isLoading = true
            this.loadingStage = {}
            this.categoriesChartValues = []
            this.categoryList = []
            this.categoriesChartValuesTotal = 1
            this.barChartValues = []
            this.barChartXValues = []
        },
        getLojas() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            return axios.get(`${CONSTANTS.API_URL}/getrequisitantescliente`, config)
                .then(data => {
                    data.data.requisitantes.forEach(element => {
                        this.arraySelectLoja.push({
                            text: element.loj_nomeFantasia,
                            value: element
                        })
                    })
                }).catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        getPointColor(idx) {
            let above10 = idx > 10
            while (above10) {
                idx -= 10
                above10 = idx > 10
            }
            return this.colorList[idx]
        },
        async loadNumbers() {
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.cotNumber.value = (this.cotNumber.rof * i).toFixed(0)
                this.buysNumber.value = (this.buysNumber.rof * i).toFixed(0)
                this.supliersNumber.value = (this.supliersNumber.rof * i).toFixed(0)
                this.cadsNumber.value = (this.cadsNumber.rof * i).toFixed(0)
            }
        },
        async loadBarChartX() {
            for (let i = 0; i < this.numbersList.length; i++) this.numbersListX.all.value += this.numbersList[i].valor_total
            const d = new Date();
            let month = d.getMonth() + 1;
            this.numbersList.forEach(element => {
                if (element.DATA.split('/')[0] == month) this.numbersListX.now.value = element.valor_total
                else if (element.DATA.split('/')[0] == month - 1) this.numbersListX.last.value = element.valor_total
            })

            this.numbersListX.last.percentTop = ((this.numbersListX.last.value / this.numbersListX.all.value) * 100).toFixed(0)
            this.numbersListX.now.percentTop = ((this.numbersListX.now.value / this.numbersListX.all.value) * 100).toFixed(0)

            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.numbersListX.all.percent = this.numbersListX.all.percentTop * i
                this.numbersListX.now.percent = this.numbersListX.now.percentTop * i
                this.numbersListX.last.percent = this.numbersListX.last.percentTop * i
            }
        },
        async loadBarChart() {
            let rof = 0
            for (let i = 0; i < this.numbersList.length; i++) if (this.numbersList[i].valor_total > rof) rof = this.numbersList[i].valor_total
            for (let i = 0; i < this.numbersList.length; i++) {
                this.barChartValues.push({
                    value: this.numbersList[i].valor_total,
                    percentTop: ((this.numbersList[i].valor_total / rof) * 100).toFixed(0),
                    percent: 0,
                })
            }
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.barChartValues.forEach(element => {
                    element.percent = element.percentTop * i
                });
            }
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        getLoadChartPercent() {
            return new Promise(resolve => {
                let total = 0
                for (let i = 0; i < this.requests.length; i++) total += this.requests[i].valorTotal
                for (let i = 0; i < this.requests.length; i++) this.requests[i].percentTop = Math.floor(((this.requests[i].valorTotal / total) * 100))
                resolve()
            })
        },
        async loadChart() {
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                let newSection = []
                for (let y = 0; y < this.requests.length; y++) newSection.push({ value: this.requests[y].percentTop * i, color: this.getPointColor(y) })
                this.sections = newSection
            }
        },
        async loadBuyerChart() {
            for (let i = 0; i < this.buyerList.length; i++) {
                this.buyerChartValuesTotal += this.buyerList[i].total_value
            }
            for (let i = 0; i < this.buyerList.length; i++) {
                this.buyerChartValues.push({
                    percentTop: this.buyerList[i].total_value,
                    value: 0,
                    color: this.getPointColor(i)
                })
            }
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.buyerChartValues.forEach(element => {
                    element.value = element.percentTop * i
                });
            }
        },
        async loadCategoryChart() {
            let rof = 0
            for (let i = 0; i < this.categoryList.length; i++) {
                this.categoriesChartValuesTotal += this.categoryList[i].qtd_total
            }
            for (let i = 0; i < this.categoryList.length; i++) {
                this.categoriesChartValues.push({
                    percentTop: this.categoryList[i].qtd_total,
                    value: 0,
                    color: this.getPointColor(i)
                })
            }
            for (let i = 0.1; i <= 1; i = parseFloat((i + 0.01).toFixed(2))) {
                await this.sleep(10)
                this.categoriesChartValues.forEach(element => {
                    element.value = element.percentTop * i
                });
            }
        },
        load() {
            if (this.loadingStage.a && this.loadingStage.b && this.loadingStage.c && this.loadingStage.d && this.loadingStage.e && this.loadingStage.f && this.loadingStage.g) {
                this.isLoading = false
                this.loadBarChart()
                this.loadBarChartX()
                this.loadCategoryChart()
                this.loadBuyerChart()
                this.loadChart()
                this.loadNumbers()
            }
        },
        init() {
            this.sections = []
            this.dashboardService.getRankingSupliers(this.selectedStore, this.fromDate, this.toDate).then(resp => {
                this.supliersRank = resp.data.rankingFornecedor
                this.loadingStage.a = true
                this.load()
            })
            this.dashboardService.getRankingProducts(this.selectedStore, this.fromDate, this.toDate).then(resp => {
                this.productsRank = resp.data.rankingProdutos
                this.loadingStage.b = true
                this.load()
            })
            this.dashboardService.getCotBuy(this.selectedStore, this.fromDate, this.toDate).then(resp => {
                this.numbersList = resp.data.compraPorCot
                this.numbersList.forEach(element => { element.date = new Date(element.DATA.split('/')[1] + '-' + element.DATA.split('/')[0] + '-1') })
                this.numbersList.sort((a, b) => { return a.date - b.date })
                if (this.numbersList.length > 6) this.numbersList.pop()
                this.dashboardService.getCountsNew().then(resp => {
                    this.numbersList.forEach(element => element.extraInfo = resp.data.find(count => Number(count.mes.split('-')[1]) == Number(element.DATA.split('/')[0])))
                    this.loadingStage.c = true
                    this.load()
                })
            })
            this.dashboardService.getCategory(this.selectedStore, this.fromDate, this.toDate).then(resp => {
                for (let i = 0; i < resp.data.categoria.length; i++) {
                    this.categoryList.push({
                        cat_descricao: resp.data.categoria[i].cat_descricao,
                        qtd_total: resp.data.categoria[i].valorTotal,
                        id: resp.data.categoria[i].cat_id
                    })
                }
                this.loadingStage.d = true
                this.load()
            })
            this.dashboardService.getRequest(this.selectedStore, this.fromDate, this.toDate).then(resp => {
                this.requests = resp.data.requisitante
                this.getLoadChartPercent().then(() => {
                    this.loadingStage.e = true
                    this.load()
                })
            })
            this.dashboardService.getCounts(this.selectedStore, this.fromDate, this.toDate).then(resp => {
                this.supliersNumber.rof = resp.data.fornecedores
                this.buysNumber.rof = resp.data.produtosComprados
                this.cotNumber.rof = resp.data.qtdCot
                this.cadsNumber.rof = resp.data.produtosCadastrados
                this.loadingStage.f = true
                this.load()
            })
            this.svc.loadBuyers(moment().subtract(5, 'months').format('YYYY-MM-05'), moment().format('YYYY-MM-DD')).then(resp => {
                this.buyerList = resp.data
                this.loadingStage.g = true
                this.load()
            })
        }
    },
    beforeMount() {
        this.getLojas()
        this.init()
    }
}
</script>

<style>
.dashboard-page {
    padding-right: 5vw;
    padding-bottom: 10vh;
    font-size: 1vw;
}

.dashboard-page-guide {
    font-weight: 400;
    font-size: 1em;
    color: #ABABAB;
}

.dashboard-page-title {
    font-weight: 600;
    font-size: 2.25em;
    color: #605F5F;
    margin-top: 1vh;
}

.dashboard--tables {
    display: flex;
}

.dashboard-table-right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.dashboard-table-left {
    display: flex;
    justify-content: space-between;
    margin-top: 3vh;
}

.dashboard-table-left-heade {
    display: flex;
    justify-content: space-between;
}

.dashboard-colum-size {
    width: 30%;
}

.dashboard-table-left-heade-nunber {
    background: rgba(23, 162, 184, 0.1);
    border-radius: 20px;
    width: 23%;
    padding: 1vh 0 1vh 1vw;
    color: #17A2B8;
}

.dashboard-table-left-heade-nunber-name {
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 0.15px;
}
.dashboard-colum-info{
    color: rgb(154, 153, 153);
    border-right: 1px solid lightgray;
    padding-right: 1vw;
    background-color: white;
    text-wrap: nowrap; 
    text-align: center;
    width: 15%;
}
.dashboard-colum-info:last-child{
    border-right: none;
}
p{
    margin: 1vh 0;
}

.dashboard-bar-table-colum-colum {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.dashboard-colum-info-value{
    color: white;
    border-radius: 2px;
    padding: 0 1vw;
}

.dashboard-table-left-heade-nunber-value {
    font-weight: 400;
    font-size: 2.25em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-products {
    background: rgba(42, 205, 114, 0.1);
    border-radius: 20px;
    width: 23%;
    padding: 1vh 0 1vh 1vw;
    color: #30AA4C;
}

.dashboard-table-left-heade-products-name {
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-products-value {
    font-weight: 400;
    font-size: 2.25em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-supliers {
    background: rgba(255, 184, 136, 0.1);
    border-radius: 20px;
    width: 23%;
    padding: 1vh 0 1vh 1vw;
    color: #eeab7e;
}

.dashboard-table-left-heade-cads {
    background: rgba(221, 136, 255, 0.1);
    border-radius: 20px;
    width: 23%;
    padding: 1vh 0 1vh 1vw;
    color: rgba(171, 7, 171, 0.354);
}

.dashboard-table-left-heade-supliers-name {
    font-weight: 500;
    font-size: 1.3em;
    letter-spacing: 0.15px;
}

.dashboard-table-left-heade-supliers-value {
    font-weight: 400;
    font-size: 2.25em;
    letter-spacing: 0.15px;
}

.dashboard-table-container {
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1vh 1vw;
    width: 32%;
}

.dashboard-table-title {
    font-weight: 600;
    font-size: 1.8em;
    color: #505050;
}

.dashboard-table-content {
    display: flex;
}

.dashboard-table-content-piechart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.dashboard-table-list {
    flex: 1;
    overflow-y: auto;
    height: 25vh;
    margin-left: 5%;
}

.dashboard-table-list::-webkit-scrollbar {
    height: 1vh;
    background: transparent;
    width: 1vw;
}

.dashboard-table-list::-webkit-scrollbar-thumb:vertical {
    background: lightgray;
    border-radius: 10px;
}

.dashboard-table-row {
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    margin-bottom: 1vh;
    margin-right: 0.5vw;
    font-size: 1.3em;
}

.dashboard-point {
    border-radius: 50%;
    height: 1vh;
    width: 1vh;
}

.dashboard-table-row-right {
    margin-left: 2vw;
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
}

.dashboard-table-row-right2 {
    margin-right: 2vw;
    font-weight: 400;
    color: #606060;
}

.dashboard-table-right-1 {
    height: 35%;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 11.3383px;
    padding: 3vh 1vw 2vh 1vw;
    justify-content: space-between;
    display: flex;
    margin-top: 3vh;
}

.dashboard-table-right-2 {
    flex: 1;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 11.3383px;
    padding: 1vh 1vw;
    margin-top: 3vh;
}

.dashboard-table-right-1-text {
    font-weight: 400;
    font-size: 1.3em;
    color: #505050;
}

.dashboard-table-right-1-value-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-table-right-1-value-container {
    width: 70%;
    background-color: #F7F7F7;
    border-radius: 10px;
    height: 0.6em;
}

.dashboard-table-right-1-value {
    background-color: #93CECE;
    border-radius: 10px;
    height: 0.6em;
}

.dashboard-bar-table {
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    height: 30vh;
}

.dashboard-bar-table-colum {
    background-color: #FFB888;
    border-radius: 10px 10px 0 0;
    width: 1.1vw;
}


.dashboard-bar-table-text {
    font-weight: 500;
    font-size: 1.3em;
    margin-right: -0.5vw;
    color: #A1A1A1;
}

.dashboard-bar-table-legends {
    display: flex;
    justify-content: space-around;
    margin-top: 2vh;
}
.dashboard-bar-table-legends-mobile{
    display: none;
}

.dashboard-ranks-container {
    display: flex;
    margin-top: 3vh;
}

.dashboard-ranks1 {
    width: 50%;
    margin-right: 5%;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.dashboard-ranks2 {
    flex: 1;
    box-shadow: 0px 0.783805px 7.83805px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.dashboard-ranks-title {
    padding: 1vh 1vw;
    font-weight: 600;
    font-size: 1.7em;
    color: #505050;
}

.dashboard-ranks-header {
    display: flex;
    background: #FFFEFC;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    padding: 1vh 0;
    font-weight: 400;
    font-size: 1em;
}

.dashboard-ranks-header-suplier {
    flex: 1;
    text-transform: uppercase;
}

.dashboard-ranks-header-quantity {
    width: 20%;
}

.dashboard-ranks-header-price {
    width: 20%;
}

.dashboard-ranks-header-rank {
    width: 10%;
}

.page-table-line-special {
    background: #F7F7F7;
}

.dashboard-ranks-row {
    display: flex;
    align-items: center;
    padding: 0.5vh 0;
    margin-top: 1vh;
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    height: 7vh;
}

.dashboard-ranks-header-rank-icon-green {
    background: rgba(42, 205, 114, 0.1);
    border-radius: 2px;
    font-weight: 400;
    font-size: 1em;
    color: #30AA4C;
    margin: 0 auto;
    width: fit-content;
    padding: 0.5vh 1vw;
}
.dashboard-date-input{
    border-radius: 8px;
}
.dashboard-clean-filter{
    border: 1px solid lightgray;
    border-radius: 8px;
    color: lightgrey;
    cursor: pointer;
    padding: 0.5vw;
}

.dashboard-ranks-header-rank-icon-blue {
    background: rgba(23, 162, 184, 0.1);
    border-radius: 2px;
    font-weight: 400;
    font-size: 1em;
    color: #17A2B8;
    margin: 0 auto;
    width: fit-content;
    padding: 0.5vh 1vw;
}

.loader-containear {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5;
}

.dashboard-title-container {
    display: flex;
    align-items: flex-end;
    gap: 3vw;
    margin-bottom: 4vh;
    /* margin-top: -4vh; */
}

@media only screen and (max-width: 1000px) {
    .dashboard--tables {
        flex-direction: column;
    }

    .dashboard-page {
        font-size: 1.5vw;
    }

    .dashboard-table-left {
        margin-right: 0;
        width: 100%;
        flex-direction: column;
    }

    .dashboard-table-container {
        width: 100%;
    }

    .dashboard-bar-table-text {
        font-size: 0.8em;
    }

    .dashboard-table-right-1 {
        margin-top: 2vh;
    }

    .second-container {
        margin-top: 2vh;
    }

    .dashboard-table-right-2 {
        flex: none;
    }
    .dashboard-bar-table-legends{
        display: none;
    }
    .dashboard-bar-table-legends-mobile{
        display: block;
        margin-top: 3vw
    }

    .dashboard-ranks-container {
        flex-direction: column;
    }

    .dashboard-ranks1 {
        width: 100%;
        margin-top: 2vh;
    }

    .dashboard-ranks2 {
        width: 100%;
        margin-top: 2vh;
    }
}

@media only screen and (max-width: 700px) {
    .dashboard-page {
        font-size: 2vw;
        padding: 0 15px 30px 0;
    }
    .dashboard-title-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .dashboard-date-input{
        width: 35vw;
    }
    .dashboard-table-row{
        display: flex;
        justify-content: space-between;
    }

    .dashboard-bar-table-colum {
        width: 2vw;
    }

    .dashboard-table-left-heade {
        flex-wrap: wrap;
        gap: 2vh 0;
    }

    .dashboard-table-left-heade-products {
        padding: 1vh 0 0 2vh;
        width: 47%;
    }

    .dashboard-table-left-heade-supliers {
        padding: 1vh 0 0 2vh;
        width: 47%;
    }

    .dashboard-table-left-heade-nunber {
        padding: 1vh 0 0 2vh;
        width: 47%;
    }

    .dashboard-table-left-heade-cads {
        padding: 1vh 0 0 2vh;
        width: 47%;
    }

    .dashboard-table-right-1 {
        height: unset;
        flex-direction: column;
    }

    .dashboard-colum-size {
        width: 100%;
    }
}

@media only screen and (max-width: 500px) {
    .dashboard-page {
        font-size: 2.5vw;
    }


}

@media only screen and (max-width: 400px) {
    .dashboard-page {
        font-size: 3vw;
    }
}
</style>
