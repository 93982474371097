<style lang="scss">
$sidebar-background-color: #fbfbfb;
$fast-options-header-background-color: #ec7f3b;
.home {
  background: white;
  display: flex;
  padding: 0;
  min-height: 100vh;
  .body {
    width: calc(100% - 290px);
    .fast-options {
      .card {
        border-radius: 0;
        min-width: 23rem;
        height: 15rem;
        padding: 0;
        box-shadow: 5px 8px #ededed;
        margin-left: 0.7rem;
        margin-top: 1rem;
        margin-bottom: 36px;
        .header {
          background-color: var(--primary-color);
          height:  15rem;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 5rem;
            &::before {
              color: rgba(255, 255, 255, 0.61);
            }
          }
        }
        .info {
          background-color: white;
          min-height: 100px;
          padding: 16px 14px 13px 20px;
          position: relative;
          h2 {
            margin: 0;
            padding: 0 0 10px;
            font-size: 16px;
            font-weight: 600;
          }
          a {
            border: 1px solid var(--primary-color);
            padding: 6px 13px;
            border-radius: 4px;
            font-size: 12px;
            color: #4d4f5c;
            position: absolute;
            bottom: 13px;
            right: 13px;
            &:hover {
              background-color: var(--primary-color);
              color: white;
            }
          }
        }
      }
    }
    .history {
      margin-bottom: 30px;
      #history-graph {
        min-width: 310px;
        height: 400px;
        margin: 0 auto;
      }
    }
    .rankings {
      margin-bottom: 40px;

      & > div:first-child {
        display: flex;
      }
      .providers {
        background-color: transparent;
        padding: 0 12px 0 0;
        .data {
          background-color: white;
          padding: 0 20px 20px;
          h2 {
            font-family: "Rubik", sans-serif !important;
            color: #9b9b9b;
            font-size: 18px;
            font-weight: 400;
            padding-top: 23px;
            margin: 0 0 20px;
          }
        }
      }
      .categories {
        background-color: transparent;
        padding: 0;
        .data {
          background-color: white;
          padding: 20px;
          h2 {
            font-family: "Rubik", sans-serif !important;
            color: #9b9b9b;
            font-size: 18px;
            font-weight: 400;
            margin: 0;
            position: absolute;
            z-index: 1;
            top: 20px;
          }
          .chart {
            height: 400px;
            .title {
              text-align: center;
              z-index: 1;
              position: absolute;
              top: 180px;
              left: 50%;
              transform: translate(-50%);
              margin-left: 3px;
            }
            #categories-chart {
              width: 200px;
              margin: 0 auto;
            }
          }
          .subtitles {
            margin-top: -60px;
            z-index: 1;
            position: relative;
            ul {
              list-style: none;
              margin: 0 auto;
              max-width: 240px;
              padding: 0;
              li {
                display: flex;
                justify-content: space-between;
                .ball {
                  width: 16px;
                  height: 16px;
                  background-color: white;
                  border: 3px solid #4d4f5c;
                  border-radius: 50%;
                  &.purple {
                    border-color: #a3a0fb;
                  }
                  &.yellow {
                    border-color: #ffda83;
                  }
                  &.red {
                    border-color: #ff8373;
                  }
                  &.blue {
                    border-color: #55d8fe;
                  }
                }
                p {
                  color: #f38505;
                  &.total-sold {
                    color: rgba(77, 79, 92, 0.5);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.icon-card{
  color:white !important;
}
.btn-padding{
  margin-top: 70px !important;
}
.text-card{
  font-size: 25px !important ;
  color: #8b8b8b !important;
}
.text-header{
  font-size: 50px;
  color: #9b9b9b;
}
.text-subheader{
   font-size: 30px;
  color: #9b9b9b;
}
.first-Letter:first-letter {
    text-transform: uppercase;
}
</style>

<template>
  <div>
    <PageErroBrowser v-if="page_error"></PageErroBrowser>

    <div v-if="!page_error">
      <div class="home">
        <div class="body">
          <div class="p-3">
            <h1 class="text-header">Olá, <span class="first-Letter">{{usuario}}</span>.</h1>
            <h2 class="text-subheader">Escolha um menu e comece.</h2>
          </div>
          <div class="py-2" style="padding-left:14% !important">
            <div class="fast-options row justify-content-around">
              <div class="card col-sm-2 col-lg-2 col-md-4">
                <div class="header icon-card">
                  <i class="fa fa-desktop fa-5x" aria-hidden="true"></i>
                </div>
                <div class="info">
                  <h2 class="text-card">Meus Monitores</h2>
                    <div class="btn-padding">
                      <router-link :to="CONSTANTS.ROUTE_MONITORS">Acessar</router-link>
                    </div>
                </div>
              </div>
              <div class="card col-sm-2 col-lg-2 col-md-4">
                <div class="header icon-card">
                <i class="fa fa-shopping-bag fa-5x" aria-hidden="true"></i>
                </div>
                <div class="info">
                  <h2 class="text-card">Meus Produtos</h2>
                  <div class="btn-padding">
                     <router-link  to="/cadastroproduto">Acessar</router-link>
                  </div>

                </div>
              </div>
              <div class="card col-sm-2 col-lg-2 col-md-4">
                <div class="header icon-card">
                  <i class="fa fa-users fa-5x" aria-hidden="true"></i>
                </div>
                <div class="info">
                  <h2 class="text-card">Meus Vendedores</h2>
                  <div class="btn-padding">
                    <router-link to="/fornecedores">Acessar</router-link>
                  </div>
                </div>
              </div>

            </div>
              <div class="fast-options row justify-content-around" >
                <div class="card col-sm-2 col-lg-2 col-md-4">
                <div class="header icon-card">
                  <i class="fa fa-cart-plus fa-5x" aria-hidden="true"></i>
                </div>
                <div class="info">
                  <h2 class="text-card">Nova Cotação / Requisição</h2>
                  <div class="btn-padding">
                      <router-link :to="CONSTANTS.ROUTE_NEW_QUOTATION_REQUEST">Acessar</router-link>

                  </div>
                </div>
              </div>
              <div class="card col-sm-2 col-lg-2 col-md-4">
                <div class="header icon-card">
                  <i class="fa fa-credit-card fa-5x" aria-hidden="true"></i>
                </div>
                <div class="info">
                  <h2 class="text-card">Nova Compra Direta</h2>
                  <div class="btn-padding">
                   <router-link to="/compradireta">Acessar</router-link>
                  </div>
                </div>
              </div>

              <div class="card col-sm-2 col-lg-2 col-md-4">
                <div class="header icon-card">
                 <i class="fa fa-line-chart fa-5x" aria-hidden="true"></i>
                </div>
                <div class="info">
                  <h2 class="text-card">Relatorios</h2>
                  <div class="btn-padding">
                    <router-link to="/relatorios">Acessar</router-link>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/cliente/base-components/Pagination";
// import Highcharts from "highcharts";
// import highchartsVariablePie from "highcharts/modules/variable-pie";
import * as CONSTANTS from "@/constants/constants";
import StorageService from '@/services/TokenService';
import VarejoFacilService from '@/services/VarejoFacilService';
import ClientServiceV3 from "@/services/v3/ClientService.js"

// highchartsVariablePie(Highcharts);

export default {
  components: {
    ccPagination: Pagination
  },
  data() {
    return {
      page_error: false,
      usuario: '',
      CONSTANTS: CONSTANTS,
      storage_svc: new StorageService(),
      vf_svc: new VarejoFacilService(),
      breadcrumbs: [
        {
          name: "Painel de Controle"
        }
      ],
      clientServiceV3: new ClientServiceV3()
    };
  },
  methods: {
    sendHome(data){
        let isMobile = window.innerWidth < 691
        let home = this.check_home_page_url(data.usuario.settings)
        if (home) {
          if (home == '/ofertas') this.$router.push(home+'?login=true');
          else if(isMobile){
            this.$router.push("/inicio");
        }else this.$router.push(home);
        } else {
            if( isMobile){
                this.$router.push("/inicio");
            }else{
                this.$router.push("/cliente/monitores/cotacoes");

            }
        }
        this.$forceUpdate();
    },
    carregarInformacoesUsuario() {
      axios
        .get(`${CONSTANTS.API_URL}/getUser`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        })
        .then(({ data }) => {
          //PERFIL ADMINISTRADOR
          function toTitleCase(str) {
            return str.replace(/\w\S*/g, function(txt){
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
          }
          this.usuario = toTitleCase(data.usuario.usu_nome.toLowerCase())
          localStorage.setItem("perfil_usuario", data.usuario.perfil_usuario);
          this.storage_svc.put("grupo", data.usuario.cliente.cli_nome_grupo);
          this.storage_svc.put("cli_tipoPedido", data.usuario.cliente.cli_tipoPedido);
          this.storage_svc.put("cli_id", data.usuario.cli_id);
          if(data.usuario.cli_id=='6263')this.$store.commit('setIdentifierText', 'Placa');
          this.storage_svc.put("tpc_id", data.usuario.cliente.tpc_id);
          this.storage_svc.put("request_type", data.usuario.cliente.cli_tipoPedido)
          this.storage_svc.put("user_name", data.usuario.usu_nome)
          this.storage_svc.put("user_settings", JSON.stringify(data.usuario.settings))
          this.storage_svc.put("stores", JSON.stringify(data.usuario.lojas))
          this.storage_svc.put("closing", data.usuario.cliente.cli_metodo_fechamento)
          this.storage_svc.put("usu_id", JSON.stringify(data.usuario.usu_id))
          this.storage_svc.put("attendance", JSON.stringify(data.usuario.attendance))
          this.storage_svc.put("showOffers", !data.usuario.usu_idLoja);
          this.storage_svc.put("cli_utilizaPrecoBase", data.usuario.cliente.cli_utilizaPrecoBase);
          this.storage_svc.put("store_id", JSON.stringify(data.usuario.usu_idLoja))
          this.storage_svc.put("AlertCount", 0)
          this.vf_svc.setup_alert(data.usuario.settings);
          if (data.usuario.usuarioPlano.length > 0) { this.storage_svc.put("plan", data.usuario.usuarioPlano[0].status) }
          else { this.storage_svc.put("plan", "0") }
          localStorage.setItem("cli_vindi", data.usuario.cliente.cli_vindi);
          if(data.usuario.lojas)this.storage_svc.put("stores", JSON.stringify(data.usuario.lojas))
          if(data.usuario.varejo_facil_pass)this.storage_svc.put("has_vf_integration", data.usuario.varejo_facil_pass)
          this.$store.dispatch('user/load_user')
          this.$forceUpdate();
          this.exibeMonitor = true;
          if (data.usuario.cliente.cli_vindi == 1){
              this.clientServiceV3.getContractsVindi().then(response => {
                  this.storage_svc.put('contracts', JSON.stringify(response.data.plan.subscriptions))
                  this.storage_svc.put('contractsBills', JSON.stringify(response.data.bills.bills))
                  this.storage_svc.put('vindiAccount', JSON.stringify(response.data.custumers.customers[0]))
              })
              this.sendHome(data)
          } else {
              this.clientServiceV3.getContracts().then(response => {
                  this.storage_svc.put('contracts', JSON.stringify(response.data))
              })
              this.sendHome(data)
          }
        })
        .catch(error => {
          console.log(error)
          if (error.response && error.response.status === 401) {

            // localStorage.clear();
            //// router.replace('/login/');
            //this.$router.push('/login/');
            // location.href = "/";
            // location.href = CONSTANTS.ROUTE_LOGIN;
            window.location.assign(CONSTANTS.ROUTE_LOGIN)
          }
        });
    },
    getParameterByName(name, url) {
      if (!url) url = location.href;
      name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      var regexS = "[\\?&]" + name + "=([^&#]*)";
      var regex = new RegExp(regexS);
      var results = regex.exec(url);
      return results == null ? null : results[1];
    },
    check_home_page_url(settings) {
        if(settings) {
            let home = settings.find(s => s.key == 'DEFAULT_HOME_PAGE')
            if(home && home.value) {
                return home.value
            }
        }
        return false
    },
  },
  created() {
    // this.verifyBrowser();
    // return
    console.log('created')
    if (this.getParameterByName("token")) {
      localStorage.setItem("token", this.getParameterByName("token"));
      this.carregarInformacoesUsuario();

      // location.href = "/";

      // this.$router.push(CONSTANTS.ROUTE_MONITORS);
      //  window.history.pushState("", "", "#/");
      // window.history.pushState("", "", "/cliente/monitores/cotacoes");

      //this.refreshToken();
    } else {
      if (!localStorage.getItem("token")) {
        this.$router.push('/login/')
        // location.href = CONSTANTS.ROUTE_LOGIN;
        //location.href = CONSTANTS.ROUTE_LOGIN
      } else {
        this.carregarInformacoesUsuario();
      }
      // router.replace('/login/');
    }
  },
  mounted() {
  }
};
</script>
