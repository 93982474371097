import http from "./http";
import Service from "../Service";

/**
 * @type ClientService
 */
export default class ClientService extends Service {

	getContracts(){
    return http.get('clients/contracts', {
      headers: this.getHeaders()
    })
  }
  getContractsVindi(){
    return http.get('providers/vindi/', {
      headers: this.getHeaders()
    })
  }
  deleteContractsVindi(id){
    return http.delete('providers/vindi/'+id, {
      headers: this.getHeaders()
    })
  }
  reativateContractsVindi(id){
    return http.post('providers/vindi/'+id+'/reactivate', {}, {
      headers: this.getHeaders()
    })
  }
  getPendencies(cnpj){
    return http.get('clients/management/system/invoices/'+ cnpj, {
      headers: this.getHeaders()
    })
  }
  getBills(id){
    return http.get('clients/conexa/store/'+ id, {
      headers: this.getHeaders()
    })
  }
  loadBuyers(init, end) {
    return http.get('clients/reports/new', {
      headers: this.getHeaders(),
      params:{
        'start_date': init,
        'end_date': end
      }
    })
  }
  getBuyer(id, init, end){
    return http.get('clients/reports/new', {
      headers: this.getHeaders(),
      params:{
        'start_date': init,
        'end_date': end,
        'usu_id': id
      }
    })
  }

  getproducts(page,from,to, excel){
    return http.get('clients/products/offers/byproduct', {
      headers: this.getHeaders(),
      params:{ page, to, from, excel },
      responseType: excel ? "arraybuffer" : null,
    })
  }
}