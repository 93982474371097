import http from "../http";
import Service from "../../Service";

/**
 * @type OrderService
 */
export default class OrderService extends Service {

	load_orders(product_id) {
		return http.get(`clients/products/${product_id}/orders`, {
            headers: this.getHeaders()
        });
	}
	getProductHistory(id, page, from, to, brand){
        return http.get("clients/products/offers", {
            headers: this.getHeaders(),
			params: {
				product_id: id,
                page: page,
				from,
				to,
				brand
            }
        });
    }
    getResuplyList(id){
		return http.get("clients/solicitacao/ressuprimento/cotacao/"+id, {
			headers: this.getHeaders()
		});
	}
	getOrders (id) {
		return http.get(`/clients/products/${id}/requests`, {
			headers: this.getHeaders()
		});
	}
}
