<template>
    <div style="padding-bottom: 10vh;" class="divergent-page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Monitor de Cotação
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/cliente/divergent" class="page-route-select-text">
                Analise de Preço
            </router-link>
        </div>
        <div class="divergent-page-title-container">
            <div class="asd divergent-page-title" v-if="current_subrequest">Análise de divergências: {{ current_subrequest.id_pedido }}</div>
            <div class="divergent-page-buttons-container">
                <StandardButton class="divergent-page-button" :action="rebuyAll" :iconName="'done_all'" :text="'Recomprar Todos'" />
                <StandardButton class="divergent-page-button" :action="close" :iconName="'arrow_back'" :text="'Voltar'" />
            </div>
            
        </div>
        <div v-if="current_subrequest" class="divergent-page-info-container">
            <div class="divergent-page-info-texts">
                <div class="divergent-page-info-buyer-colum">
                    <div class="divergent-page-info-buyer-title-text">Comprador</div>
                    <div class="divergent-page-info-text">{{ current_subrequest.loj_nomeFantasia.toUpperCase() }} <br>
                        {{ current_subrequest.loj_endereco }}, {{ current_subrequest.loj_bairro }} - {{
                            current_subrequest.cid_nome }}/{{ current_subrequest.est_sigla }}</div>
                    <div class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Fone:</div>
                        <a class="divergent-page-info-text" v-if="current_subrequest.loj_telefone"
                            :href="'https://api.whatsapp.com/send?phone=' + formatNumber(current_subrequest.loj_telefone)"
                            target="_blank">{{ current_subrequest.loj_telefone | VMask('(##) #####-####') }}</a>
                        <div class="divergent-page-info-text" v-else>--</div>
                    </div>
                    <div class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">CNPJ:</div>
                        <div class="divergent-page-info-text">{{ current_subrequest.loj_cnpj | cnpj }}</div>
                    </div>
                </div>
                <div class="divergent-page-info-suplier-colum">
                    <div class="divergent-page-info-buyer-title-text">Fornecedor</div>
                    <div class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-suplier-raiting-text">{{ current_subrequest.for_nome }}</div>
                        <span class="material-icons-outlined divergent-page-info-suplier-raiting-icon">grade</span>
                        <div v-if="current_subrequest && current_subrequest.rate">{{ current_subrequest.rate.toFixed(1) }}
                        </div>
                    </div>
                    <div class="divergent-page-info-text">{{ current_subrequest.usu_nome }}</div>
                    <div class="divergent-page-info-subtitle" style="margin-top: 1.5vh;">E-mail:</div>
                    <div class="divergent-page-info-text">{{ current_subrequest.usu_email }}</div>
                    <div class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">CNPJ:</div>
                        <div v-if="providerCnpj" class="divergent-page-info-text">{{ providerCnpj | cnpj }}</div>
                    </div>
                    <div class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Fone:</div>
                        <img class="divergent-page-info-whats-icon" src="@/assets/WhatsApp_black.svg" alt="mandar mensagem">
                        <a class="divergent-page-info-text" v-if="false"
                            :href="'https://api.whatsapp.com/send?phone=' + formatNumber(current_subrequest.usu_telefone)"
                            target="_blank">{{ current_subrequest.usu_telefone | VMask('(##) #####-####') }}</a>
                        <div class="divergent-page-info-text" v-else>--</div>
                    </div>
                </div>
                <div :class="{ 'extra-margin': !showDetailsMobile }" class="divergent-page-info-details-colum">
                    <div style="display: flex; align-items: center;" v-on:click="showDetailsMobile = !showDetailsMobile">
                        <span style="color: var(--primary-color);" v-if="showDetailsMobile"
                            class="material-icons-outlined mobile">expand_more</span>
                        <span v-else class="material-icons-outlined mobile"
                            style="rotate: -90deg; color: var(--primary-color);">expand_more</span>
                        <div class="divergent-page-info-buyer-title-text">Detalhe do Pedido</div>
                    </div>

                    <div :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Entrega:</div>
                        <div class="divergent-page-info-text">{{ current_subrequest.data_entrega | formatDateOnly }}</div>

                    </div>
                    <div v-if="divergentOrder && divergentOrder.data_entrega_realizada"
                        :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle" style="color: lightcoral;">Entrega Realizada:</div>
                        <div class="divergent-page-info-text">{{ divergentOrder.data_entrega_realizada | formatDateOnly }}
                        </div>
                    </div>
                    <DivergentButton :class="{ 'desktop': !showDetailsMobile }" v-if="!divergentOrder" :action="() => showOrderDivergentModal = true" />
                    <div :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Tipo de Entrega:</div>
                        <div class="divergent-page-info-text">{{ current_subrequest.tpc_nome }}</div>
                    </div>
                    <div :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Faturamento:</div>
                        <div class="divergent-page-info-text">{{ current_subrequest.faturamento_minimo | currency }}</div>
                    </div>
                    <div :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Cond. de Pgto:</div>
                        <div class="divergent-page-info-text">{{ current_subrequest.forma }}</div>
                    </div>
                    <div v-if="divergentOrder && divergentOrder.condicao_pagamento"
                        :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle" style="color: lightcoral;">Cond. de Pgto Realizada:</div>
                        <div class="divergent-page-info-text">{{ getDivergentText(divergentOrder.condicao_pagamento) }}
                        </div>
                    </div>
                    <div :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-suplier-raiting">
                        <div class="divergent-page-info-subtitle">Status:</div>
                        <div v-if="!current_subrequest.id_pedido" class="divergent-page-info-status-pending">pendente</div>
                        <div v-else class="divergent-page-info-status-created">gerado</div>
                    </div>
                </div>
            </div>

            <div :class="{ 'desktop': !showDetailsMobile }" class="divergent-page-info-obs-colum">
                <div class="divergent-page-info-buyer-title-text divergent-page-info-obs-title-container">
                    Observação
                    <span v-if="can('REQUEST_ORDER', 'CREATE') && true"
                        class="material-icons-outlined divergent-page-info-obs-title-icon">save</span>
                </div>
                <textarea v-model="current_subrequest.obs" :disabled="can('REQUEST_ORDER', 'CREATE') && true"
                    class="divergent-page-info-obs-textarea" placeholder="Digite aqui ser houver alguma observação"
                    cols="30" rows="8"></textarea>
            </div>
        </div>
        <div style="position: relative;">
            <h4 v-if="current_subrequest" class="modalTitlulo pb-3 pt-2 text-center">
                Como foi sua experiência com
                <span class="modalTituloNome">{{ current_subrequest.usu_nome }}</span> ?
            </h4>
            <div class="d-flex justify-content-center">
                <star-rating v-if="current_subrequest && current_subrequest.rate" @rating-selected="modalClick"
                    v-model="current_subrequest.rate" :star-size="50" :show-rating="false"></star-rating>
            </div>
            <div style="height: 5vh;">
                <div class="d-flex justify-content-center py-3">
                    <h1 class="notaVendedor">{{ notaVendedor }}</h1>
                </div>
            </div>
        </div>

        <div class="divergent-page-table-header">
            <div class="divergent-page-table-name-width">Produto/Marca</div>
            <div class="divergent-page-table-alert-width"></div>
            <div class="divergent-page-table-req-width">Req.</div>
            <div class="divergent-page-table-quantity-width">Qtd Emb.</div>
            <div class="divergent-page-table-orders-width">Pedido</div>
            <div class="divergent-page-table-unity-width">Und Compra</div>
            <div class="divergent-page-table-digi-width">Digitação</div>
            <div class="divergent-page-table-total-width">Total</div>
            <div class="divergent-page-table-action-width">Ação</div>
        </div>
        <div  v-for="(product, idx) in products" :key="idx">
            <DivergentTableLineDesktop :product="product" :openModal="openModal"/>
            <DivergentTableLineMobile :product="product" :openModal="openModal" />
        </div>

        <AddDivergentModal v-if="showDivergentModal" :id_pedido="current_subrequest.id_pedido" :update="loadPage"
            :action="openSaveModal" :back="() => showDivergentModal = false" :cotId="this.$route.params.id"
            :product="selectedProduct" />
        <saveOrderDivergentModal v-if="showOrderDivergentModal" :payment_types="payment_types" :update="updatePage"
            :current_subrequest="current_subrequest" :back="() => showOrderDivergentModal = false" />
        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import { perm_mixin } from '@/mixins/permission.mixin'
import DivergentButton from './DivergentButton.vue';
import AddDivergentModal from './AddDivergentModal.vue';
import QuotationService from "@/services/QuotationService";
import RequestsService from "@/services/v2/requests.service";
import saveOrderDivergentModal from './saveOrderDivergentModal.vue';
import DivergentService from '@/services/v3/divergentService.js'
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import DivergentTableLineDesktop from './DivergentTableLineDesktop.vue'
import DivergentTableLineMobile from './DivergentTableLineMobile.vue'
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'DivergentPage',
    mixins: [perm_mixin],
    components: {
        DivergentButton,
        AddDivergentModal,
        saveOrderDivergentModal,
        StandardButton,
        DivergentTableLineDesktop,
        DivergentTableLineMobile
    },
    data() {
        return {
            showDetailsMobile: false,
            showDivergentModal: false,
            showSaveDivergent: false,
            isLoading: true,
            products: [],
            current_subrequest: undefined,
            selectedProduct: undefined,
            subrequests: undefined,
            all_subrequests: undefined,
            providerCnpj: this.$route.params.cnpj,
            quotationService: new QuotationService(),
            service: new RequestsService(),
            divergentService: new DivergentService(),
            payment_types: [],
            selected: 0,
            showDivergentHistory: false,
            showOrderDivergentModal: false,
            divergentOrder: undefined,
            notaVendedor: ''
        }
    },
    mounted() {
        this.load_subrequests()
    },
    methods: {
        getDivergentText(id) {
            let resp
            this.payment_types.forEach(element => element.code == id ? resp = element.label : '')
            return resp
        },
        rebuyAll(){
            let list = this.products.map(value=>{ return {idProduto: value.idProduto, quantidade: value.quantidade}})
            this.$swal.fire({
                title: "Confirma a recompra de todos os produtos?",
                text: "Os produtos serão enviados para o monitor de divergências.",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, recomprar produtos."
            }).then(resp=> {
                if (resp.isConfirmed){
                    this.isLoading = true
                    this.divergentService.rebuyAll(list,this.$route.params.id,this.current_subrequest.id_pedido ).then(()=>{
                        this.isLoading = false
                        this.close()
                    })
                }
            })
        },
        modalClick() {
            if (this.current_subrequest.rate === 1) {
                this.notaVendedor = "Vendedor péssimo, poderia melhorar.";
            }
            if (this.current_subrequest.rate === 2) {
                this.notaVendedor = "Vendedor ruim, poderia melhorar.";
            }
            if (this.current_subrequest.rate === 3) {
                this.notaVendedor = "Vendendor regular, poderia melhorar.";
            }
            if (this.current_subrequest.rate === 4) {
                this.notaVendedor = "Vendedor bom, mas poderia melhorar.";
            }
            if (this.current_subrequest.rate === 5) {
                this.notaVendedor = "Vendedor Excelente!";
            }
            this.postAvaliacaoPedido()
        },
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        postAvaliacaoPedido() {
            this.isLoading = true
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.post(`${CONSTANTS.API_URL}/atualizaravaliacaopedido`, {
                id_pedido: this.current_subrequest.id_pedido,
                nota: this.current_subrequest.rate,
                comentarios: '',
                observacao: " "
            }, config).then(() => {
                this.isLoading = false
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        updatePage() {
            this.loadPage()
            this.loadDivergent()
        },
        is_winner(product) {
            return (product.vencedor_multiplo == 1 || product.vencedor == 1) && product.quantidade_sugestao > 0;
        },
        loadPage() {
            this.isLoading = true
            this.quotationService.getProdutosDigitacaoComparativo(
                this.$route.params.id,
                this.$route.params.sellerid,
                this.$route.params.sid,
                undefined,
                1,
                this.current_subrequest.id_pedido
            ).then(resp => {
                let productsArray = resp.data.produtos;
                productsArray.forEach(p => {
                    p.new_amount = p.quantidade_sugestao;
                    p.firstValue = p.quantidade_sugestao;
                    p.visible = this.is_winner(p)
                });
                this.products = productsArray.filter(value => value.visible);
                this.loadDivergent()
            })
        },
        openModal(product) {
            this.showDivergentModal = true
            if (product) this.selectedProduct = product
        },
        load_subrequests() {
            this.service.subrequests_with_sellers_for(this.$route.params.id)
                .then(response => response.data).then(data => {
                    this.subrequests = data;
                    this.all_subrequests = data
                    let next = this.subrequests.find(s => s.for_id == this.$route.params.providerid && s.vendedor_id == this.$route.params.sellerid && s.loj_id == this.$route.params.sid)
                    if (next) {
                        this.current_subrequest = next
                        this.sort_subrequests()
                    } else {
                        if (this.current_subrequest) {
                            this.subrequests.push(this.current_subrequest)
                            this.current_subrequest.total = 0
                        }
                        this.sort_subrequests()
                    }
                    this.loadPage()
                })
        },
        sort_subrequests() {
            let result = []
            let loj_ids = Array.from(new Set(this.subrequests.map(s => s.loj_id)))
            for (let id of loj_ids) {
                result = result.concat(this.subrequests
                    .filter(s => s.loj_id == this.$route.params.sid)
                    .sort((a, b) => { return a.for_nome.localeCompare(b.for_nome) })
                )
            }
            this.subrequests = result;
        },
        close() {
            this.$router.go(-1)
        },
        openSaveModal(selected) {
            this.selected = selected
            this.showSaveDivergent = true
            this.showDivergentModal = false
        },
        loadDivergent() {
            this.quotationService.list_payment_types()
                .then(response => response.data)
                .then(data => {
                    this.payment_types = data.data.map(p => { return { label: p.ttp_nome, code: p.ttp_id } });
                    this.divergentService.getOrderDivergent(this.current_subrequest.id_pedido).then(resp => {
                        if (resp.data.length > 0) this.divergentOrder = resp.data[resp.data.length - 1]
                        this.isLoading = false
                    })
                })

        }
    }
}
</script>

<style lang="scss" scoped>
@import "./typings.component";

.page-route-select {
    display: flex;
    align-items: center;
}

.divergent-page {
    font-size: 1vw;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    line-height: 17px;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.divergent-page-title-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    width: 97%;
}

.divergent-page-title {
    font-weight: 700;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: red;
}

.divergent-page-subtitle {
    font-weight: 500;
    font-size: 0.6em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 1vh;
}

.divergent-page-info-container {
    display: flex;
    margin-top: 5vh;
    padding-left: 5vw;
}

.divergent-page-info-texts {
    display: flex;
    flex: 1;
}

.divergent-page-info-buyer-colum {
    flex: 1;
}

.divergent-page-info-buyer-title-text {
    font-weight: 700;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #606060;
}

.divergent-page-info-text {
    font-weight: 300;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #A1A1A1;
}

.divergent-page-info-suplier-raiting {
    margin-top: 1.5vh;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}

.divergent-page-info-suplier-raiting-text {
    background: rgba(42, 205, 114, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    font-weight: 300;
    font-size: 1.1em;
    color: #30AA4C;
    margin-right: 2vw;
}
.divergent-page-buttons-container{
    display: flex;
    gap: 2vw;
}

.divergent-page-info-suplier-raiting-icon {
    color: #FFCA2B;
    font-size: 2em;
}

.divergent-page-info-subtitle {
    font-weight: 500;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #707070;
    margin-right: 0.5vw;
}

.divergent-page-info-suplier-colum {
    flex: 1;
}

.divergent-page-info-details-colum {
    flex: 1;
}

.mobile {
    display: none;
}

.divergent-page-info-obs-colum {
    width: 25%;
}

.divergent-page-info-obs-title-container {
    display: flex;
}




.divergent-page-info-status-created {
    font-weight: 500;
    font-size: 1.1em;
    color: #2ACD72;
}

.divergent-page-info-status-pending {
    font-weight: 500;
    font-size: 1.1em;
    color: #f39c12;
}

.divergent-page-table-header {
    display: flex;
    padding: 1vh 0.5vw;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
    margin-top: 2vh;
}

.divergent-page-table-name-width {
    flex: 1;
}

.divergent-page-table-req-width {
    width: 8%;
}
.divergent-page-table-alert-width{
    width: 7%;
}

.divergent-page-table-quantity-width {
    width: 8%;
}


.divergent-page-table-digi-width {
    width: 10%;
    display: flex;
    align-items: center;
}

.divergent-page-table-uniprice-width {
    width: 12%;
    display: flex;
    align-items: center;
}

.divergent-page-table-total-width {
    width: 10%;
}

.divergent-page-table-action-width {
    width: 13%;
}

.divergent-page-table-unity-width {
    width: 10%;
}

.divergent-page-table-orders-width {
    width: 10%;
}

.have-divergent-product {
    color: lightcoral;
}





.makeorder-page-table-digi-icon {
    color: var(--primary-color);
    font-size: 1.2rem;
    margin-right: 0.5vw;
    cursor: pointer;
}



@media only screen and (max-width: 1000px) {
    .divergent-page {
        font-size: 1.25vw;
    }

    .divergent-page-info-container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 850px) {
    .divergent-page {
        font-size: 2.5vw;
    }
    .divergent-page-info-container {
        font-size: 3.5vw;
    }

    .divergent-page-info-texts {
        flex-direction: column;
    }

    .mobile {
        display: flex;
    }

    .desktop {
        display: none;
    }

    .divergent-page-table-header {
        display: none;
    }
    .modalTitlulo{
        font-size: 2em;
        margin-right: 5vw;
    }
    .divergent-page-title-container{
        flex-direction: column;
        gap: 2vh;
    }
    .divergent-page-button{
        width: 45%;
    }
}
</style>