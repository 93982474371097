<template>
    <div>
        <div class="page-filters__datetime-container">
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                    <div class="page-filters__right-title">Data Inicial</div>
                </div>
                <div class="page-filters__right-select-wrapper end-date">
                    <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker"
                        v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
                    <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                        today
                    </span>
                </div>
            </div>
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                    <div class="page-filters__right-title">Data Fim</div>
                </div>
                <div class="page-filters__right-select-wrapper end-date">
                    <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker"
                        v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
                    <span @click="open_datepicker(1)" class="material-icons-outlined date-icon">
                        today
                    </span>
                    <span @click="loadPage(1)" class="material-icons-outlined search-icon">
                        search
                    </span>
                </div>
            </div>
            <StandardInput :placeholder="$store.state.identifierText" :noIcon="true" :action="v=>setIdentifier(v)" :type="'text'" />
            <div class="action-buttons-width">
                <Button title="Excel" :text="'Excel'" class="mobile-button-size" :iconName="'file_download'" :action="excel" />
                <div class="reports-page__title-button-total mobile-button-size">
                    <div style="display: flex; align-items: center;">
                        Total: {{ orders.length }}
                    </div>
                </div>
                <div class="reports-page__title-button-total mobile-button-size">
                    <div style="display: flex; align-items: center;">
                        Valor Total: {{ total | currency }}
                    </div>
                </div>
            </div>
        </div>
        <div class="identifier-container">
            <ShowIdentifier v-if="identifier" :text="identifier" :action="()=>cleanIdentifier()" />
        </div>
        
        <div class="desktop">
            <div class="page-table-header">
                <div class="page-table-header-text name-width">ID / Cotação</div>
                <div class="page-table-header-text total-width">Loja</div>
                <div class="page-table-header-text total-width">Data</div>
                <div class="page-table-header-text total-width">Fornecedor / Vendedor</div>
                <div class="page-table-header-text total-width">Valor(R$)</div>
                <div class="page-table-header-text total-width">Data de Confirmação</div>
                <div class="page-table-header-text total-width">Data entrega</div>
            </div>
            <div v-for="data in orders" :key="data.name">
                <div class="page-table-row">
                    <div class="page-table-desc-column name-width">
                        {{ data.id_pedido }} <br> {{ data.id_cotacao }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{ data.loj_descricao }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{ getDate(data.data_geracao) }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        <div style="font-weight: bold;">{{ data.fornecedor ? data.fornecedor.for_nome : '--' }}</div>
                        <div>{{ data.vendedor }}</div>
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{ data.valor_pedido | currency }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{ getDate(data.data_confirmacao) }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{ getDate(data.data_entrega) }}
                    </div>
                </div>
            </div>
        </div>
		<a id="download-excel" style="display:none;"></a>
        <cc-loader-full v-if="isLoading"/>
    </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import Button from "@/components/ui/buttons/Standard-Button.vue"
import StandardInput from "@/components/ui/inputs/StandardInput.vue";
import ShowIdentifier from "../../../components/ui/labels/ShowIdentifier.vue";

export default {
    name: 'TabOrderList',
    data() {
        return {
            selectedFornecedor: undefined,
            selectedLoja: undefined,
            total: 0,
            orders: [],
            isLoading: false,
            identifier: ''
        }
    },
    components: {
        Button,
        StandardInput,
        ShowIdentifier
    },
    mounted() {
        this.loadPage(1)
    },
    methods: {
        cleanIdentifier(){
            this.identifier = ''
            this.loadPage(1)
        },
        loadPage (page) {
            this.isLoading = true
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    fornecedor: this.selectedFornecedor? this.selectedFornecedor.for_id : null,
                    loja: this.selectedLoja ? this.selectedLoja.loj_id : null,
                    datainicial:  moment(this.$store.state.dateIniRelatorio).format().split('T')[0] ,
                    datafinal:  moment(this.$store.state.dateFimRelatorio).format().split('T')[0] ,
                    identifier: this.identifier,
                    total: true
                }
            };
            let url = `${CONSTANTS.API_URL}/listarpedidos?page=${page}`;
            axios.get(url, config).then(data => {
                this.total = 0
                this.orders = data.data.pedidos
                data.data.pedidos.forEach(element => this.total += element.valor_pedido);
                this.isLoading = false
            })
        },
        setIdentifier(value){
            this.identifier = value
            this.loadPage(1)
        },
        excel () {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    fornecedor: this.selectedFornecedor? this.selectedFornecedor.for_id : null,
                    loja: this.selectedLoja ? this.selectedLoja.loj_id : null,
                    datainicial:  moment(this.$store.state.dateIniRelatorio).format().split('T')[0] ,
                    datafinal:  moment(this.$store.state.dateFimRelatorio).format().split('T')[0] ,
                    identifier: this.identifier,
                },
                responseType: "arraybuffer"
            };
            let url = `${CONSTANTS.API_V3_URL}/clients/reports/excel/orders`;
            axios.get(url, config).then(data => {
                var file = new Blob([data.data], {type: 'application/vnd.ms-excel'});
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-excel");
                el.download = "orders.xls";
                el.href = fileURL;
                el.click();
                setTimeout(function(){
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            })
        },
        getDate (date) {
            if (date) { return moment(String(date)).format("DD/MM/YYYY HH:mm")}
            else { return '--'}
        },
    }
}

</script>

<style lang="scss" scoped>
.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    font-size: 1.3em;
    display: flex;
    align-items: center;
    padding: 1vh 0;
    margin-top: 4vw;
}

.price-analysis-mobile-header {
    display: none;
}

.page-table-header-text {
    font-weight: 400;
    color: #605F5F;
    padding-left: 10px;
}

.name-width {
    flex: 1;
}

.expand-width {
    border-left: 1px solid #DDE5E9;
    text-align: center;
    width: 3%;
}

.total-width {
    width: 15%;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    color: #605F5F;
    border-bottom: 1px solid #DDE5E9;
    align-items: center;
    cursor: pointer;
}

.identifier-container{
    margin-top: 2vh;
}

.page-table-line-special {
    background: #F7F7F7;
}

.page-table-desc-column {
    padding: 0.8em;
    border-right: 1px solid #DDE5E9;
}

.sub-table-colum {
    flex: 1;
    padding-left: 2%;
    border-right: 1px solid #DDE5E9;
    border-bottom: 1px solid #DDE5E9;
}

.reports-page__title-button-total {
    background: rgba(23, 162, 184, 0.1);
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
    display: flex;
    align-items: center;
    padding: 0 1vw;
    height: 7.5vh;
}

.page-filters__datetime-container {
    display: flex;
    align-items: end;
    margin-top: 2em;
}

.page-filters__right-title {
    font-weight: 400;
    font-size: 1.7em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #898989;
}

.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;
}

.date-icon {
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 0px 8px 8px 0px;
    position: relative;
    left: -6px;
}

.search-icon {
    margin: 0em 0.2em;
    color: #fff;
    background-color: var(--primary-color);
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.action-buttons-width {
    margin-top: 20px;
    display: flex;
    gap: 4vw;
    margin-left: auto;
}

.page-filters__right-wrapper {
    margin-right: 10px;
}
.mobile{
    display: none;
}

@media only screen and (max-width: 800px) {
    .page-filters__datetime-container {
        flex-wrap: wrap;

    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }

    .action-buttons-width {
        width: 100%;
    }

    .page-filters__right-wrapper {
        margin-right: 0;
        width: 50%;
    }

    .total-width {
        width: 25%;
    }

    .expand-width {
        width: 10%;
    }
    .mobile-button-size{
        width: 50%;
        justify-content: center;
    }

}
</style>