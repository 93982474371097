<style lang="scss">

.client-sidebar {
    height: 100%;
    padding-top: 5%;
    z-index: 3;
    display: flex;
    position: fixed;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    font-size: 1vw;
    width: 6vw;
    overflow: hidden;
    transition: width 0.5s;
}
.new-sidebar__list {
  padding: 1vh 1vw;
  display: flex;
  cursor: pointer;
  border-left: 3px solid transparent;
  align-items: center;
  &:hover{
    border-left: 3px solid var(--primary-color);
    background-color: #fdf0e9;
  }
}
.new-sidebar__icon-color {
  color: #7c7c7c;
  font-size: 2em;
  padding: 0 0.7vw;
  border-radius: 7px;
  display: flex;
  align-items: center;
  height: 6.5vh;
}
.menu-option-2{
    width: 0;
    transition: width 0.15s ease-out;
    overflow: hidden;
    position: absolute;
    margin-left: 5.5vw;
    color: #7c7c7c;
    background-color: #fdf0e9;
    border-radius: 7px;
    padding: 2vh 0;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: -5px;
}
.menu-option-1{
    color: #7c7c7c;
    display: flex;
    align-items: center;
    border-radius: 0 7px 7px 0;
    text-transform: uppercase;
    margin-left: 3vw;
    white-space: nowrap;
}
.green-icon{
    color: lightgreen !important;
}
.active-class {color: var(--primary-color);}
.client-sidebar-arrow-container{
    position: absolute;
    background-color: white;
    margin-left: 5vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid var(--primary-color);
    }
}
.client-sidebar-arrow-container2{
    position: absolute;
    background-color: white;
    margin-left: 25vw;
    margin-bottom: 76vh;
    border-radius: 50%;
    padding: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-color);
    box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -webkit-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 1px 10px  rgba(0,0,0,0.1);
    &:hover{
        outline: 1px solid var(--primary-color);
    }
}
.sidebar-home-alert{
    font-weight: 400;
    font-size: 1em;
    background-color: var(--primary-color);
    color: #FFFFFF;
    border-radius: 5px;
    padding: 0.5vw;
    white-space: nowrap;
    margin-left: 3vw;
}
.sidebar-options{
    position: absolute;
    margin-left: 22.1vw;
    margin-top: 10vh;
    z-index: 4;
    background-color: white;
    border-radius: 8px;
    padding: 0.5vh 0;
    display: flex;
    flex-direction: column;
    width: max-content;
    outline: 1px solid #ABABAB;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}
.sidebar-option{
    padding: 0.5vh 1vw;
    &:hover{background-color: #fdf0e9;}
}

@media only screen and (max-width: 630px) {
  .client-sidebar {
    display: none;
  }
}
</style>
<template>
    <div class="client-sidebar" :style="getExpandstyles()" @mouseover="showExpand()" @mouseleave="hideExpand()">
        <div class="hover-container" v-for="menu of permitted_menus()" :key="menu.name" @mouseover="showMenuOptions(menu)" @mouseleave="hideMenuOptions(menu)">
            <router-link :to="menu.link" v-on:click="redirect(menu)"  class="new-sidebar__list" >
                    <span :class="{ 'active-class' : menu.active, active: menu.active, 'material-icons': menu.icon == 'local_offer','green-icon': menu.icon == 'local_offer', 'material-icons-outlined': menu.icon != 'local_offer'}"  class="new-sidebar__icon-color">
                        {{ menu.icon }}
                    </span>
                <div class="menu-option-1" >
                    {{menu.name}}
                </div>
                <div v-if="isExpanded && menu.name == 'Home'" class="sidebar-home-alert">
                    Começe por Aqui
                </div>
                <span v-if="menu.options" class="material-icons-outlined" style="color: var(--primary-color);">chevron_right</span>
                <div class="sidebar-options" v-if="menu.showOptions && isExpanded" >
                    <router-link :to="option.link"  class="sidebar-option menu-option-1" v-for="option in menu.options" :key="option.name">
                        {{option.name}}
                    </router-link>
                </div>
            </router-link>
        </div>
        <div class="hover-container">
            <div v-on:click="logout()" class="new-sidebar__list">
                <span class="material-icons-outlined new-sidebar__icon-color">
                    logout
                </span>
                <div v-if="isExpanded" class="menu-option-1" >
                    sair
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import * as CONSTANTS from "@/constants/constants";
import StorageService from '@/services/TokenService';
import { perm_mixin } from '@/mixins/permission.mixin'

export default {
    mixins: [ perm_mixin ],
    data() {
        return {
            storage_svc: new StorageService(),
            isExpanded: false,
            fullExpand: false,
            CONSTANTS: CONSTANTS,
            menus: [
                {
                    name: "Home",
                    resource: 'HOME',
                    icon: "home",
                    link: '/home-page/',
                    active: false,
                    route: 'home'
                },
                {
                    name: "Dashboard",
                    resource: 'REPORT',
                    icon: "donut_large",
                    link: '/dashboard/',
                    active: false,
                    route: 'relatorios'
                },
                {
                    name: "Produtos",
                    resource: 'PRODUCT',
                    icon: "inventory_2",
                    link: '/produtos/',
                    route: "products",
                    active: false,
                },
                {
                    name: "Meus Fornecedores",
                    resource: 'SELLER',
                    icon: "groups",
                    link: '/fornecedores/',
                    route: "sellers",
                    active: false,
                },
                {
                    name: "Mapa de Fornecedores",
                    resource: 'MAP',
                    icon: "map",
                    link: '/explorar/',
                    active: false,
                    action: 'READ_PROVIDERS_MAP',
                    route: 'explore'
                },
                {},
                {
                    name: "painel de operações",
                    resource: 'REQUEST',
                    icon: "desktop_windows",
                    link: CONSTANTS.ROUTE_MONITORS,
                    params: { tab: 'cotacoes' },
                    route: "monitors-page",
                    active: false,
                    showOptions: false,
                    options: [
                        {
                            name: "Monitor de Cotação",
                            link: "/cliente/monitores/cotacoes"
                        },
                        {
                            name: "Monitor de Ressuprimento",
                            link: "/cliente/monitores/ressuprimento"
                        },
                        {
                            name: "Lista Mobile",
                            link: "/cliente/monitores/mobile"
                        },
                        {
                            name: "Pedidos",
                            link: "/cliente/monitores/pedidos"
                        },
                        // {
                        //     name: "Monitor Preço de Venda Varejo",
                        //     link: "/cliente/monitores/price"
                        // },
                    ]
                },
                {
                    name: "Relatórios",
                    resource: 'REPORT',
                    icon: "trending_up",
                    link: '/relatorios/',
                    active: false,
                    route: 'relatorios'
                },
                {
                },
                // {
                //     name: "Produtos em Destaque",
                //     resource: 'PRODUCT',
                //     action: 'SEE_HIGHLIGHTS',
                //     icon: "fas fa-medal",
                //     link: '/destaques',
                //     route: "highlights-index",
                //     active: false,
                // },
                {
                    name: "Minhas Configurações",
                    icon: "settings",
                    resource: 'ACCOUNT_SETTINGS',
                    action: 'READ',
                    link: CONSTANTS.ROUTE_SETTINGS,
                    route: "user-settings",
                    active: false,
                }
            ]
        };
    },
    computed: {

    },
    watch: {
        perms: function() {
            setTimeout(() => {
                this.process_request_permission_menu()
            }, 400);
        },
        '$route' (to, from){
            this.menus.forEach(m => {
                if(to.name == m.route) {
                    this.activate(m)
                }
            })
        }
    },
    methods: {
        permitted_menus: function() {
            return this.menus.filter(menu => menu.link)
                .filter(menu => menu.resource == null || this.can(menu.resource, menu.action || 'READ'))
        },
        hideMenuOptions(menu){
            if (menu.options) {
                menu.showOptions = false
                this.fullExpand = false
            }
        },
        showMenuOptions(menu){
            if (menu.options) {
                menu.showOptions = true
                this.fullExpand = true
            }
        },
        getExpandstyles() {
            if (this.fullExpand) return  'border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); width: 25vw; overflow: visible'
            if (this.isExpanded) return 'border-right: 1px solid #ABABAB; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05); width: 25vw'
        },
        showExpand() {
            this.isExpanded = true
        },
        hideExpand() {
            this.isExpanded = false
            this.menus.forEach(menu => menu.showOptions = false)
        },
        redirect(menu){
            if (!menu.options) {
                this.menus.forEach(m => m.active = false)
                menu.active = true;
                this.$forceUpdate()
                this.isExpanded = false
                // this.$router.push(menu.link)
            }
        },
        logout() {
            this.$store.dispatch('user/logout')
            window.location.assign(CONSTANTS.ROUTE_LOGIN)
        },
        activate(menu) {
            this.menus.forEach(m => m.active = false)
            menu.active = true;
            this.$forceUpdate()
        },
        defineMenuItemActive() {
            this.menus.map((menu,index) => {
                this.menus[index].active = false;
                if ( menu.link === this.$route.path ) {
                    this.menus[index].active = true;
                }
            });
        },
        process_request_permission_menu() {
            if (JSON.parse(this.storage_svc.get('showOffers'))){
                this.menus[8] = {
                    name: "Ofertas",
                    resource: 'CAMPAIGN',
                    icon: "local_offer",
                    link: '/ofertas',
                    route: "campaigns-index",
                    active: false,
                }
                this.$forceUpdate()
            }

            if(this.can('REQUEST', 'CREATE') || this.can('REQUEST', 'CREATE_RESUPPLY')) {
                let action = null
                if(this.can('REQUEST', 'CREATE')) {
                    action = 'CREATE'
                } else if(this.can('REQUEST', 'CREATE_RESUPPLY')) {
                    action = 'CREATE_RESUPPLY'
                }
                this.menus[5] = {
                    name: "Nova Compra/Requisição",
                    resource: 'REQUEST',
                    action: action,
                    icon: "add_shopping_cart",
                    link: CONSTANTS.ROUTE_NEW_QUOTATION_REQUEST,
                    active: false,
                }
                this.$forceUpdate()
            }
            this.$forceUpdate()
        }
    },
    mounted(){
        this.process_request_permission_menu()
        this.defineMenuItemActive();
    },
    beforeRouteUpdate (to, from, next) {
        this.defineMenuItemActive();
    }
}
</script>
