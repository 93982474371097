<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="product-history__header-container">
                <span class="product-history__header-title">
                    Histórico - {{ product.pro_descricao }}
                </span>
                <span @click="close" class="material-icons product-history__closebtn-icon">
                    cancel
                </span>
            </div>

            <body class="log-body">
                <div class="log-buttons-container">
                    <StandardButton :iconName="'group'" :action="() => tab = 'digi'" :text="'Histórico de digitação'"
                        :class="{ 'button-active': tab == 'digi' }" />
                    <StandardButton :iconName="'group'" :action="() => tab = 'request'"
                        :text="'Histórico de requisição'" :class="{ 'button-active': tab == 'request' }" />
                </div>

                <template v-if="tab == 'digi'">
                    <div class="inputs-container">
                        <div class="page-filters__right-wrapper">
                            <div class="page-filters__right-title-wrapper">
                                <div class="products-page__datetime-title">Data Inicial</div>
                            </div>
                            <div class="page-filters__right-select-wrapper">
                                <datetime value-zone="UTC-3" class="datetime-input form-control" v-model="fromDate"
                                    ref="datepicker" auto type="date"></datetime>
                                <span class="material-icons date-icon">
                                    search
                                </span>
                            </div>
                        </div>
                        <div class="page-filters__right-wrapper">
                            <div class="page-filters__right-title-wrapper">
                                <div class="products-page__datetime-title">Data Final</div>
                            </div>
                            <div class="page-filters__right-select-wrapper">
                                <datetime value-zone="UTC-3" class="datetime-input form-control" v-model="toDate"
                                    ref="datepicker" auto type="date"></datetime>
                                <span class="material-icons date-icon">
                                    search
                                </span>
                            </div>
                        </div>
                        <StandardInput v-if="!brand" title="marca" type="select" :list="brandList" :action="v => selectBrand = v"/>


                        <StandardButton style="margin-left: auto;" :action="() => loadPage(1)" :text="'Filtrar'" />
                    </div>
                    <div class="modal-table-header">
                        <div class="log-table-header cot-width">Cotação</div>
                        <div class="log-table-header company-width">Empresa / Vendedor</div>
                        <div class="log-table-header history-name-width">Fat. Min.</div>
                        <!-- <div class="log-table-header history-name-width">Forma</div> -->
                        <div class="log-table-header type-width">Preço</div>
                        <div class="log-table-header type-width">Marca</div>
                        <div class="log-table-header date-width">Data</div>
                        <div class="log-table-header old-width">Obs</div>
                        <!-- <div class="log-table-header new-width">Dado novo</div> -->
                    </div>
                    <h1 class="empty-alert" v-if="!isLoading && logs.length < 1">Sem registro</h1>
                    <div v-for="(log, idx) in logs" :key="idx">
                        <!-- desktop -->
                        <div class="log-table-row" :class="{ 'page-table-line-special': idx % 2 != 0 }">
                            <div class="log-table-header cot-width">{{ log.request.id }}</div>
                            <div style="text-transform: uppercase;" class="log-table-header company-width">{{
                                log.provider.trading_name }} <br> {{ log.seller.name }}</div>
                            <div class="log-table-header history-name-width">{{ log.delivery.faturamento_minimo |
                                currency }}</div>
                            <!-- <div class="log-table-header history-name-width">{{ log.delivery.forma }}</div> -->
                            <div class="log-table-header type-width action-text"
                                :title="log.purchased ? 'Este item foi comprado' : ''">
                                <span :class="{ 'line-special-purchased': log.purchased }">{{ log.price | currency
                                    }}</span>
                            </div>
                            <div class="log-table-header type-width action-text">{{ log.product.brand }}</div>
                            <div class="log-table-header date-width">{{ log.request.created_at | formatDateOnly }}</div>
                            <div class="log-table-header old-width" :title="log.obs">
                                {{ log.obs || '--' }}
                            </div>

                        </div>

                        <!-- mobile -->
                        <ProductHistoryMobileLine :log="log" />
                    </div>
                    <pagination v-if="totalItems == 0 || totalItems > 14" classes="orange" :total="totalItems" :page="1"
                        :page_limit="11" :items_by_page_value="15" v-on:change="loadPage($event)"
                        style="margin-top: 5vh;">
                    </pagination>
                </template>


                <template v-else>
                    <div class="modal-table-header">
                        <div class="log-table-header order-cot-width">Cotação</div>
                        <div class="log-table-header order-cot-width">Digitações</div>
                        <div class="log-table-header order-cot-width">Pedidos</div>
                        <div class="log-table-header order-cot-width">Data de Criação</div>
                    </div>
                    <h1 class="empty-alert" v-if="!isLoading && logs.length < 1">Sem registro</h1>
                    <div v-for="(order, idx) in orders" :key="idx">
                        <!-- desktop -->
                        <div class="log-table-row" :class="{ 'page-table-line-special': idx % 2 != 0 }">
                            <div class="log-table-header order-cot-width">{{ order.request.id }}</div>
                            <div style="text-transform: uppercase;" class="log-table-header order-cot-width">{{
                                order.offers_count }}</div>
                            <div class="log-table-header order-cot-width">{{ order.orders_count }}</div>
                            <div class="log-table-header order-cot-width ">
                                {{ order.request.created_at | formatDateOnly }}
                            </div>

                        </div>
                    </div>
                </template>



            </body>
        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import OrderService from "@/services/v3/products/order.service";
import ProductService from "@/services/ProductService";
import pagination from "@/components/cliente/base-components/Pagination";
import ProductHistoryMobileLine from "./ProductHistoryMobileLine.vue";
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'

export default {
    name: 'ProductHistoryModal',
    props: ['close', 'product', 'brand', 'propFromDate', 'propToDate'],
    components: {
        pagination,
        ProductHistoryMobileLine,
        StandardButton,
        StandardInput
    },
    data() {
        return {
            svc: new OrderService(),
            prod_svc: new ProductService(),
            selectBrand: undefined,
            tab: 'digi',
            isLoading: true,
            logs: [],
            orders: [],
            totalItems: 0,
            fromDate: this.propFromDate,
            toDate: this.propToDate,
            brandList: []
        }
    },
    watch: {
        tab(value) {
            if (value == 'request' && this.orders.length == 0) this.loadOrders()
        },
        selectBrand(){
            this.loadPage(1)
        }
    },
    methods: {
        loadPage(page) {
            this.isLoading = true
            const brand = this.selectBrand ? this.selectBrand.pro_id || this.selectBrand.prod_id : undefined
            
            this.svc.getProductHistory(this.product.pro_id || this.product.prod_id, page, this.fromDate, this.toDate, brand).then(resp => {
                this.logs = resp.data.data
                this.totalItems = resp.data.meta.total
                this.isLoading = false
            })
        },
        loadOrders() {
            this.isLoading = true
            this.svc.getOrders(this.product.pro_id).then(resp => {
                this.orders = resp.data.sort((a, b) => b.request.id - a.request.id)
                this.isLoading = false
            })
        },
        loadBrands() {
            this.prod_svc
                .get_product_brands(this.product.pro_id)
                .then((data) => {
                    this.brandList = data.data.produtos.sort((a, b) => a.pro_observacao.localeCompare(b.pro_observacao)).map(v => {return {text: v.pro_observacao, value:v}});
                })
        },
    },
    created() {
        if (this.brand) this.selectBrand = this.brand
        else this.loadBrands()
        this.loadPage(1)
    }
}
</script>

<style scoped lang="scss">
.modal-mask {
    position: fixed;
    z-index: 4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    font-size: 1vw;
}

.modal-content-container {
    background-color: white;
    width: 90vw;
    border-radius: 12px;
    border-radius: 10px 10px 10px 10px;
    padding-bottom: 2vh;
}

.product-history__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--primary-color);
}

.product-history__header-title {
    color: #ffffff;
    font-weight: 500;
    font-size: 2.5em;
}

.product-history__closebtn-icon {
    color: #ffffff;
    font-size: 3.5em;
    cursor: pointer;
}

.log-body {
    height: 100vh !important;
    overflow-y: auto;
    box-sizing: border-box;
    padding: 3% 4%;
    background-color: white;
}

.modal-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 2vh 0;
    padding-left: 2%;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 17px;
    color: #605F5F;
    font-weight: bold;
    margin-bottom: 1vh;
}

.log-table-header {
    font-size: 1.2em;
}

.log-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    align-items: center;
    padding: 2vh 0 2vh 2%;
}

.company-width {
    flex: 1;
    box-sizing: border-box;
    padding-right: 1vw;
}

.log-buttons-container {
    display: flex;
    gap: 5vw;
    margin-bottom: 5vh;
}

.cot-width {
    width: 8%;
}

.order-cot-width {
    flex: 1;
}

.history-name-width {
    width: 10%;
}

.type-width {
    width: 10%;
}

.date-width {
    width: 10%;
}

.order-date-width {
    width: 20%;
}

.old-width {
    width: 12%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.new-width {
    width: 15%;
}

.page-table-line-special {
    background: #F7F7F7;
}

.empty-alert {
    text-align: center;
    color: lightgray;
    font-size: 5em;
    margin-top: 15%;
}

.action-text {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1em;
}

.button-active {
    background-color: #fdf0e9;
}

.line-special-purchased {
    background-color: lightgreen;
    padding: 5px 10%;
    border-radius: 8px;
    color: white;
}

.page-filters__right-title-wrapper {
    display: flex;
    align-items: center;
}

.products-page__datetime-title {
    font-size: 1.7em;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #898989;
}

.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;
}

.date-icon {
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    padding: 15px 10px;
    border-radius: 0px 8px 8px 0px;
    position: relative;
    left: -6px;
    height: 6vh;
}

.datetime-input {
    border-radius: 8px !important;
    height: 6vh;
}

.inputs-container {
    display: flex;
    gap: 2vw;
    margin-bottom: 3vh;
    align-items: end;
}

@media only screen and (max-width: 800px) {
    .modal-mask {
        font-size: 2vw;
    }

    .modal-content-container {
        width: 110vw;
    }

    .modal-table-header {
        display: none;
    }

    .log-table-row {
        display: none;
    }
}
</style>