<template>
    <!-- <div class="monitors-winners">
        <a id="download-pdf" style="display:none;"></a>
        <div class="body winner-typing">
            <cc-loader :text="'Carregando..'" v-if="is_loading" />
            <div class="container-fluid header-info" v-else>
                <div class="row">
                    <div class="col">
                        <h4 class="header-title"><i class="fa fa-handshake" aria-hidden="true"></i>Realizar Pedido</h4>
                        <h5 class="header-subtitle">Cotação #{{$route.params.id}}</h5>
                    </div>
                    <div class="col not-print">
                        <div class="providers-nav">
                            <span class="prev hvr-backward" @click="navigate(-1)">
                                <i class="fas fa-backward"></i>
                            </span>
                            <span class="next hvr-forward" @click="navigate(1)">
                                <i class="fas fa-forward"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <Summary
                    :seller="seller"
                    :store="store"
                    :request="request"
                    :order="order" v-if="seller" />

                <no-data-available v-if="!is_loading && !seller" :text="'Sem pré-pedidos'" />

                <div class="row action-buttons not-print" v-if="store && seller">
                    <div class="col info">
                        <button style="color:black !important" class="info" disabled>
                            <i class="fas fa-coins"></i>
                            Valor Total: {{ total | currency }}
                        </button>
                        <button class="info" disabled :class="{
                            'positive-credit' : balance() == 0,
                            'negative-credit' : balance() < 0
                         }">
                            Saldo: {{ balance() | currency }}
                        </button>
                    </div>
                    <div class="col">
                        <router-link :to="return_url" tag="button" v-bind:class="'back'">
                            <i class="fa fa-arrow-left" style="margin-right: 8px;font-size: 1.5rem;color: #71727d;padding-bottom: 2px;"></i>
                            Voltar
                        </router-link>
                        <button v-on:click="download_pdf()">
                            <i class="fas fa-print"></i>
                            <span>Imprimir</span>
                        </button>
                        <button class="order" v-on:click="send_order" v-if="can('REQUEST_ORDER', 'CREATE') && (!order || order.status == 'PENDING')">
                            <i class="fa fa-shopping-cart"></i>
                            Realizar Pedido
                        </button>
                        <button class="order cancel" v-on:click="cancel_order" v-if="can('REQUEST_ORDER', 'CREATE') && order && order.status != 'PENDING'">
                            <i class="fa fa-times-circle"></i>
                            Cancelar Pedido
                        </button>
                    </div>
                </div>

                <div class="row" v-if="!is_loading && seller">
                    <div class="col pr-1">
                        <Offers
                            v-if="SECTION == 'TYPINGS'"
                            :request="request"
                            :seller="seller"
                            :store_id="store_id"
                            :order="order"
                            @total="total = $event"
                            @loaded="build_nav"
                            @reload="reload" />
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div>
        <div v-if="is_loading">
            <cc-loader-full />
        </div>
        <div v-else class="makeorder-page">
            <a id="download-pdf" style="display:none;"></a>
            <div class="page-route-select">
                <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                    Home
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                    Monitor de Cotação
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <router-link :to="'/cliente/monitores/vencedores/' + $route.params.id" class="page-route-select-text">
                    Comparativo de Preço
                </router-link>
                <span class="material-icons page-route-select-icon">play_arrow</span>
                <div class="page-route-select-text">
                    Não Digitados
                </div>
            </div>
            <div class="makeorder-page-title-container">
                <div class="makeorder-page-title">
                    <div>Realizar Pedido</div>
                    <div class="makeorder-page-subtitle">Cotação #{{$route.params.id}}</div>
                </div>
                <div>
                    <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate(-1)">skip_previous</span>
                    <span class="material-icons-outlined makeorder-page-title-icons" @click="navigate(1)" style="margin-left: 1vw;">skip_next</span>
                </div>
            </div>
            <div class="makeorder-page-info-container">
                <div class="makeorder-page-info-texts">
                    <div class="makeorder-page-info-buyer-colum">
                        <div class="makeorder-page-info-buyer-title-text">Comprador</div>
                        <div class="makeorder-page-info-first-line makeorder-page-info-subtitle">Comprador:</div>
                        <div class="makeorder-page-info-text">{{ store.name.toUpperCase() }}</div>
                        <div class="makeorder-page-info-text">{{ store.street }}, {{ store.district }} - {{ store.city.name }}/{{ store.state.name }}</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Fone:</div>
                            <a class="makeorder-page-info-text" v-if="store.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(store.phone)" target="_blank">{{ store.phone | VMask('(##) #####-####')  }}</a>
                            <div class="makeorder-page-info-text" v-else>--</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">CNPJ:</div>
                            <div class="makeorder-page-info-text">{{ store.cnpj | cnpj }}</div>
                        </div>
                    </div>
                    <div class="makeorder-page-info-suplier-colum" v-if="seller">
                        <div class="makeorder-page-info-buyer-title-text">Fornecedor</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-suplier-raiting-text">{{ seller.provider.name }}</div>
                        </div>
                        <div class="makeorder-page-info-text">{{ seller.name }}</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">E-mail:</div>
                            <div class="makeorder-page-info-text">{{ seller.email }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Fone:</div>
                            <img class="makeorder-page-info-whats-icon" src="@/assets/WhatsApp_black.svg" alt="mandar mensagem">
                            <a class="makeorder-page-info-text" v-if="seller.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(seller.phone)" target="_blank">{{ seller.phone | VMask('(##) #####-####')  }}</a>
                            <div class="makeorder-page-info-text" v-else>--</div>
                        </div>
                    </div>
                    <div class="makeorder-page-info-details-colum" v-if="seller">
                        <div class="makeorder-page-info-buyer-title-text">Detalhe do Pedido</div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Entrega:</div>
                            <div class="makeorder-page-info-text">{{ seller.delivery.date | formatDateOnly }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Tipo de Entrega:</div>
                            <div class="makeorder-page-info-text">{{ seller.delivery.tcp }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Faturamento:</div>
                            <div class="makeorder-page-info-text">{{ seller.delivery.minimum_billing | currency }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Cond. de Pgto:</div>
                            <div class="makeorder-page-info-text">{{ seller.form }}</div>
                        </div>
                        <div class="makeorder-page-info-suplier-raiting">
                            <div class="makeorder-page-info-subtitle">Status:</div>
                            <div v-if="!order" class="makeorder-page-info-status-pending">pendente</div>
                            <div v-else class="makeorder-page-info-status-created">gerado</div>
                        </div>
                    </div>
                </div>
                <div class="makeorder-page-info-obs-colum">
                    <div class="makeorder-page-info-buyer-title-text makeorder-page-info-obs-title-container">
                        Observação
                        <span v-if="can('REQUEST_ORDER', 'CREATE') && (!order || order.status == 'PENDING')" @click="save_request_detail_obs" class="material-icons-outlined makeorder-page-info-obs-title-icon">save</span>
                    </div>
                    <textarea v-if="current_subrequest" :disabled="can('REQUEST_ORDER', 'CREATE') && order && order.status != 'PENDING'" v-model="current_subrequest.obs" class="makeorder-page-info-obs-textarea" placeholder="Digite aqui ser houver alguma observação" cols="30" rows="8"></textarea>
                </div>
            </div>
            <div class="makeorder-page-buttons-row">
                <div v-if="current_subrequest" class="makeorder-page-buttons-total">
                    <span class="material-icons-outlined makeorder-page-buttons-total-icon">attach_money</span>
                    Valor Total: {{ total | currency }}
                </div>
                <div class="makeorder-page-buttons-balance">
                    Saldo: {{ balance() | currency }}
                </div>
                <div class="makeorder-page-buttons-blank"></div>
                <StandardButton :action="sendBack" :iconName="'arrow_back'" :text="'Voltar'"/>
                <StandardButton class="makeorder-page-button-right" :action="download_pdf" :iconName="'print'" :text="'Imprimir'"/>
                <div class="makeorder-page-button-order" v-on:click="send_order" v-if="can('REQUEST_ORDER', 'CREATE') && (!order || order.status == 'PENDING')">
                <span class="material-icons-outlined makeorder-page-button-order-icon">shopping_cart</span>
                    Realizar Pedido
                </div>
                <div class="makeorder-page-button-cancelorder" v-on:click="cancel_order" v-if="can('REQUEST_ORDER', 'CREATE') && order && order.status != 'PENDING'">
                    <span class="material-icons-outlined makeorder-page-button-cancelorder-icon">block</span>
                    Cancelar Pedido
                </div>
            </div>
            <div class="makeorder-page-buttons-row">
                <div class="makeorder-page-buttons-section">Itens do Pedido</div>
            </div>
            <Offers
                v-if="SECTION == 'TYPINGS'"
                :request="request"
                :seller="seller"
                :store_id="store_id"
                :order="order"
                @total="total = $event"
                @loaded="build_nav"
                @reload="reload" />
        </div>
    </div>
</template>
<script>
import Summary from './components/_summary.component.vue'
import Pagination from "@/components/cliente/base-components/Pagination";
import Offers from "./components/_offers.componentV2";
import V3RequestsService from "@/services/v3/request.service"
import V3OrderService from "@/services/v3/requests/order.service"
import PreOrderService from "@/services/v3/requests/pre-order.service"
import OfferService from "@/services/v3/requests/offer.service"
import ProviderService from "@/services/v3/provider.service"
import StoreService from "@/services/v3/stores/store.service"
import QuotationService from "@/services/QuotationService";
import OrderService from "@/services/OrderService";
import V2OrderService from "@/services/v2/order.service";
import RequestsService from "@/services/v2/requests.service";
import PreOrderCollection from '@/models/requests/pre-order.collection'
import * as CONSTANTS from "@/constants/constants";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import SellerReviewModal from '@/modals/seller-review/seller-review.modal';
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState, mapActions } from 'vuex'
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'


export default {
    mixins: [ perm_mixin, loaderMixin ],
    props: {
        SECTION: {
            type: String,
            default: 'TYPINGS'
        },
        id: [ Number, String ],
        store_id: [ Number, String ],
        return_to: {
            type: String,
            default: 'request'
        },
        seller_id: [ Number, String ],
    },
    components: {
        ccPagination: Pagination,
        Summary,
        Offers,
        'cc-seller-review' : SellerReviewModal,
        StandardButton,
    },
    computed: {
        ...mapState('user', [ 'stores' ]),
        return_url: function() {
            return {
                name: this.return_to,
                params: { id: this.$route.params.id, request_id: this.$route.params.id },
                query: { tab: this.$route.query.tab }
            }
        }
    },
    data() {
        return {
            total: 0,
            order: null,
            request: null,
            trigger: false,
            is_loading: true,
            CONSTANTS: CONSTANTS,
            sellers: [],
            offer_svc: new OfferService(),
            orderService: new OrderService(),
            svc: new V3RequestsService(),
            quotationService: new QuotationService(),
            service: new RequestsService(),
            v3_order_svc: new V3OrderService(),
            v2_order_svc: new V2OrderService(),
            po_svc: new PreOrderService(),
            prov_svc: new ProviderService(),
            store_svc: new StoreService(),
            current_subrequest: null,
            store: null,
            seller: null,
            pre_order_collection: null,
            current: null,
            breadcrumbs: [
                {
                    link: CONSTANTS.ROUTE_MONITORS,
                    name: "Monitor de Cotações"
                },
                {
                    link: CONSTANTS.ROUTE_MONITORS + "/vencedores/" + this.$route.params.id,
                    name: "Cotação " + this.$route.params.id
                },
                {
                    name: "Pré-Pedidos"
                },
                {
                    name: "Realizar Pedido"
                }
            ]
        }
    },
    methods: {
        ...mapActions('requests', [ 'set_request' ]),
        save_request_detail_obs() {
            let data = {
                obs: this.current_subrequest.obs,
                loj_id: this.$route.params.store_id,
                usu_id: this.$route.params.seller_id
            }
            return this.service.save_pre_request_details(this.$route.params.id, data).then(response => response.data)
            .then(data => {
                this.request_detail_obs_editing = false
                this.$swal.fire(
                    "Salvo!",
                    "Suas observações foram salvas com sucesso!",
                    "success"
                )
            }, err => {
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao salvar observações.",
                    "error"
                )
            })
        },
        load_subrequests() {
            return this.service.subrequests_with_sellers_for(this.$route.params.id)
            .then(response => response.data).then(data => {
                let subrequests = data;
                let next = subrequests.find(s => s.for_id == this.current.provider.id && s.vendedor_id == this.$route.params.seller_id && s.loj_id == this.$route.params.store_id)

                if(next) {
                    this.current_subrequest = next
                }
            })
        },
        formatNumber(number){
            if (!number) return null
            number = number.replace('+','').replace('(','').replace(')','').replace('-','')
            if (number[0] + '' + number[1] != '55' ) number = '55' + number
            return number
        },
        sendBack() {this.$router.push(this.return_url)},
        balance() {
            if (!this.seller) return 0
            return (this.seller.delivery.minimum_billing - this.total) < 0
                ? 0
                : (this.seller.delivery.minimum_billing - this.total)*-1
        },
        print() {
            self.print();
        },
        navigate(direction) {
            let to = direction < 0 ? this.current.prev : this.current.next
            this.$router.push({
                name: 'subrequest',
                params: {
                    id: this.id,
                    seller_id: to.id,
                    store_id: this.store_id
                },
                query: {
                    tab: this.$route.query.tab
                }
            })
            this.init()
        },
        download_pdf() {
            let callback = data => {
                var file = new Blob([data], { type: "application/pdf" });
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-pdf");
                var title = "pre-pedido-vendedor.pdf"
                if(this.order) {
                    title = "Pedido-" + this.order.id + ".pdf"
                }
                el.download = title
                el.href = fileURL;
                el.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
                this.is_loading_pdf = false;
            }

            this.is_loading_pdf = true;
            if(this.order) {
                this.v3_order_svc.download_pdf(this.order.id).then(response => response.data)
                .then(callback)
                .catch(error => {
                    this.is_loading_pdf = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
            } else {
                this.po_svc.download_pdf(this.request.id, {
                    seller_id: this.seller_id,
                    store_id: this.store_id
                }).then(response => response.data)
                .then(callback)
                .catch(error => {
                    this.is_loading_pdf = false;
                    ErrorHandlerService.handle(error, this.$store);
                });
            }
        },
        minimum_billing_reached() {
            let mb = this.seller.provider.minimum_billing || 0
            return this.total > mb
        },
        send_order() {
            let mb_reached = this.minimum_billing_reached(this.store_id, this.seller_id)
            let confirmation_text = "<h2 style=''>Confirma realização do pedido?</h2>"
            confirmation_text += "</br><span>Seu pedido será enviado ao fornecedor!</span>"
            if(!mb_reached) {
                confirmation_text = "<h2 style='color:#d33'>Atenção! Faturamento minimo não atingido</h2>"
                confirmation_text += "</br><span>Deseja continuar?</span>"
            }
            this.confirm_action({
                html: confirmation_text,
                icon: 'warning',
                callback: () => {
                    this.is_loading = true
                    this.v3_order_svc.send_by_store_seller(this.request.id, this.store_id, this.seller_id)
                    .then((response) => response.data).then((response) => {
                        this.order = response
                        this.v3_order_svc.sync_legacy(response.id)
                        .then((response) => response.data).then((legacy) => {
                            this.v2_order_svc.notify_legacy(legacy.id_pedido).then(() => {
                                this.is_loading = false
                                this.present_info("Seu pedido foi realizado com sucesso.")
                                this.reload()
                            })
                        })
                    })
                }
            })
        },
        cancel_order() {
            this.confirm_action({
                message: 'Confirma cancelamento do pedido?',
                callback: () => {
                    this.is_loading = true
                    this.v3_order_svc.cancel_by_store_seller(this.request.id, this.store_id, this.seller_id)
                    .then(() => {
                        this.v3_order_svc.cancel_legacy(this.order.legacy_id)
                        .then(() => {
                            this.order = null
                            this.is_loading = false
                            this.present_info('Pedido cancelado com sucesso!')
                            this.reload()
                        })
                    })
                }
            })
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                { name: "Painel de Operações", route: 'monitors-page' },
                { name: "Cotações", route: 'monitors-page' },
                { name: `#${this.$route.params.id}`, route: 'cliente-monitores-vencedores' },
                { name: "detalhes" }
            ])
        },
        async load_request(params = {}) {
            this.request = await this.svc.load(this.id).then(response => response.data).catch((error) => console.log(error))
            this.set_request(this.request)
            if(params.done) {
                params.done(this.request)
            }
        },
        load_order() {
            this.order = null
            return this.v3_order_svc.order_by_store_seller(this.request.id, this.store_id, this.seller_id)
                .then(response => this.order = response.data)
                .catch((error) => console.log(error))
        },
        load_seller() {
            return this.prov_svc.show(this.seller_id, { request_id: this.request.legacy_id })
            .then(response => response.data)
            .then(response => {
                this.seller = response
            })
            .catch((error) => console.log(error))
        },
        reload() {
            this.load_request({
                done: () => {
                    this.is_loading = false
                    this.load_order()
                }
            })
        },
        build_nav(pos) {
            this.pre_order_collection = new PreOrderCollection(pos, this.stores, this.order ? 'ORDERED' : 'NOT_ORDERED')
            this.pre_order_collection.store(this.store_id)
            this.current = this.pre_order_collection.seller(this.seller_id)

        },
        async init() {
            this.is_loading = true
            await this.load_request()
            await this.load_seller()
            await this.load_order()
            this.update_breadcrumbs()
            this.store = this.stores.find(s => s.id == this.store_id)

            this.is_loading = false
            setTimeout(() => {
                this.load_subrequests()
            }, 1000);

        }
    },
    async created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
    @import "./index.page";
.makeorder-page{
    padding-right: 3.5vw;
    font-size: 1vw;
    padding-bottom: 5vh;
}
.page-route-select{
  display: flex;
  align-items: center;
}
.page-route-select-text{
  font-weight: 400;
  font-size: 1.36em;
  line-height: 17px;
  color: #ABABAB;
  margin-right: 20px;
  cursor: pointer;
}
.page-route-select-icon{
  color: var(--primary-color);
  margin-right: 20px;
}
.mobile{display: none;}
.makeorder-page-title{
    font-weight: 700;
    font-size: 2.72em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #605F5F;
}
.makeorder-page-title-container{
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.makeorder-page-title-icons{
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    padding: 0.5vh 1vw;
    border-radius: 8px;
    cursor: pointer;
    &:hover{
        background-color: var(--primary-color);
        color: white;
    }
}
.makeorder-page-info-container{
    display: flex;
    margin-top: 5vh;
    padding-left: 5vw;
}
.makeorder-page-info-buyer-colum{
    flex: 1;
}
.makeorder-page-info-suplier-colum{
    flex: 1;
}
.makeorder-page-info-details-colum{
    flex: 1;
}
.makeorder-page-info-obs-colum{
    width: 25%;
}
.makeorder-page-info-buyer-title-text{
    font-weight: 700;
    font-size: 1.4em;
    letter-spacing: 0.15px;
    color: #606060;
}
.makeorder-page-info-first-line{ margin-top: 2vh; }
.makeorder-page-info-subtitle{
    font-weight: 500;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #707070;
    margin-right: 0.5vw;
}
.makeorder-page-info-text{
    font-weight: 300;
    font-size: 1.15em;
    letter-spacing: 0.15px;
    color: #A1A1A1;
}
.makeorder-page-info-suplier-raiting{
    margin-top: 1.5vh;
    display: flex;
    align-items: center;
    margin-bottom: 1vh;
}
.makeorder-page-info-suplier-raiting-text{
    background: rgba(42, 205, 114, 0.1);
    border-radius: 8px;
    padding: 0.5vh 0.5vw;
    font-weight: 300;
    font-size: 1.1em;
    color: #30AA4C;
    margin-right: 2vw;
}
.makeorder-page-info-suplier-raiting-icon{
    color: #FFCA2B;
    font-size: 2em;
}
.makeorder-page-info-whats-icon{
    filter: brightness(0) saturate(100%) invert(53%) sepia(11%) saturate(2678%) hue-rotate(81deg) brightness(100%) contrast(89%);
    height: 3.5vh;
    margin-right: 1.5vw;
}
.makeorder-page-info-status-created{
    font-weight: 500;
    font-size: 1.1em;
    color: #2ACD72;
}
.makeorder-page-info-status-pending{
    font-weight: 500;
    font-size: 1.1em;
    color: #f39c12;
}
.makeorder-page-info-obs-textarea{
    resize: none;
    padding: 1vh 1vw;
    border-radius: 8px;
    border: 1px solid #F2F2F2;
    margin-top: 0.5vh;
}
.makeorder-page-info-obs-textarea:focus{outline: none;}
.makeorder-page-info-obs-title-container{
    display: flex;
}
.makeorder-page-info-obs-title-icon{
    font-size: 1.4em;
    margin-left: 1vw;
    color: lightgreen;
    cursor: pointer;
}
.makeorder-page-buttons-row{
    display: flex;
    margin-top: 3.5vh;
    justify-content: flex-end;
}
.makeorder-page-buttons-total{
    display: flex;
    padding: 1vh 1vw;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    align-items: center;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: #202020;
    margin-right: 2vw;
}
.makeorder-page-buttons-total-icon{
    font-size: 1.75em;
    color: var(--primary-color);
}
.makeorder-page-buttons-balance{
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: var(--primary-color);
    padding: 1vh 1vw;
    display: flex;
    align-items: center;
}
.makeorder-page-buttons-blank{flex: 1;}
.makeorder-page-button-right{margin-left: 1.5vw;}
.makeorder-page-buttons-section{
    font-weight: 600;
    font-size: 1.1em;
    border-radius: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 7.5vh;
    min-width: 12vw;
    padding: 0 1vw;
    display: flex;
    align-items: center;
    background-color: #fdf0e9;
    color: var(--primary-color);
}
.makeorder-page-button-order{
    background-color: #30AA4C;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 1.5vw;
    padding: 0 1vw;
    cursor: pointer;
}
.makeorder-page-button-order:hover{
    background-color: white;
    color: #30AA4C;
    border: 0.5px solid #30AA4C;
}
.makeorder-page-button-order-icon{
    margin-right: 0.5vw;
    font-size: 1.5em;
}
.makeorder-page-button-cancelorder{
    background-color: #E5E5E5;
    border: 0.5px solid #E5E5E5;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1.1em;
    letter-spacing: 0.05em;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 1.5vw;
    padding: 0 1vw;
    cursor: pointer;
}
.makeorder-page-button-cancelorder-icon{
    margin-right: 0.5vw;
    font-size: 1.5em;
}
.makeorder-page-table-container{margin-top: 3vh;}
.makeorder-page-info-texts{
    display: flex;
    flex: 1;
}
.makeorder-page-subtitle{
    font-weight: 500;
    font-size: 0.6em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 1vh;
}
@media only screen and (max-width: 1000px) {
    .makeorder-page{font-size: 1.25vw;}
    .makeorder-page-info-container{flex-direction: column;}
}
@media only screen and (max-width: 750px) {
    .makeorder-page{font-size: 1.5vw;}
}
@media only screen and (max-width: 550px) {
    .mobile{display: block;}
    .desktop{display: none;}
    .makeorder-page{font-size: 2vw;}
    .makeorder-page-buttons-row{flex-direction: column;}
    .makeorder-page-button-right{margin-left: 0; margin-top: 2vh}
    .makeorder-page-buttons-total{
        margin-right: 0;
        margin-bottom: 2vh;
        justify-content: center;
        height: 7.5vh
    }
    .makeorder-page-buttons-balance{margin-bottom: 2vh; justify-content: center; height: 7.5vh}
    .makeorder-page-button-order{
        margin-left: 0;
        margin-top: 2vh;
        justify-content: center;
        height: 7.5vh
    }
    .makeorder-page-buttons-section{margin: 2vh 0 !important; justify-content: center;}
}
@media only screen and (max-width: 450px) {
    .makeorder-page{font-size: 3vw;}
    .makeorder-page-info-texts{flex-direction: column;}
    .page-route-select{flex-wrap: wrap;}
    .makeorder-page-info-container{
        font-size: 3.5vw;
    }
}
</style>
