<template>
	<!-- <div class="tab-pane fade show active in" id="orders" role="tabpanel" aria-labelledby="order-tab">
        <cc-mobile-note class="mt-3 mb-3" />
        <cc-request-orders-subtab :buscaCotacao="request_id" />
  	</div> -->
    <div class="order-page-container">
        <cc-loader-full v-if="loading"/>
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Pedidos
            </div>
        </div>
        <div class="order-page-title">
            Pedidos
        </div>
        <div class="order-page-info-containear">
            <MobileNote/>

            <!-- mobile -->
            <div class="mobile mobile-buttons-row">
                <Button style="width: 40%;" class=" mobile mobile-filter-button" :text="!showMobileFilter ?'Mostrar filtros':'Esconder filtros'" :iconName="'settings'" :action="() => showMobileFilter = !showMobileFilter"/>
                <Button style="width: 40%;" :class="{'hide-mobile-filters': !showMobileFilter}"  :text="'Imprimir'" :iconName="'print'" :action="printClick"/>
            </div>


            <!-- desktop -->
            <Button :class="{'hide-mobile-filters': !showMobileFilter}" class="order-page-print-button desktop" :text="'Imprimir'" :iconName="'print'" :action="printClick"/>
            <OrderPartial v-if="order_data" :order_data="order_data" :printColapsed="printColapsed" />
        </div>
        <!-- desktop -->
        <div class="desktop order-page-filter-section2">
            <StandardInputListFiltered  :action="setCompany" :title="'Empresa'" :width="'25vw'" :selectWidth="'36vw'" :placeholder="'Selecione a empresa'" :list="arraySelectLoja"/>
            <StandardInputListFiltered  :action="setSuplier" :title="'Fornecedor'" :width="'25vw'" :selectWidth="'36vw'" :placeholder="'Selecione o fornecedor'" :list="arraySelectFornecedor"/>
            <StandardInputListFiltered  :action="setStatus" :title="'Status'" :width="'25vw'" :selectWidth="'36vw'" :placeholder="'Selecione o status'" :list="statuses"/>
            <StandardInput :placeholder="'Selecione'" :action="v=>setIdentifier(v)" :width="'25vw'"
                :type="'text'" :title="$store.state.identifierText"/>
        </div>
        <!-- mobile -->
        <div class="mobile order-page-filter-section2" >
            <StandardInputListFiltered  :action="setCompany" :title="'Empresa'" :width="'49%'" :selectWidth="'45%'" :placeholder="'Selecione a empresa'" :list="arraySelectLoja"/>
            <StandardInputListFiltered  :action="setSuplier" :title="'Fornecedor'" :width="'49%'" :selectWidth="'45%'" :placeholder="'Selecione o fornecedor'" :list="arraySelectFornecedor"/>

        </div>
        <StandardInputListFiltered class="mobile" :class="{'hide-mobile-filters': !showMobileFilter}"  :action="setStatus" :title="'Status'" :width="'100%'" :selectWidth="'100%'" :placeholder="'Selecione o status'" :list="statuses"/>
        <div class="order-page-filter-section1" style="margin-top: 2vh;" :class="{'hide-mobile-filters': !showMobileFilter}">
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                <div class="page-filters__right-title">Data Inicial</div>
                </div>
                <div class="page-filters__right-select-wrapper end-date">
                <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="dateInit" auto type="date"></datetime>
                <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                    today
                </span>
                </div>
            </div>

            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                <div class="page-filters__right-title">Data Final</div>
                </div>
                <div class="page-filters__right-select-wrapper end-date">
                <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="dateEnd" auto type="date"></datetime>
                <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                    today
                </span>
                </div>
            </div>


            <StandardInputAuto class="desktop" :action="setOrderSearch" :title="'Nº do Pedido'" :width="'25vw'" :type="'text'"/>
            <StandardInputAuto class="desktop" :action="setCotacao" :title="'Nº da Cotação'" :width="'25vw'" :type="'text'"/>


            <StandardInputAuto class="mobile" style="margin-right: 1.5vw;" :action="setOrderSearch" :title="'Nº do Pedido'" :width="'56vw'" :type="'text'"/>
            <StandardInputAuto class="mobile" style="margin-right: 1.5vw;" :action="setCotacao" :title="'Nº da Cotação'" :width="'56vw'" :type="'text'"/>


        </div>
        <div class="selected-options-containear">
            <div class="selected-option" v-if="buscaPedido">
                <div>{{buscaPedido}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => buscaPedido = undefined">close</span>
            </div>
            <div class="selected-option" v-if="identifier">
                <div>{{identifier}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => setIdentifier('')">close</span>
            </div>
            <div class="selected-option" v-if="buscaCotacao">
                <div>{{buscaCotacao}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => setCotacao(undefined) ">close</span>
            </div>
            <div class="selected-option" v-if="selectedLoja">
                <div>{{selectedLoja.loj_nomeFantasia}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => {if (!lockStore) selectedLoja = ''}">close</span>
            </div>
            <div class="selected-option" v-if="selectedFornecedor">
                <div>{{selectedFornecedor.for_nome}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => setSuplier(undefined)">close</span>
            </div>
            <div class="selected-option" v-if="filters.status">
                <div>{{filters.status}}</div>
                <span class="material-icons" style="cursor: pointer" v-on:click="() => filters = {}">close</span>
            </div>
        </div>
        <div class="order-page-table-container">
            <div class="order-page-table-size">
                <div v-if="!loading">
                    <div class="order-page-table-header">
                        <span v-if="isAllCheck" v-on:click="checkAll(false)" class="material-icons-outlined checkbox-width">check_box</span>
                        <span v-else v-on:click="checkAll(true)" class="material-icons-outlined checkbox-width">check_box_outline_blank</span>
                        <div class="id-width">ID / Cotação</div>
                        <div class="store-width">Loja</div>
                        <div class="date-width">Data</div>
                        <div class="seller-width">Fornecedor / Vendedor</div>
                        <div class="value-width">Valor(R$)</div>
                        <div class="status-width">Status</div>
                        <div class="confirmationdate-width confirmationdate-header-text">Data de Confirmação</div>
                        <div class="review-width">Avaliação</div>
                        <div class="actions-width">Ações</div>
                    </div>
                    <div class="order-page-table" v-for="(data,index) in arrayPedido" :key="index" :class="getSpecialBackground(index)">
                        <div class="checkbox-width order-page-table-separator">
                            <!-- mobile -->
                            <span  v-on:click="() => {data.isExpand = !data.isExpand; $forceUpdate()}" v-if="!data.isExpand" class="mobile material-icons-outlined mobile-expand-icon">expand_more</span>
                            <span  v-on:click="() => {data.isExpand = !data.isExpand; $forceUpdate()}" v-else class="mobile material-icons-outlined mobile-expand-icon">expand_less</span>

                            <span v-if="data.isCheck" v-on:click="togleCheck(data)" class="material-icons-outlined  ">check_box</span>
                            <span v-else v-on:click="togleCheck(data)" class="material-icons-outlined">check_box_outline_blank</span>

                            <!-- mobile -->
                            <div class="mobile" style="font-weight: bold;">
                                {{ data.fornecedor ? data.fornecedor.for_nome : '--' }}
                            </div>
                        </div>

                        <!-- mobile -->
                        <div class="mobile" style=" width: 100%; gap: 2vw; flex-direction: row; ">
                            {{ data.loj_descricao }}
                        </div>

                        <!-- desktop -->
                        <div class="desktop id-width order-page-table-separator">
                            <div class="order-table-mobile-header">ID <br> Cotação</div>
                            <div >
                               {{ data.id_pedido }} <br> {{ data.id_cotacao }}
                            </div>
                        </div>
                        <!-- desktop -->
                        <div class="desktop store-width order-page-table-separator order-table-store-content">
                            <div class="order-table-mobile-header">Loja</div>
                            {{ data.loj_descricao }}
                        </div>
                        <!-- desktop -->
                        <div class="desktop date-width order-page-table-separator">
                            <div class="order-table-mobile-header">Data</div>
                            {{ getDate(data.data_geracao) }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row">
                            <div style="width: 30%;" >
                                <div class="order-table-mobile-header">ID</div>
                                {{ data.id_pedido }}
                            </div>
                            <div >
                                <div class="order-table-mobile-header">Cotação</div>
                                {{ data.id_cotacao }}
                            </div>

                            <div style="width: 30%; display: flex; flex-direction: column; align-items: flex-end;" >
                                <div class="order-table-mobile-header">Data</div>
                                {{ getDate(data.data_geracao) }}
                            </div>
                        </div>

                        <!-- desktop -->
                        <div class="desktop seller-width order-page-table-separator">
                            <div class="order-table-mobile-header">Fornecedor <br> Vendedor</div>
                            <div class="order-table-seller-content">
                                <div style="font-weight: bold;">{{ data.fornecedor ? data.fornecedor.for_nome : '--' }}</div>
                                <div>{{ data.vendedor }}</div>
                            </div>
                        </div>
                        <!-- desktop -->
                        <div class="desktop value-width order-page-table-separator">
                            <div class="order-table-mobile-header">Valor(R$)</div>
                            <div style="color: white; background-color: green; opacity: 0.5; margin: 0 0.5vw; padding: 0 1vw; border-radius: 10px;">
                                {{ data.valor_pedido | currency }}
                            </div>

                        </div>
                        <!-- desktop -->
                        <div class="desktop status-width order-page-table-separator">
                            <div class="order-table-mobile-header">Status</div>
                            <span v-if="data.status === 'C'" title="Confirmado pelo fornecedor" style="color: #30AA4C; cursor: default" class="material-icons-round">check_circle</span>
                            <span v-else title="Aguardando confirmação do fornecedor" class="material-icons" style="color: #CD2A2A; cursor: default">cancel</span>
                            <span v-if="data.status === 'C'">
                                <span v-if="data.status_recebido && (data.divergencia.length>0 || data.divergencia_produto.length>0)" v-on:click="sendToDivergency(data)" title="Pedido recebido com divergência!" style="color: red; cursor: pointer" class="material-icons">local_shipping</span>
                                <span v-else-if="data.status_recebido" title="Pedido recebido!" v-on:click="sendToDivergency(data)" style="color: #30AA4C; cursor: pointer" class="material-icons">local_shipping</span>
                                <span v-else title="Pedido ainda não foi recebido!" style="color: gray; cursor: default" class="material-icons">local_shipping</span>
                            </span>
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row">
                            <div style="width: 30%;">
                                <div  class="order-table-mobile-header"> Vendedor</div>
                                {{ data.vendedor }}
                            </div>

                            <div style="width: 30%; text-align: center;">
                                <div style="justify-content: center; width: 100%; " class="order-table-mobile-header">Status</div>
                                <span v-if="data.status === 'C'" title="Confirmado pelo fornecedor" style="color: #30AA4C; cursor: default" class="material-icons-round">check_circle</span>
                                <span v-else title="Aguardando confirmação do fornecedor" class="material-icons" style="color: #CD2A2A; cursor: default">cancel</span>
                                <span v-if="data.status === 'C'">
                                    <span v-if="data.status_recebido" title="Pedido recebido!" style="color: #30AA4C; cursor: default" class="material-icons">local_shipping</span>
                                    <span v-else title="Pedido ainda não foi recebido!" style="color: gray; cursor: default" class="material-icons">local_shipping</span>
                                </span>
                            </div>

                            <div style="width: 30%; display: flex; flex-direction: column; align-items: flex-end;">
                                <div class="order-table-mobile-header">Valor(R$)</div>
                                <div style=" color: white; background-color: green; opacity: 0.5; margin: 0 0.5vw; padding: 0 1vw; border-radius: 10px;">
                                    {{ data.valor_pedido | currency }}
                                </div>

                            </div>

                        </div>

                        <!-- desktop -->
                        <div class="desktop confirmationdate-width order-page-table-separator">
                            <div class="order-table-mobile-header">Data de Confirmação</div>
                            {{ getDate(data.data_confirmacao) }}
                        </div>
                        <!-- desktop -->
                        <div class="desktop review-width order-page-table-separator">
                            <div class="order-table-mobile-header">Avaliação</div>
                            {{ data.nota ? data.nota : '--' }}
                        </div>

                        <!-- mobile -->
                        <div class="mobile mobile-table-row" v-if="data.isExpand">
                            <div >
                                <div class="order-table-mobile-header">Data de Confirmação</div>
                                {{ getDate(data.data_confirmacao) }}
                            </div>
                            <div style="text-align: center;">
                                <div class="order-table-mobile-header">Avaliação</div>
                                {{ data.nota ? data.nota : '--' }}
                            </div>

                        </div>

                        <div class="actions-width">
                                <span class="material-icons" @click="print(data)" style="font-size: 1.5em;
                                color: #CD2A2A; cursor: pointer">picture_as_pdf</span>
                                <span v-on:click="show(index)" class="material-icons" style="font-size: 1.5em; color: #FFCA2B; cursor: pointer">star</span>
                                <router-link v-if="data.order_id"
                                    :to="{ name: 'subrequest',
                                        params: {
                                            return_to: 'monitors-page',
                                            id: data.id_cotacao,
                                            seller_id: data.usu_id,
                                            store_id: data.id_loja,
                                            tab: 'pedidos',
                                            provider_id: data.for_id },
                                        }" tag="span">
                                    <span class="material-icons" style="font-size: 1.5em; color: #17A2B8; cursor: pointer">error_outline</span>
                                </router-link>
                                <router-link v-else
                                    :to="{
                                        name: 'order-info',
                                        params: {
                                            backRouteName: 'monitors-page',
                                            id: data.id_cotacao,
                                            sellerid: data.usu_id,
                                            sid: data.id_loja,
                                            providerid: data.for_id },
                                        query: { backRoute: 2 } }" tag="span">
                                    <span class="material-icons" style="font-size: 1.5em; color: #17A2B8; cursor: pointer">error_outline</span>
                                </router-link>
                                <!-- <router-link :to="{
                                        name: 'cliente-monitores-divergent',
                                        params: {
                                            id: data.id_cotacao,
                                            sellerid: data.usu_id,
                                            sid: data.id_loja,
                                            providerid: data.for_id } }">
                                    <span style="color: rgb(153, 153, 3);" class="material-icons-outlined">report_problem</span>
                                </router-link> -->
                                <span v-if="data.status === 'C' && !data.status_recebido" @click="confirm_delivery(data, index)" class="material-icons" style="font-size: 1.5em; color: #30AA4C; cursor: pointer">done_all</span>
                                <span v-if="data.status === 'P' && !data.status_recebido" @click="confirm_delivery_status_p(data)" class="material-icons" style="font-size: 1.5em; color: #30AA4C; cursor: pointer">done_all</span>

                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-bottom: 5vh">
                <cc-pagination
                    classes="orange justify-end"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="paginaAtual"
                    v-on:change="updatePage($event)"
                ></cc-pagination>
            </div>
        </div>

        <modal name="hello-world" classes="v--modal modal-avaliar " styles="margin: 30px auto;left: 0px !important;position: inherit !important;"  height="600px" :clickToClose="false">
            <div class="container modalBorder">
                <div class="d-flex justify-content-end">
                    <h1 class="closeModal p-1" @click="hide">X</h1>
                </div>
                <h4 class="modalTitlulo pb-3 pt-2 text-center">
                    Como foi sua experiência com
                    <span class="modalTituloNome">{{indexArray}}</span> ?
                </h4>
                <div class="d-flex justify-content-center">
                <star-rating
                    @rating-selected="modalClick"
                    v-model="rating"
                    :star-size="50"
                    :show-rating="false"
                ></star-rating>
                </div>
                <div>
                <div class="d-flex justify-content-center py-3">
                    <h1 class="notaVendedor">{{notaVendedor}}</h1>
                </div>
                <div class="d-flex justify-content-center py-2">
                    <h5 class="subTitulo">Escolha um ou mais problemas que você encontrou:</h5>
                </div>
                <div class="d-flex justify-content-center py-2">
                    <form class="formModal">
                    <div class="form-group" v-for="(data,key) in arrayCheckbox" :key="data.id">
                        <div class="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            :name="data.descricao"
                            :id="data.id"
                            :checked="checkbox[key] == data.id"
                            v-model="checkbox[key]"
                        />
                        <label class="custom-control-label" :for="data.id">{{data.descricao}}</label>
                        </div>
                    </div>
                    </form>
                </div>

                <div class="d-flex justify-content-center pb-2">
                    <h5 class="subTitulo">Sua avaliação é feita de forma anônima.</h5>
                </div>
                <div class="d-flex justify-content-center pt-2">
                    <button class="btnFeedback" @click="postAvaliacaoPedido">Enviar Feedback</button>
                </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Button from "@/components/ui/buttons/Standard-Button.vue"
import OrderService from "@/services/OrderService";
import * as CONSTANTS from "@/constants/constants";
import DirectOrdersSubTab from './_direct_orders.subtab'
import MobileNote from '@/components/notes/mobile-promotion.noteV2';
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import StandardInputListFiltered from '@/components/ui/inputs/StandardInputListFiltered.vue'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import QuotationService from "@/services/OrderService";
import RequestOrdersSubTab from './_request_orders.subtab'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import OrderPartial from "@/components/partials/_print_order.partial";
import SellersService from "@/services/v2/sellers.service";
import Paginacao from "@/components/cliente/base-components/Pagination";
import { perm_mixin } from '@/mixins/permission.mixin';
import StandardInput from "@/components/ui/inputs/StandardInput.vue";

export default {
    mixins: [ loaderMixin, perm_mixin ],
    props: {
        request_id: {
            type: Number
        }
    },
    components: {
        'cc-direct-orders-subtab' : DirectOrdersSubTab,
        'cc-request-orders-subtab' : RequestOrdersSubTab,
        Button,
        MobileNote,
        StandardInputAuto,
        StandardInput,
        OrderPartial,
        'cc-pagination': Paginacao,
        StandardInputListFiltered
    },
    data() {
        return {
            page: 1,
            showPagination: false,
            orders: [],
            CONSTANTS: CONSTANTS,
            items_by_page_value: 50,
            service: new OrderService(),
            orderService: new QuotationService(),
            svc: new SellersService(),
            dateInit: moment().subtract(30, 'days').format("YYYY-MM-DD"),
            dateEnd: moment().add(1, 'days').format("YYYY-MM-DD"),
            printColapsed: undefined,
            is_loading_pdf: false,
            showMobileFilter: false,
            selectPedidos: [],
            arrayPedido: [],
            order_data: null,
            current_order: null,
            lockStore: false,
            buscaPedido: null,
            arraySelectLoja: [],
            filters: {},
            arraySelectFornecedor: [],
            selectedLoja: "",
            statuses: [
                { value: 'P', text: 'Pendente' },
                { value: 'C', text: 'Confirmado' },
                { value: 'D', text: 'Entregue' },
                { value: 'ND', text: 'Não Entregue' }
            ],
            identifier: '',
            isAllCheck: false,
            indexArray: "",
            indexIdPedido: null,
            rating: 0,
            checkbox: [],
            arrayCheckbox: null,
            notaVendedor: null,
            total_items: 1000,
            paginaAtual: 1,
            loading: true
        };
    },
    computed:{
        selectedFornecedor(){
            return this.$store.state.selectedFornecedor
        },
        buscaCotacao(){
            return this.$store.state.buscaCotacao
        }
    },
    methods: {
        open_datepicker(idx) {
            document.getElementsByClassName('vdatetime-input')[idx].click()
        },
        checkAll (value) {
            this.arrayPedido.forEach(element => element.isCheck = value )
            this.isAllCheck = value
        },
        setIdentifier(value){
            this.identifier = value
            this.reloadOrders()
        },
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        togleCheck (data) {
            data.isCheck = !data.isCheck
            if (!data.isCheck) this.isAllCheck = false
            this.$forceUpdate()
        },
        getDate (date) {
            if (date) { return moment(String(date)).format("DD/MM/YYYY")}
            else { return '--'}
        },
        reloadOrders () {
            if (!this.loading) {
                this.loading = true
                setTimeout(() => {
                    this.getListaPedidos(this.page)
                }, 2000)
            }
        },
        setCotacao (text) {
            this.$store.commit('setBuscaCotacao', text)
            this.reloadOrders()
        },
        setOrderSearch (text) {
            this.buscaPedido = text
            this.reloadOrders()
        },
        setCompany (value) {
            this.selectedLoja = value
            this.getListaPedidos(this.page)
        },
        setSuplier (value) {
            this.$store.commit('setSelectedFornecedor', value)
            this.getListaPedidos(this.page)
        },
        setStatus (value) {
            this.filters.status = value
            this.getListaPedidos(this.page)
        },
        getTotal(order) {
            return order.itens.reduce((x, { preco_embalagem, quantidade }) => x + (preco_embalagem*quantidade), 0);
        },
        translateStatus(status) {
            switch(status) {
                case "AGUARDANDO_CONFIRMACAO":
                    return "AGUARDANDO CONFIRMACAO";
                case "CONFIRMADO":
                    return "CONFIRMADO";
                default:
                    return "Não identificado!"
            }
        },
        paginate() {
            this.page = this.page + 1;
            this.getDirectSaleOrders();
        },
        confirm_delivery(order, index) {
            this.confirm_action({
                message: "Confirma recebimento do pedido?",
                deny_text: "Com divergência!",
                cancel_buttom_color: '#4D4F5C',
				callback: (result) => {
                    this.svc.confirm_delivery(order.id_pedido).then(res => {
                        order.status_recebido = true
                    })
                },
                deny_callback: () => {
                    this.svc.confirm_delivery(order.id_pedido)
                    this.$router.push({
                            name: 'cliente-monitores-divergent',
                            params: {
                                id: order.id_cotacao,
                                sellerid: order.usu_id,
                                sid: order.id_loja,
                                providerid: order.for_id }
                        })
                }
			})
        },
        sendToDivergency(order){
            this.$router.push({
                name: 'cliente-monitores-divergent',
                params: {
                    id: order.id_cotacao,
                    sellerid: order.usu_id,
                    sid: order.id_loja,
                    providerid: order.for_id }
            })
        },
        confirm_delivery_status_p(order) {
            this.confirm_action({
                message: "Confirma recebimento do pedido?",
                cancel_buttom_color: '#4D4F5C',
				callback: (result) => {
                    this.svc.confirm_delivery_status_p(order.id_pedido).then(res => {
                        order.status_recebido = true
                        this.reloadOrders()
                    })
                }
			})
        },
        updatePage(new_page) { this.getListaPedidos(new_page); },
        postAvaliacaoPedido() {
            let arrayCheck = [];
            this.checkbox.forEach((data, index) => {
                if (data) {
                arrayCheck.push(this.arrayCheckbox[index].id);
                }
            });
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            axios.post(`${CONSTANTS.API_URL}/atualizaravaliacaopedido`, {
                id_pedido: this.indexIdPedido,
                nota: this.rating,
                comentarios: arrayCheck,
                observacao: " "
            }, config).then(() => {
            this.hide();
            this.getListaPedidos(this.paginaAtual);
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        hide() {
            this.$modal.hide("hello-world");
            this.rating = 0;
            this.select = "";
            this.checkbox = [];
        },
        modalClick() {
            if (this.rating === 1) {
                this.notaVendedor = "Vendedor péssimo, poderia melhorar.";
            }
            if (this.rating === 2) {
                this.notaVendedor = "Vendedor ruim, poderia melhorar.";
            }
            if (this.rating === 3) {
                this.notaVendedor = "Vendendor regular, poderia melhorar.";
            }
            if (this.rating === 4) {
                this.notaVendedor = "Vendedor bom, mas poderia melhorar.";
            }
            if (this.rating === 5) {
                this.notaVendedor = "Vendedor Excelente!";
            }
        },
        show(index) {
            this.$modal.show("hello-world");
            this.indexArray = this.arrayPedido[index].vendedor;
            this.indexIdPedido = this.arrayPedido[index].id_pedido;
            this.rating = this.arrayPedido[index].nota;
            this.checkbox = this.arrayCheckbox.map(opts => {
                let opt = this.arrayPedido[index].avaliacao.find(item => item.id == opts.id)
                return opt ? true : false
            })
        },
        getArrayPedido() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            }
            axios.get(`${CONSTANTS.API_URL}/listarcomentariospedido`, config)
            .then(data => {
                this.arrayCheckbox = data.data.comentarios;
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            })
        },
        getDirectSaleOrders() {
            // this.is_loading = true;
            // this.service.getDirectSaleOrders({ page: this.page, page_size: this.items_by_page_value })
            // .then(response => response.data)
            // .then(data => {
            //     this.is_loading = false;
            //     this.orders = this.orders.concat(data.data);
            //     this.showPagination = data.next_page_url != null;
            // }).catch(error => {
            //     this.is_loading = false;
            //     ErrorHandlerService.handle(error, this.$store);
            // });;
        },
        print(order) {

            this.current_order = order

            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                [ order.id_pedido ], [ order.id_cotacao ], [ order.id_loja ], [ order.usu_id ]
            ]

            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };

            this.orderService.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                console.log('print order', order)
                Vue.nextTick(() => {
                    window.document.title = `pedido-${order.id_pedido}.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        printClick(){
            let check = false
            this.arrayPedido.forEach(element => {
                if (element.isCheck) check = true
            })
            if (!check) {
                this.$swal.fire('Por Favor,', 'Selecione ao menos uma cotação.')
                return
            }
            this.$swal.fire({
                icon: 'info',
                title: 'Deseja juntar as paginas?',
                text: 'Deseja que as informações sejam agrupados no menor numero de paginas posiveis?',
                iconHtml: '<span class="material-icons-outlined print-icon" style="font-size: 1em">print</span>',
                showDenyButton: true,
                denyButtonText: 'Não',
                denyButtonColor: 'red',
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Sim"
            }).then(resp =>{
                if ( resp.isConfirmed) this.print_many(true)
                else if (resp.isDenied) this.print_many(false)
            })
            // this.$swal
            //     .fire({
            //         title: "",
            //         type: "warning",
            //         showCancelButton: true,
            //         confirmButtonColor: "#3085d6",
            //         cancelButtonColor: "#d33",
            //         confirmButtonText: "Sim",
            //         cancelButtonText: "Não",
            //     }).then(resp => {
            //         if (resp.value) this.print_many(true)
            //         else this.print_many(false)
            //     })

        },
        print_many(value) {
            this.is_loading_pdf = true;

            this.selectPedidos = this.arrayPedido.filter(order => order.isCheck)
            const [id_pedido, id_cotacao, id_loja, usu_id] = [
                this.selectPedidos.map(item => item.id_pedido),
                this.selectPedidos.map(item => item.id_cotacao),
                this.selectPedidos.map(item => item.id_loja),
                this.selectPedidos.map(item => item.usu_id)
            ]
            const dados = {
                id_pedido: id_pedido,
                id_cotacao: id_cotacao,
                id_loja: id_loja,
                usu_id: usu_id
            };
            this.orderService.get_order_data(dados).then(response => response.data).then(data => {
                this.order_data = data.data
                this.printColapsed = value
                Vue.nextTick(() => {
                    // window.document.title = `pedidos.pdf`
                    this.$htmlToPaper('print-order', { name: 'pedido.pdf' }, () => {
                        this.current_order = null
                        this.order_data = null
                        // window.document.title = 'Club da Cotação'
                    });
                })
            })
        },
        getLojas() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            return axios.get(`${CONSTANTS.API_URL}/getrequisitantescliente`, config)
            .then(data => {
                data.data.requisitantes.forEach(element => {
                    this.arraySelectLoja.push({
                        text: element.loj_nomeFantasia,
                        value: element
                    })
                })
                if(!this.can('REQUEST_ORDER', 'READ_ALL') || JSON.parse(localStorage.getItem('store_id'))) {
                    this.selectedLoja = this.arraySelectLoja[0].value
                    this.lockStore = true
                }
                this.getListaPedidos(1)
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getListaPedidos(page) {
            this.loading = true;
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
                params: {
                    idPedido: this.buscaPedido,
                    numerocotacao: this.buscaCotacao,
                    fornecedor: this.selectedFornecedor? this.selectedFornecedor.for_id : null,
                    loja: this.selectedLoja ? this.selectedLoja.loj_id : null,
                    datainicial: !this.buscaCotacao ? moment(this.dateInit).format().split('T')[0] : null,
                    datafinal: !this.buscaCotacao ? moment(this.dateEnd).format().split('T')[0] : null,
                    singleorder: false,
                    status: this.filters.status ? this.filters.status : null,
                    identifier: this.identifier
                }
            };
            let url = `${CONSTANTS.API_URL}/listarpedidos?page=${page}`;
            axios.get(url, config).then(data => {
                this.arrayPedido = data.data.pedidos.data;
                this.loading = false;
                this.paginaAtual = data.data.pedidos.current_page;
                this.totalPaginas = data.data.pedidos.last_page;
                this.total_items = data.data.pedidos.total;
            }).catch(error => {
                this.loading = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        getFornecedor() {
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        };
        axios
            .get(`${CONSTANTS.API_URL}/getfornecedorescliente`, config)
            .then(data => {
            data.data.fornecedores.forEach(element => {
                this.arraySelectFornecedor.push({
                    text: element.for_nome,
                    value: element
                })
            });
            this.getLojas();
            }).catch(error => {
                ErrorHandlerService.handle(error, this.$store);
            });
        },
    },
    watch: {
        items_by_page_value: function() {
            this.orders = [];
            this.getDirectSaleOrders();
        },
        dateEnd (){
            if (this.loading) return
            if(this.dateInit) this.getListaPedidos(1)
        },
        dateInit (){
            if (this.loading) return
            if(this.dateEnd) this.getListaPedidos(1)
        }

    },
    mounted() {
        this.getFornecedor();
        window.scrollTo(0,0)
        this.getArrayPedido();
        // this.getDirectSaleOrders();
        if (this.request_id) this.$store.commit('setBuscaCotacao', this.request_id)

    },
    // beforeDestroy(){
    //     console.log(1231231231);
    //     this.$store.commit('setSelectedFornecedor', undefined)
    //     this.$store.commit('setBuscaCotacao', undefined)
    // }
};
</script>

<style lang="scss" scoped>
@import "@/sass/commons/_variables";
.order-page-container{
    font-size: 1vw;
}
.page-route-select{
    display: flex;
    align-items: center;
}
.page-route-select-text{
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.page-route-select-icon{
    color: var(--primary-color);
    margin-right: 20px;
}
.order-page-print-button{
    width: 12%;
}
.order-page-title{
    font-weight: 600;
    font-size: 2.5em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
}
.page-table-line-special {
  background: #F7F7F7;
}
.order-page-info-containear{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 3vh;
}
.order-page-filter-container{
    display: flex;
    margin-top: 2vh;
    align-items: flex-end;
}
.order-page-table-header{
    margin-top: 4vh;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    padding: 0.7vh 0;
    display: flex;
    align-items: center;
    margin-bottom: 2vh;
}
.checkbox-width{
    width: 3.5%; text-align: center;
    color: var(--primary-color);
    cursor: pointer;
}
.id-width{width: 10%; text-align: center;}
.store-width{width: 14%; text-align: center; padding: 0 0.5%;}
.date-width{width: 9%; text-align: center;}
.price-width{width: 7%; text-align: center;}
.seller-width{flex: 1; text-align: center;}
.value-width{width: 8%; text-align: center;}
.status-width{width: 7%; text-align: center;}
.confirmationdate-width{
    width: 11%;
    text-align: center;
}
.confirmationdate-header-text{ font-size: 0.8em; }
.suplier-width{width: 14%; text-align: center;}
.review-width{width: 7%; text-align: center;}
.actions-width{
    width: 12%;
}
.page-filters__right-wrapper {
  width: 25vw;
}
.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 1.2em;
}
.page-filters__right-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.order-print-check{
    position: absolute;
    background-color: white;
    top: 45%;
    left: 35%;
    width: 35%;
    height: 20%;
    border: 1px solid var(--primary-color);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.order-print-button{
    width: 7vw;
    border-radius: 10px;
    height: 5vh;
}
.order-print-button-yes{
    background-color: lightgreen;
    color: white;
    margin-right: 3vw;
}
.order-print-button-no{
    background-color: lightcoral;
    color: white;
}
.order-table-seller-content{
    text-align: left;
    padding-left: 5%;
}
.order-table-store-content{
    text-align: left;
}
.order-page-table{
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    align-items: center;
    padding: 2vh 0;
}
.order-page-table-separator{
    border-right: 0.5px solid #DDE5E9;
}
.order-page-filter-section1{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.selected-options-containear{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1.5vw;
  min-height: 7vh;
}
.selected-option{
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1vh 5%;
  font-weight: 300;
  font-size: 11px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-top: 1vh;
}
.order-page-filter-section2{
    display: flex;
    flex: 1;
    justify-content: space-between;
}
.tablet{ display: none; }
.mobile{ display: none; }
.order-table-mobile-header{
    display: none;
}
@media only screen and (max-width: 800px) {
    .order-page-filter-container{
        flex-direction: column;
    }
    .desktop{ display: none !important; }
    .tablet{ display: block; }
    .order-page-filter-section1{
        margin-bottom: 2vh;
    }
    .order-page-container{
        font-size: 1.25vw;
    }
    .order-page-filter-section1{
        margin-bottom: 0;
    }

    .tablet{ display: none; }
    .mobile{
        display: block;
    }
    .order-page-filter-section2{
        width: 100%;
        display: flex;
    }
    .page-filters__right-title-wrapper{
        margin-top: 1vh;
    }
    .order-page-filter-section1{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
     }
     .mobile-table-row{
        display: flex;
        width: 100%;
        justify-content: space-between;
     }
     .mobile-expand-icon{
        font-size: 1.5em;
        color: var(--primary-color);
     }
    .order-page-info-containear{
        flex-direction: column;
        align-items: flex-start;
    }
    .order-page-print-button{
        width: 40%;
        margin-top: 2vh;
    }
    .order-page-container{
        font-size: 2vw;
    }
    .order-page-table-header{display: none;}
    .order-page-table{
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 3vh;
        font-size: 1.5em;
    }
    .order-page-table-separator{
        border-right: none;
        width: 100%;
        flex: unset;
        display: flex;
        justify-content: space-between;
        padding-right: 3vw;
    }
    .order-table-mobile-header{
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
        text-align: inherit;
    }
    .hide-mobile-filters{
        display: none;
    }
    .order-table-seller-content{
        text-align: end;
    }
    .actions-width{
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        padding-right: 3vw;
    }
    .checkbox-width{
        color: unset;
        justify-content: flex-start;
        gap: 3vw;
        font-size: 1.1em;
        align-items: center;
    }
    .mobile-id-row{
        display: flex;
        align-items: center;
        gap: 1.5vw;
    }
    .page-filters__right-wrapper {
        width: 100%;
    }
    .mobile-filter-button{
        display:flex;
    }
    .mobile-buttons-row{
        display: flex;
        gap: 10%;
        margin-top: 2vh;
        width: 100%;
        justify-content: space-between;
    }
}
@media only screen and (max-width: 500px) {.order-page-container{ font-size: 3vw; }}
@media only screen and (max-width: 200px) {.order-page-container{ font-size: 3.25vw; }}
</style>
