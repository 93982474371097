<template>
    <div class="page">
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Monitor de Cotação
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Fonecedor
            </div>
        </div>
        <div class="page-title-container">
            <div class="page-title">Fornecedor</div>

            <div style="display: flex; gap: 2%;">
                <StandardButton v-if="!current_seller" :action="linkBack" text='Voltar' iconName="arrow_back" />
                <StandardButton v-if="!current_seller" :action="sendWinners" text='Vencedores' iconName="shopping_cart" />
                <label v-if="!current_seller" :for="'v2-excel'" style="margin-bottom: 0; ">
                    <StandardButton :action="() => {}" :iconName="'cloud_upload'" :text="'Importar Excel'" />
                </label>
                <StandardButton :action="getProducts" :iconName="'upload_file'" :text="'Exportar Excel'" />
                <input :id="'v2-excel'" style="display: none;" type="file"
                    v-on:change="event => loadExcelV2(event)">
            </div>


        </div>

        <div v-if="!current_seller" class="modal-content-body">
            <div class="modal-content-search-title">Fornecedor da Compra/Cotação</div>
            <div class="modal-content-search-inputs">
                <input type="text" v-model="filters.name" class="modal-content-search-input">
                <!-- <StandardButton class="modal-content-table-button" :action="filter_sellers" :iconName="'search'" :text="'Buscar'"/> -->
            </div>
            <cc-loader v-if="loading" style="max-height: fit-content;"></cc-loader>
            <div v-else class="modal-content-table-rows">
                <div class="modal-content-table-header">
                    <div class="modal-content-table-suplier">Fornecedor</div>
                    <div class="modal-content-table-seller">Vendedor</div>
                    <div class="modal-content-table-status">Status</div>
                    <div class="modal-content-table-action">Ações</div>
                </div>
                <div class="modal-content-table-row" v-show="seller.visible" v-for="(seller, idx) in sellers"
                    :class="getSpecialBackground(idx)" :key="seller.id_vendedor">
                    <div class="modal-content-table-suplier table-suplier-text">{{ seller.fornecedor_nome }}</div>
                    <div class="modal-content-table-seller table-seller-text">{{ seller.vendedor_nome }}</div>
                    <div class="modal-content-table-status"><cc-status :status="seller.status"></cc-status></div>
                    <div class="modal-content-table-action" v-on:click="toggle_seller(seller)">
                        <StandardButton class="modal-content-table-button" :action="() => { }" :iconName="'local_shipping'"
                            :text="'Produtos'" />
                    </div>
                    <!-- <div class="modal-content-table-action">
						<span class="material-icons-outlined modal-content-table-icon" v-on:click="toggle_seller(seller)" title="Produtos">local_shipping</span>
					</div> -->
                </div>
            </div>
            <cc-pagination :key="'pag'" classes="orange" :items_by_page_value="50" :total="total_items" :page="page"
                :page_limit="11" v-on:change="page = $event"></cc-pagination>
        </div>
        <div v-else class="modal-content-body">
            <div class="modal-content-products-title">
                <div class="modal-content-products-title-text"># {{ current_seller ? current_seller.fornecedor_nome + ' - ' +
                    current_seller.vendedor_nome : '' }}</div>
                <StandardButton :action="() => { this.current_seller = undefined; this.load_sellers(); this.cleanFilters() }"
                    :iconName="'arrow_back'" :text="'Voltar'" />
            </div>
            <div class="modal-content-products-filter2-container">
                <!-- <div class="modal-content-products-filter2-selectplan">
                    Plano de Pagamento
                    <cc-select-v2 :tracker="'ttp_id'" :text="'ttp_nome'" :options="plans" @select="set_delivery_plan"
                        v-model="current_seller.delivery.payment_plan" style="border-radius: 8px;"></cc-select-v2>
                </div> -->
                <StandardInput class="client-suplier-payment" :placeholder="current_seller.entrega.forma" :action="set_delivery_plan" title="Plano de Pagamento" type="select" :list="plans" />
                <div class="modal-content-products-filter2-selectdate">
                    Data de Entrega
                    <div class="modal-content-products-filter2-selectdate-input">

                        <datetime v-model="current_seller.delivery.date" @input="set_delivery_date" auto :minute-step=10
                            type="date" class=""></datetime>
                        <span class="material-icons-outlined" style="cursor: pointer;"
                            v-on:click="open_datepicker()">event</span>
                    </div>
                </div>
                <div >
                    <div class="modal-content-products-filter-title">Fat. minimo</div>
                    <money v-bind="money" class="modal-content-bilim-filter-input" v-model="current_seller.entrega.faturamento_minimo"/>
                </div>
                <!-- <StandardInput type="select" :list="arrayCategoria" title="asdas"/> -->

                <StandardButton class="modal-content-products-filter2-button" :action="save_delivery"
                    :iconName="current_seller.delivery.saved ? 'thumb_up' : 'save'"
                    :text="current_seller.delivery.saved ? 'Salvo' : 'Salvar'" />
            </div>

            <div v-if="current_seller.delivery.saved">
                <div class="modal-content-products-filter-container">
                    <!-- <input class="modal-content-products-filter-input" type="text" v-model="query_params.produto"> -->
                    <StandardInput class="client-suplier-product-search" title="Nome do produto" type="text" :action="setNameFilter" />

                    <!-- <div class="modal-content-products-filter2-selectplan">
                        Categoria
                        <cc-select-v2 @select="selectCategory()" v-model="categoryFilter" :tracker="'text'" :text="'text'" :options="arrayCategoria" style="border-radius: 8px;"></cc-select-v2>
                    </div> -->
                    <StandardInput class="client-suplier-category" title="Categoria" :action="setCategoryFilter" type="select" :list="arrayCategoria" />

                    <div class="modal-content-products-filter-checkbox">
                        <span v-if="query_params.with_last_offer == 1" v-on:click="query_params.with_last_offer = 0"
                            class="material-icons modal-content-products-filter-checkbox-active">check_box</span>
                        <span v-else v-on:click="query_params.with_last_offer = 1"
                            class="material-icons modal-content-products-filter-checkbox-active">check_box_outline_blank</span>
                        Apenas com última oferta
                    </div>

                    <!-- <StandardButton class="modal-content-products-filter-button" :action="filter_products"  :iconName="'search'" :text="'Buscar'"/> -->
                    <label :for="current_seller.id_vendedor" style="margin-bottom: 0; ">
                        <StandardButton class="modal-content-products-filter-button" :action="() => {}"
                        :iconName="'cloud_upload'" :text="'Excel'" />
                    </label>
                </div>
                <div class="modal-content-products-filter-container">
                    <input :id="current_seller.id_vendedor" style="display: none;" type="file"
                        v-on:change="event => loadExcel(event, current_seller)">
                    <StandardButton class="modal-content-products-filter-button" :action="copy_prices"
                        :iconName="'content_copy'" :text="'Copiar Preços'" />
                </div>
            </div>
            <cc-loader v-if="loading" style="max-height: fit-content;"></cc-loader>
            <div v-else style="overflow-x: auto;">
                <div class="modal-content-products-table">
                    <div class="modal-content-products-table-header">
                        <div class="modal-content-products-table-expand"></div>
                        <div class="modal-content-products-table-cod">Código</div>
                        <div class="modal-content-products-table-name">Descrição do produto</div>
                        <div class="modal-content-products-table-wrap">Embalagem</div>
                    </div>
                    <div v-if="products.length < 1" class="modal-content-products-table-noproducts">Desculpe, não há
                        produtos disponíveis.</div>
                    <div v-else v-for="product in products" :key="'prod-' + product.pro_id" style="margin-bottom: 2vh">

                        <div class="modal-content-products-table-row" v-on:click="() => {product.isExpand = !product.isExpand; $forceUpdate()}">
                            <div class="modal-content-products-table-expand">
                                <span v-if="product.isExpand" class="material-icons-outlined expand-icon">expand_more</span>
                                <span v-else class="material-icons-outlined expand-icon">expand_less</span>
                            </div>
                            <div class="modal-content-products-table-cod">{{ product.ean }}</div>
                            <div class="modal-content-products-table-name">{{ product.pro_descricao }}</div>
                            <div class="modal-content-products-table-wrap">{{ product.flag_generico == 1 ?
                                product.pro_unidade_compra : product.pro_embalagem }}</div>

                        </div>

                        <div v-if="product.isExpand">
                            <div class="modal-content-products-subtable-header">
                                <div class="modal-content-products-subtable-brand">Marca</div>
                                <div class="modal-content-products-subtable-price">Preço unitário</div>
                                <div class="modal-content-products-subtable-last">Última oferta</div>
                                <div class="modal-content-products-subtable-action">Ação</div>
                            </div>

                            <div v-if="!checkDerivatives (product)" class="modal-content-products-table-nobrands">Desculpe,
                                nenhuma marca indisponíveis.</div>
                            <div v-else class="modal-content-products-subtable-row" v-for="(subproduct, idx) in product.derivatives"
                                :key="'prod-' + subproduct.pro_id" v-show="subproduct.visible" :class="{'page-table-line-special': idx % 2 == 0}">
                                <div class="modal-content-products-subtable-brand">{{ subproduct.pro_observacao }}</div>
                                <div class="modal-content-products-subtable-price">
                                    <cc-loader-full v-if="subproduct.loading" />
                                    <money v-model="subproduct.valor" :style="isProductSaved(subproduct)" v-bind="money"
                                        class="modal-content-products-subtable-price-input desktop"

                                        @keydown.native="handle_navigation($event, subproduct)"

                                         ref="price"
                                        :data-visible="subproduct.visible" :disabled="(current_seller.owner_type && current_seller.owner_type !== 'CLIENT')
                                            ||
                                            subproduct.is_discontinued
                                            ||
                                            (request.expired
                                                ||
                                                ((
                                                    (current_seller && current_seller.entrega && !current_seller.entrega.forma)
                                                    ||
                                                    (current_seller.entrega && !current_seller.entrega.data_entrega)
                                                )
                                                    &&
                                                    !current_seller.delivery.saved)
                                            )" />


                                            <money v-model="subproduct.valor" :style="isProductSaved(subproduct)" v-bind="money"
                                        class="modal-content-products-subtable-price-input mobile"
                                        @blur.native="handle_navigation($event, subproduct)"
                                        @keydown.native="handle_navigation($event, subproduct)"

                                         ref="price"
                                        :data-visible="subproduct.visible" :disabled="(current_seller.owner_type && current_seller.owner_type !== 'CLIENT')
                                            ||
                                            subproduct.is_discontinued
                                            ||
                                            (request.expired
                                                ||
                                                ((
                                                    (current_seller && current_seller.entrega && !current_seller.entrega.forma)
                                                    ||
                                                    (current_seller.entrega && !current_seller.entrega.data_entrega)
                                                )
                                                    &&
                                                    !current_seller.delivery.saved)
                                            )" />
                                </div>
                                <div class="modal-content-products-subtable-last">{{ subproduct.last_offer | currency }}</div>
                                <div class="modal-content-products-subtable-action">
                                    <span v-if="!subproduct.is_discontinued" @click="clear_offer(subproduct)"
                                        class="material-icons-outlined modal-content-products-subtable-action-icon">delete</span>
                                    <span v-else @click="continue_product(subproduct)"
                                        class="material-icons-outlined modal-content-products-subtable-action-icon-add">add</span>
                                    <span :style="getObsColor(subproduct.observacao)" v-if="subproduct.valor" @click=" addObsProduct=subproduct" :title="subproduct.observacao? subproduct.observacao : 'Adicionar observação'" class="add-obs-icon material-icons-outlined">info</span>
                                </div>
                            </div>
                            <div class="modal-content-products-table-button" v-on:click.stop v-if="product.derivatives.length > 1">
                                <StandardButton class="modal-content-products-table-button-input" :action="() => toggle_brands(product)"
                                    :text="!product.show_all ? 'Visualizar outras marcas' : 'Apenas com ultima oferta'" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <cc-pagination classes="orange" :items_by_page_value="20" :total="total_items2"
                :page_limit="5" v-on:change="event => updatePage(event)"></cc-pagination>
        </div>

        <PendecieNotification v-if="showPendencieNotification" :close="() => showPendencieNotification = false" :text="pendencieText"/>
        <ChangeQuotationDeadlineModal id="modal" @reload="() => updatePage(1)" :request="request" v-if="show_deadline_modal" @close="show_deadline_modal = false;" />
        <AddObsModal v-if="addObsProduct" :action="update_offer" :product="addObsProduct" :close="()=>addObsProduct=false"/>
    </div>
</template>

<script>
import Pagination from "@/components/cliente/base-components/Pagination";
import StandardButton from '@/components/ui/buttons/Standard-Button.vue';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue';
import * as CONSTANTS from "@/constants/constants";
import { elementNavigationmMixin } from '@/mixins/navigation.mixin';
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { typingRemoteRequestControlMixin } from "@/mixins/typing-remote-request-controls.mixin";
import ChangeQuotationDeadlineModal from "@/modals/requests/deadline/request-deadline.modal";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import QuotationService from "@/services/QuotationService";
import StorageService from '@/services/TokenService';
import ProductService from "@/services/v2/products.service";
import RequestsService from "@/services/v2/requests.service";
import SellersService from "@/services/v2/sellers.service";
import { gsap } from "gsap";
import readXlsxFile from 'read-excel-file';
import AddObsModal from "./AddObsModal.vue";
import { mapState } from "vuex";
import ExcelDownload from '@/services/ExcelDownload.js'

export default {
    name: 'ClientSuplier',
    mixins: [loaderMixin, elementNavigationmMixin, typingRemoteRequestControlMixin],
    components: {
        "cc-pagination": Pagination,
        StandardButton,
        StandardInput,
        ChangeQuotationDeadlineModal,
        AddObsModal
    },
    computed: {
        ...mapState([ "whitelabel"]),
    },
    data() {
        return {

            request: {},
            inputs: 0,
            sellers: [],
            products: [],
            query_params: {
                produto: '',
                page: 1,
                with_last_offer: true
            },
            filters: {
                name: ''
            },
            plans: [

            ],
            current_seller: null,
            loading: true,
            total_items: 0,
            total_items2: undefined,
            productsExcel: [],
            page: 1,
            page2: 0,
            is_loading_available_sellers: false,
            is_loading_request_sellers: false,
            addObsProduct: false,
            service: new SellersService(),
            quotation_svc: new QuotationService(),
            req_svc: new RequestsService(),
            prod_svc: new ProductService(),
            storage_svc: new StorageService(),
            show_deadline_modal: false,
            arrayCategoria: [],
            categoryFilter: undefined,
            pendencieText: '',
            showPendencieNotification: false,
            testLoading: true,
            request_model: null,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                precision: 2,
                masked: false
            }
        };
    },
    methods: {
        selectCategory(){
            setTimeout(() => {
                this.load_products(this.current_seller)
            }, 500);
        },
        cleanFilters(){
            this.query_params.produto = undefined
            this.categoryFilter = undefined
            this.query_params = {
                produto: '',
                page: 1,
                with_last_offer: true
            }
        },
        getListaCategorias() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            let url = `${CONSTANTS.API_URL}/listarcategoriascliente`;
            axios.get(url, config).then(data => {
                data.data.categorias.forEach(element => {
                    this.arrayCategoria.push({
                        value: element,
                        text: element.cat_descricao
                    })
                });
            });
        },
        sendWinners () {
            let cliVindi =  this.storage_svc.get('cli_vindi')
            if (cliVindi == 0) {
                const contracts = JSON.parse(this.storage_svc.get('contracts'))
                for (let i = 0; i < contracts.length; i++){
                    if( contracts[i].cnt_contas_atrasada > 0 && contracts[i].data_diff > 7) {
                        this.pendencieText = "Desculpe, sua conta esta bloqueada."
                       this.showPendencieNotification = true
                       return
                    }
                }
            } else if (cliVindi == 1) {
                const contracts = JSON.parse(this.storage_svc.get('contractsBills'))
                const dateNow = new Date()
                for (let i = 0; i < contracts.length; i++) {
                    const dueDate = new Date(contracts[i].due_at)
                    if (contracts[i].status == "pending" && (dueDate - dateNow) < -1295399970) {
                        this.pendencieText = "Desculpe, sua conta esta bloqueada."
                        this.showPendencieNotification = true
                        return
                    }
                }
            }
            this.$router.push({ name: 'cliente-monitores-vencedores', params: { tab:'', id: this.request.numeroCotacao }});
        },
        checkDerivatives (product) {
            let resp = false
            product.derivatives.forEach(element => {if (element.visible) resp = true});
            return resp
        },
        updatePage (event) {
            this.query_params.page = event
            this.load_products(this.current_seller)
        },
        linkBack () {
            this.$router.go(-1)
        },
        getObsColor(value){
            if (value) return 'color: green'
        },
        isProductSaved(subproduct) {
            if (subproduct.saved) return 'background-color: #9fff9f73;'
        },
        getSpecialBackground(index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        loadExcel(event, seller) {
            const payload = []
            this.loading = true
            readXlsxFile(event.target.files[0]).then(data => {
                for (let index = 1; index < data.length; index++) {
                    const element = data[index];
                    if (element[0] && element[2] != '#ERROR_#N/A') {
                        payload.push({
                            "preco": element[2],
                            "product_ean": element[0],
                            "seller_id": seller.id_vendedor,
                            "cot_id": this.request.numeroCotacao,
                            "for_id": seller.id_fornecedor
                        })
                    }
                }
                this.req_svc.importExcel(payload).then(() => {
                    this.load_products(this.current_seller)
                })
            })

        },
        getProducts() {
            this.loading = true
            this.req_svc
            .request_products(this.request.numeroCotacao, { })
            .then(response => response.data)
            .then(data => {
                this.loading = false
                this.productsExcel = data;
                this.downloadExcel()
            })
            .catch(error => {
                this.loading = false
                ErrorHandlerService.handle(error, this.$store, this.$store);
            });
        },
        downloadExcel(){
          setTimeout(() => {
              const payload = {
                  tableName: 'Produtos',
                  skip: true,
                  userName: localStorage.getItem('user_name'),
                  author: this.whitelabel && this.whitelabel.data.providers.text_window,
                  headerColor: this.whitelabel && this.whitelabel.data.colors.primary,
                  store: localStorage.getItem('grupo'),
                  cot: '#'+this.request.numeroCotacao,
                  header: ['Código','Nome', 'Preço', 'Embalagem', 'Qtd.'],
                  body: this.productsExcel.map(value => {
                      return [
                          value.pro_ean,
                          value.pro_descricao,
                          '',
                          value.pro_generico == 1 ? value.pro_unidade_compra : value.pro_embalagem,
                          value.soma_quantidades || value.quantidade 
                      ]
                  })
              }
              new ExcelDownload(payload)
          }, 100);
        },
        loadExcelV2(event) {
            const payload = []
            this.loading = true
            readXlsxFile(event.target.files[0]).then(data => {
                for (let index = 1; index < data.length; index++) {
					const element = data[index];
					if(element[0] && element[2] != '#ERROR_#N/A') {
						payload.push({
							"product_ean": element[0],
							"product_name": element[1],
							"packing": `${element[2]} ${element[3]}`,
							"obs": element[10],
                            "preco": element[11],
							"seller_id": element[15],
							"cot_id": this.request.numeroCotacao
						})
					}
				}
                this.req_svc.importExcelV2(payload).then(() => {
                    this.load_products(this.current_seller)
                })
            })

        },
        open_datepicker() { document.getElementsByClassName('vdatetime-input')[0].click() },
        toggle_seller(seller) {
            let products_width = 70
            if (this.current_seller && this.current_seller.id_vendedor == seller.id_vendedor) {
                gsap.to('#seller-products', { duration: 1, width: 0 })
                gsap.to('#sellers', { duration: 1, 'max-width': "100%", 'flex': "0 0 100%" })
                this.current_seller = null
            } else {
                this.page = 1
                this.load_products(seller)
                if (!this.current_seller) {
                    gsap.fromTo('#seller-products', { 'width': "0%", 'flex': `0 0 0%` }, { duration: 1, 'flex': `0 0 ${products_width - 6}%`, ease: 'expo.out' })
                    gsap.fromTo('#sellers', { 'max-width': "100%", 'flex': '0 0 100%' }, { duration: 1, 'max-width': `"${100 - products_width}%`, 'flex': `0 0 ${100 - products_width}%`, ease: 'expo.out' })
                }
                this.current_seller = seller;
                if (!this.current_seller.data_entrega) {
                    this.current_seller.delivery.date = this.current_seller.entrega ? this.current_seller.entrega.data_entrega : moment(this.request.dataExpiracao, 'YYYY-MM-DD').add("1", "days").format()
                }
                if (this.current_seller.entrega) this.current_seller.delivery.saved = true
                else this.current_seller.entrega = {}
            }
            this.$forceUpdate()
        },
        toggle_brands(product) {
            product.show_all = !product.show_all
            product.derivatives.filter(p => !p.last_offer).forEach(p => p.visible = !p.visible)
            this.$forceUpdate()
        },
        close() {
            this.$emit("close");
        },
        set_delivery_plan(value) {
            this.$set(this.current_seller.delivery, 'payment_plan', value)
            this.$forceUpdate()
        },
        set_delivery_date() {
            this.$set(this.current_seller.delivery, 'date', moment(this.current_seller.delivery.date, 'YYYY-MM-DD').format())
            this.$forceUpdate()
        },
        save_delivery() {
            let data = {
                data_entrega: moment(this.current_seller.delivery.date).format("YYYY-MM-DD"),
                faturamento_minimo: this.current_seller.entrega ? this.current_seller.entrega.faturamento_minimo : 0,
                forma_pagamento: this.current_seller.delivery.payment_plan.ttp_nome,
                numerocotacao: this.request.numeroCotacao,
                observacao: "",
                old: false,
                seller_id: this.current_seller.id_vendedor
            }
            this.current_seller.delivery.saved = false
            return this.quotation_svc.send_delivery(data).then(() => {
                this.current_seller.delivery.saved = true
                this.present_info("Entrega salva com sucesso")
                this.load_sellers()
            })
        },
        load_sellers() {
            this.loading = true

            return this.quotation_svc.get_request_providers(this.request.numeroCotacao, this.page)

                .then(response => response.data)
                .then(data => {
                    this.sellers = data.concorrentes;
                    this.total_items = data.concorrentes.length
                    setTimeout(() => {
                        this.total_items = data.concorrentes.length
                        this.$forceUpdate()
                    }, 1000)
                    this.sellers.forEach(s => s.visible = true)
                    this.sellers.forEach(s => {
                        let del = this.plans.find(p => p.ttp_nome == s.forma) || this.plans[0]
                        s.delivery = {
                            payment_plan: {
                                ttp_id: del ? del.ttp_id : '',
                                ttp_nome: del ? del.ttp_nome : '',
                            },
                            date: s.entrega ? moment(s.entrega.data_entrega, 'YYYY-MM-DD').format() : null
                        }
                    })
                    this.$forceUpdate();
                    this.loading = false
                })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        load_products(seller) {

            this.loading = true

            this.cancelSearch(() => { this.loading = true });

            this.$forceUpdate();
            if (this.categoryFilter) this.query_params.cat_id = this.categoryFilter.cat_id


            return this.req_svc.request_seller_products(this.request.numeroCotacao, seller.id_vendedor, 20, this.query_params, this.cancelSource.token)
                .then(response => response.data)
                .then(data => {
                    this.products = data.data
                    this.products.forEach(element => {
                        element.isExpand = this.query_params.with_last_offer == 1
                        if (element.derivatives.length < 2) element.isExpand = true
                    });
                    setTimeout(() => {
                        this.total_items2 = data.total
                    }, 100);
                    // this.products.filter(p => !p.flag_generico).forEach(p => p.derivatives.push({ ...p }))
                    this.products.forEach(p => this.process_visibility(p))
                    this.loading = false
                })
                .catch(error => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        update_offer(product, seller_id = this.current_seller.id_vendedor) {
            this.$set(product, 'loading', true)
            return this.req_svc.update_request_product_offer(this.request.numeroCotacao, product.pro_id, { observacao: product.observacao, preco: product.valor, quantidadeDigitacao: 1, origem: "CLIENTE", vendedor: seller_id })
                .then(() => this.$set(product, 'loading', false))
                .then(() => this.$set(product, 'saved', true))
                .then(response => response.data)
                .catch(error => {
                    if (error.response.data.mensagem == 'Cotacao nao disponivel para digitacao'){
                        this.$swal.fire({
                            title: "Cotação expirada.",
                            text: "Deseja ajustar a data de enceramento?",
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Sim!"
                        }).then(resp => {
                            if (resp.isConfirmed){
                                this.show_deadline_modal = true
                            }
                        })
                    }
                    this.$set(product, 'loading', false)
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        enable_product(product) {

        },
        clear_offer(product) {
            this.$set(product, 'loading', true)
            return this.prod_svc.reset_product_last_offer(product.pro_id, this.current_seller.id_vendedor)
                .then(() => this.$set(product, 'loading', false))
                .then(() => this.$set(product, 'last_offer', 0))
                .then(() => this.$set(product, 'valor', 0))
                .then(() => this.$set(product, 'is_discontinued', 1))
                .then(() => this.update_offer(product))
                .catch(error => {
                    this.$set(product, 'loading', false)
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        continue_product(product) {
            this.$set(product, 'loading', true)
            return this.prod_svc.continue_product(product.pro_id, this.current_seller.id_vendedor)
                .then(() => this.$set(product, 'loading', false))
                .then(() => this.$set(product, 'saved', true))
                .then(() => this.$set(product, 'is_discontinued', 0))
                .catch(error => {
                    this.$set(product, 'loading', false)
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        filter_sellers() {
            let filter = this.filters.name
            this.sellers.forEach(s => s.visible = false)
            this.sellers
                .filter(s => s.vendedor_nome && s.vendedor_nome.toLowerCase().includes(filter.toLowerCase()) || s.fornecedor_nome && s.fornecedor_nome.toLowerCase().includes(filter.toLowerCase()))
                .forEach(s => s.visible = true)
            this.$forceUpdate()
        },
        filter_products() {
            this.load_products(this.current_seller)
        },
        process_visibility(p) {
            p.show_all = !this.query_params.with_last_offer
            // if(p.flag_generico == 1) {
            p.derivatives.forEach(p => p.visible = ((p.last_offer > 0 || p.is_discontinued) && this.query_params.with_last_offer) || !this.query_params.with_last_offer)
            // }
        },
        handle_navigation($event, product) {
            console.log('handlenavigation')
            this.navigate($event, {
                callback: {
                    function: this.update_offer,
                    params: product
                },
                ref: 'price'
            })
        },
        setNameFilter (value) {
            this.query_params.produto = value
            this.load_products(this.current_seller)
        },
        setCategoryFilter (value){
            console.log(value);
            this.categoryFilter = value
            this.load_products(this.current_seller)
        },
        copy_prices($evt, seller = this.current_seller) {
            this.confirm_action({
                message: "Confirma ação?",
                subtitle: "Todos os preços da ultima cotação serão atualizados nesta ",
                callback: () => {
                    this.loading = true
                    if (!this.current_seller.data_entrega) {
                        this.save_delivery().then(() => {
                            return this.quotation_svc
                                .copy_prices(this.request.numeroCotacao, seller.id_vendedor)
                                .then(() => this.load_products(seller))
                        })
                    } else {
                        return this.quotation_svc.copy_prices(this.request.numeroCotacao, seller.id_vendedor)
                            .then(() => this.load_products(seller))
                    }
                }
            })
        }
    },
    watch: {
        "query_params.with_last_offer": function () {
            this.load_products(this.current_seller)
        },
        "page": function () {
            // this.load_products(this.current_seller)
            this.load_sellers()
        },
        "page2": function () {
            this.load_products(this.current_seller)
        },
        "filters.name": function () { this.filter_sellers() },
        // "query_params.produto": function () {
        //     console.log('init');
        //     if (!this.loading) {
        //         this.loading = true
        //         setTimeout(() => this.load_products(this.current_seller), 1500)
        //     }
        // }
    },
    created() {
        this.request = this.$store.getters.clientSuplier
        if (!this.request) this.$router.push('/cliente/monitores/cotacoes')
        else {
            this.quotation_svc.list_payment_types().then((resp) => resp.data).then((resp) => {
                resp.data.forEach(element => {
                    this.plans.push({text: element.ttp_nome, value:element})
                });
                this.load_sellers()
                if (this.current_seller && !this.current_seller.forma) {
                    this.current_seller.delivery.payment_plan.ttp_nome = this.plans[0].ttp_nome
                }
            })
            this.getListaCategorias()
        }

    }
}
</script>

<style lang="scss" scoped>
.page {
    font-size: 1vw;
}

.page-route-select {
    display: flex;
    align-items: center;
}

.page-route-select-text {
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}

.page-route-select-icon {
    color: var(--primary-color);
    margin-right: 20px;
}

.page-title {
    font-weight: 600;
    font-size: 2.72em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;

}
.page-title-container{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-right: 4vw;
}

.modal-content-body {
    margin-top: 3vh;
    padding-right: 4vw;
}

.modal-content-search-title {
    font-weight: 400;
    font-size: 1.4em;
    color: #808080;
}

.modal-content-search-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5vh;
}

.modal-content-search-input {
    width: 100%;
    background: #FFFFFF;
    border: 0.5px solid #C1C1C1;
    border-radius: 8px;
    padding: 1vh 1vw;
    font-weight: 400;
    font-size: 1.4em;
    color: #808080;

    &:focus {
        outline: none;
    }
}

.modal-content-search-button {
    border: 0.5px solid #C1C1C1;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 10%;
    cursor: pointer;
    font-weight: 600;
    font-size: 1em;
    color: #202020;
}

.modal-content-search-button-icon {
    color: var(--primary-color);
    font-size: 1.6em;
}

.modal-content-table-header {
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 1vw;
    margin-bottom: 3vh;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.modal-content-table-suplier {
    flex: 1;
}

.page-table-line-special{background: #F7F7F7;}

.modal-content-table-seller {
    width: 30%;
}

.modal-content-table-status {
    width: 15%;
}

.modal-content-table-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
    width: 18%;
}

.modal-content-table-icon {
    color: var(--primary-color);
    height: 7.5vh;
    width: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #E5E5E5;
    font-size: 2.2vw;

    &:hover {
        background-color: var(--primary-color);
        color: white;
    }
}

.modal-content-table-rows {
    margin-top: 1vh;
}

.modal-content-table-row {
    display: flex;
    align-items: center;
    padding: 1vh 1.5vw;
}

.table-suplier-text {
    font-weight: 600;
    font-size: 1em;
    color: #605F5F;
    text-overflow: ellipsis;
    overflow: hidden;
}

.page-table-line-special {
    background: #F7F7F7;
}

.table-seller-text {
    font-weight: 400;
    font-size: 1em;
    color: #605F5F;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-content-table-button {
    height: unset;
    padding: 0.75vh 1vw;
}

.modal-content-products-title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-content-products-title-text {
    font-weight: 600;
    font-size: 1.5em;
    color: var(--primary-color);
}

.modal-content-products-filter-title {
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}

.client-suplier-payment{
    width: 25%;
}
.client-suplier-product-search{
    width: 32%;
}
.client-suplier-category{
    width: 17%;
}

.modal-content-products-filter-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
label {
    font-size: 1em;
}

.modal-content-products-filter-input {
    border: 1px solid #c2c2c3;
    border-radius: 8px;
    width: 30%;
    height: 95%;
    padding: 1vh 1vw;
    font-weight: 400;
    font-size: 1.5em;
    color: #A1A1A1;

    &:focus {
        outline: none;
    }
}
.expand-icon{
    font-size: 3em;
    color: var(--primary-color);
    margin-left: 1.5vw;
}

.modal-content-bilim-filter-input {
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    height: 7vh;
    margin-top: 5px;
    padding: 1vh 1vw;
    font-weight: 400;
    font-size: 1.5em;
    color: #A1A1A1;

    &:focus {
        outline: none;
    }

}

.modal-content-products-filter-button {
    margin-left: 2vw;
}

.modal-content-products-filter-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 300;
    font-size: 1.2em;
    color: #505050;
}

.modal-content-products-filter-checkbox-active {
    color: var(--primary-color);
    font-size: 1.5em;
    margin-right: 1vw;
    cursor: pointer;
}

.modal-content-products-filter2-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 3vh;
}

.modal-content-products-filter2-selectplan {
    width: 20%;
    font-weight: 400;
    font-size: 1.4em;
    color: #A1A1A1;
}

.modal-content-products-filter2-selectdate {
    width: 33%;
    font-weight: 400;
    font-size: 1.5em;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}

.modal-content-products-filter2-selectdate-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    padding: 0 2vw;
    height: 7vh;
    margin-top: 5px;
}


.modal-content-products-table {
    border: 1px solid #c2c2c3;
    border-radius: 10px;
    margin-top: 3vh;
}

.modal-content-products-table-header {
    display: flex;
    padding: 1vh 0;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.modal-content-products-table-cod {
    width: 20%;
}

.modal-content-products-table-expand{
    width: 5%;
}

.modal-content-products-table-name {
    flex: 1;
}

.modal-content-products-table-wrap {
    width: 40%;
}

.modal-content-products-table-button {
    display: flex;
    justify-content: center;
}

.modal-content-products-table-row {
    display: flex;
    align-items: center;
    background: #FFEADB;
    padding: 1vh 0;
    cursor: pointer;
}

.modal-content-products-table-button-input {
    height: unset;
    min-width: unset;
    width: fit-content;
    padding: 0.5vh 1.5vw;
}

.modal-content-products-subtable-header {
    display: flex;
    font-weight: 600;
    font-size: 1em;
    color: #605F5F;
    padding: 2vh 0;
}

.modal-content-products-subtable-brand {
    margin-left: 7vw;
    flex: 1;
}

.modal-content-products-subtable-price {
    width: 15%;
}

.modal-content-products-subtable-last {
    width: 15%;
}

.modal-content-products-subtable-action {
    width: 10%;
}

.modal-content-products-subtable-row {
    display: flex;
    align-items: center;
    padding: 0.5vh 0;
    font-weight: 400;
    font-size: 1em;
    color: #605F5F;
}
.desktop{
    display: block;
    @media only screen and (max-width: 700px) {
        display: none;
    }

}
.mobile{
    display: none;
    @media only screen and (max-width: 700px) {
        display: block;
    }
}

.modal-content-products-subtable-price-input {
    width: 85%;

    &:focus {
        outline: none;
    }

    border: 1px solid #D1D1D1;
    border-radius: 8px;
    padding: 0.5vh 1vw;
    font-weight: 400;
    font-size: 1em;
    color: #605F5F;
}

.modal-content-products-subtable-action-icon {
    color: red;
    cursor: pointer;
    font-size: 1.75em;
}

.modal-content-products-subtable-action-icon-add {
    cursor: pointer;
    color: lightgreen;
    font-size: 1.75em;
}

.modal-content-products-table-noproducts {
    margin: 5vh 0;
    text-align: center;
    font-weight: 500;
    font-size: 2em;
    color: #909090;
}

.modal-content-products-table-nobrands {
    margin: 1vh 0;
    text-align: center;
    font-weight: 500;
    font-size: 1em;
    color: #909090;
}
.add-obs-icon{
    color: orange;
    cursor: pointer;
    font-size: 1.75em;
}

@media only screen and (max-width: 850px) {
    .page {
        font-size: 2vw;
    }
    .page-title-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .client-suplier-payment{
        width: 45%;
    }
    .modal-content-products-table-wrap {
        display: none;
    }
    .modal-content-products-table-expand{
        width: 15%;
    }
    .modal-content-products-subtable-price{
        width: 30%;
    }
    .modal-content-products-subtable-last{
        width: 20%;
    }
    .modal-content-products-subtable-action{
        width: 15%;
    }

    .modal-content-products-table {
        font-size: 18px;
    }

    .button-containear-comp {
        margin-right: 18px;
    }

    .modal-content-products-filter-container {
        flex-wrap: wrap;
        align-items: center;
        gap: 2vh 0;
    }
    .client-suplier-product-search{
        width: 45%;
    }
    .client-suplier-category{
        width: 45%;
    }

    .modal-content-products-filter-input {
        width: 98%;
    }

    .modal-content-products-filter-button {
        margin: 0;
        width: 40vw;
    }

    .modal-content-products-filter2-container {
        flex-wrap: wrap;
    }

    .modal-content-products-filter2-selectplan {
        width: 98%;
    }

    .modal-content-products-filter2-selectdate {
        margin-left: 0;
        width: 45%;
    }

    .modal-content-products-filter2-button {
        margin-left: 0;
        margin-top: 3vh;
        width: 100%;
    }
}

@media only screen and (max-width: 700px) {
    .modal-content-header {
        width: 105vw;
    }

    .modal-mask {
        font-size: 2.5vw;
    }

    .modal-content-table-header {
        display: none;
    }

    .modal-content-table-row {
        margin-bottom: 3vh;
        flex-wrap: wrap;
    }
    .table-suplier-text{
        width: 50%;
        flex: unset;
    }
    .table-seller-text{
        width: 50%;
        text-align: center;
    }
    .modal-content-table-status{
        width: 50%;
    }
  .modal-content-table-action{
    width: 50%;
  }
  .modal-content-products-title{
    align-items: center;
  }
}

@media only screen and (max-width: 550px) {
    .page {
        font-size: 3vw;
    }
}</style>
