<template>
    <div style="width: 99%;">
        <div class="inputs-container">
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                    <div class="products-page__datetime-title">Data Inicial</div>
                </div>
                <div class="page-filters__right-select-wrapper">
                    <datetime value-zone="UTC-3" class="datetime-input form-control" v-model="fromDate" ref="datepicker"
                        auto type="date"></datetime>
                    <span class="material-icons date-icon">
                        search
                    </span>
                </div>
            </div>
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                    <div class="products-page__datetime-title">Data Final</div>
                </div>
                <div class="page-filters__right-select-wrapper">
                    <datetime value-zone="UTC-3" class="datetime-input form-control" v-model="toDate" ref="datepicker"
                        auto type="date"></datetime>
                    <span class="material-icons date-icon">
                        search
                    </span>
                </div>
            </div>
            <StandardButton style="margin-left: auto;" :action="()=> getExcel()" iconName="file_download" :text="'Exportar Excel'"/>
            <StandardButton style="margin-left: 2%;" :action="()=> updatePage(1)" iconName="search" :text="'Filtrar'"/>
        </div>


        <div class="page-table-header">
            <div class="page-table-header-text name-width">Nome</div>
            <div class="page-table-header-text info-width">Valor mínimo</div>
            <div class="page-table-header-text info-width">Valor Médio</div>
            <div class="page-table-header-text info-width">Valor Máximo</div>
            <div class="page-table-header-text info-width">Quantidade Digitação</div>
        </div>
        <div v-for="p in products" :key="p.prod_id" style="border-bottom: 1px solid lightgray; padding: 1vh 0;">
            <div class="page-table-row">
                <div class="page-table-code-colum name-width">{{ p.pro_descricao }}</div>
                <div class="page-table-code-colum info-width">R$ {{ p.min_valor.toFixed(2) }}</div>
                <div class="page-table-code-colum info-width">R$ {{ p.avg_valor.toFixed(2) }}</div>
                <div class="page-table-code-colum info-width">R$ {{ p.max_valor.toFixed(2) }}</div>
                <div class="page-table-code-colum info-width">{{ p.total }}</div>
            </div>
            <div v-for="b in p.brands" :key="b.propd_id" class="page-table-row">
                <div class="page-table-code-colum name-width" style="padding-left: 5%;">
                    <span @click="()=>{showProductHistory = p; brand = b}" class="material-icons-outlined" style="cursor: pointer; font-size: 1.5em; margin-right: 1vw; color: gray;">description</span>
                    {{ b.pro_observacao }}
                </div>
                <div class="page-table-code-colum info-width">R$ {{ b.min_valor.toFixed(2) }}</div>
                <div class="page-table-code-colum info-width">R$ {{ b.avg_valor.toFixed(2) }}</div>
                <div class="page-table-code-colum info-width">R$ {{ b.max_valor.toFixed(2) }}</div>
                <div class="page-table-code-colum info-width">{{ b.offers }}</div>
            </div>
        </div>
        <Paginacao classes="orange justify-end" :items_by_page_value="10" :total="totalItems" :page="1"
            v-on:change="updatePage($event)"></Paginacao>
        <cc-loader-full v-if="isLoading" />
        <ProductHistory v-if="showProductHistory" :product="showProductHistory" :propFromDate="fromDate" :propToDate="toDate" :brand="brand" :close="() => showProductHistory = false" />
		<a id="download-excel" style="display:none;"></a>

    </div>
</template>

<script>
import moment from 'moment';
import ClientService from '../../services/v3/ClientService';
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import ProductHistory from './ProductHistory.vue';

export default {
    name: 'CotProducts',
    data() {
        return {
            service: new ClientService(),
            isLoading: true,
            products: [],
            totalItems: 0,
            fromDate: moment().add(-30, 'days').toISOString(),
            toDate: moment().toISOString(),
            showProductHistory: false,
            brand: undefined
        }
    },
    components: {
        Paginacao,
        StandardButton,
        ProductHistory
    },
    created() {
        this.loadPage(1)
    },
    methods: {
        loadPage(page) {
            this.service.getproducts(page, this.fromDate.split('T')[0], this.toDate.split('T')[0]).then(resp => {
                this.products = resp.data.data
                this.products.forEach(element => {
                    element.brands.sort((a, b) => a.pro_observacao.localeCompare(b.pro_observacao))
                    if (element.brands.lenght>0) element.total = element.brands.reduce((partialSum, a) => partialSum + a.offers, 0)
                    else element.total = element.offers
                });
                this.totalItems = resp.data.total
                this.isLoading = false
            })
        },
        updatePage(page) {
            this.isLoading = true
            this.loadPage(page)
        },
        getExcel(){
            this.isLoading = true
            this.service.getproducts(1, this.fromDate.split('T')[0], this.toDate.split('T')[0], true).then(resp => {
                var file = new Blob([resp.data], {type: 'application/vnd.ms-excel'});
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-excel");
                el.download = "AcompanhamentoDePreco.xls";
                el.href = fileURL;
                el.click();
                setTimeout(function(){
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-table-header {
    background: #fffefc;
    border: 0.5px solid #e5e5e5;
    display: flex;
    padding: 1vh 0;
}

.page-table-header-text {
    font-weight: 600;
    font-size: 1.19em;
    color: #605f5f;
    padding-left: 5px;
}

.info-width {
    width: 15%;
}

.page-table-code-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
    display: flex;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605f5f;
}

.page-filters__right-title-wrapper {
    display: flex;
    align-items: center;
}

.products-page__datetime-title {
    font-size: 1.7em;
    font-weight: 400;
    letter-spacing: 0.15px;
    color: #898989;
}

.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;
}

.date-icon {
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 0px 8px 8px 0px;
    position: relative;
    left: -6px;
}

.datetime-input {
    border-radius: 8px !important;
}

.inputs-container {
    display: flex;
    gap: 2vw;
    margin-bottom: 3vh;
    align-items: end;
}
</style>