<style lang="scss">
@import "@/sass/commons/_variables";
.env-row {
  margin-top: 22vh;
  margin-bottom: -22vh;
  color: var(--primary-color);
  font-size: 2rem;
  word-wrap: break-word;
  font-family: monospace;
  text-align: center;
}

#app > .header {
  font-family: $font-default;
  & > div {
    display: flex;
    align-items: center;
  }
  z-index: 3;
}
.primary-header {
  background-color: white;
  height: 4rem;
  font-size: 0.9rem;
  color: $primary-color;
  font-weight: 500;
}
.logoHeaderStyle {
  max-height: 70px;
  border-radius: 12px;
}
.logoHeaderStyleMobile {
  max-height: 60px;
  border-radius: 12px;
}

.secondary-header {
  background-color: $ternary-color;
  height: 2.3rem;

  .menus {
    display: flex;
    justify-content: space-evenly;
    width: 60%;

    .hvr-underline-from-center:before {
      background: white;
    }

    .menu {
      display: inline-block;
      &.active {
        box-shadow: inset 0px -6px 0px 0px white;
      }
      a {
        display: inline-block;
        padding: 0.5rem;
        color: white;
        font-size: 0.9rem;
        height: 2.3rem;
        font-weight: 500;
      }
      &:hover {
        color: $primary-color;
        cursor: pointer;
      }
    }
  }
}
.menuDropDown {
  z-index: 1;
  position: absolute !important;
  margin-top: -8px;
  background-color: $ternary-color;
  margin-left: 56px;
  width: 50%;
  span:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
.menuLink {
  border: 1px solid white !important;
}
.header-upper-containear {
  background: var(--primary-color);
  height: 11vh;
  display: flex;
  align-items: center;
}
.header-upper-logo {
  flex: 1;
  margin-left: 50px;
}
.header-upper-icon {
  color: white;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-icon:hover {
  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
}
.header-upper-icon-insta:hover {
  color: var(--primary-color)!important;
  text-decoration: none !important;
  cursor: pointer;
}
.header-upper-icon-insta {
  background: white;
  color: var(--primary-color);
  border-radius: 50%;
  padding: 4px;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-suport-containear {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 22px;
  margin-right: 12px;
  cursor: pointer;
}
.header-upper-icon-whats {
  color: white;
  background: orangered;
  padding: 5px;
  border-radius: 50%;
}
.header-upper-icon-whats-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary-color);
  margin-left: 7px;
  margin-right: 10px;
}
.header-upper-icon-divider {
  border-right: 1px solid white;
  margin-right: 15px;
}
.header-upper-user-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  margin-right: 20px;
}
.header-lower-containear {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  color: #605f5f;
  padding-top: 7px;
  padding-bottom: 9px;
  margin-bottom: 11px;
  box-shadow: 0px 5px 8px 0px lightgrey;
  min-height: 50px;
  background-color: white;
}
.header-lower-texts {
  /* margin-right: 5vw; */
  cursor: pointer;
  color: #605f5f;
  font-size: 16px;
}
.header-lower-texts:hover {
  color: #605f5f !important;
}
.header-lower-texts-activate {
  border-bottom: 3px solid var(--secondary-color);
  padding-bottom: 5.5px;
}
.header-contact-list {
  position: absolute;
  right: 20px;
  top: 100px;
  z-index: 10;
}
.header-contact-list-row {
  background-color: var(--primary-color);
  border-radius: 5px;
  padding: 2px 4px;
  margin-bottom: 10px;
}
.header-contact-list-row-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: white;
}
.mobile-header {
  display: none;
}
.mobile-header-logo-containear {
  flex: 1;
  display: flex;
  justify-content: center;
}
.mobile-side-menu-containear {
  position: absolute;
  z-index: 3;
  top: 0;
  background-color: white;
  min-width: 100%;
  min-height: 100vh;
}
.account-icon {
  font-size: 35px;
  color: white;
  margin-right: 15px;
}
.header-upper-icons {
  margin-right: 12px;
}
.mobile-header-img-logo {
  width: 130px;
}
.desktop-header {
  position: fixed;
  width: 100%;
  z-index: 4;
}
.mobile-only{
    display: none !important;
}
@media only screen and (max-width: 630px) {
  .desktop-header {
    display: none;
  }
  .env-row {
    margin-top: 20vh;
    margin-bottom: 0;
  }
  /* .mobile-header {
    background: var(--primary-color);
    height: 8vh;
    display: flex;
    align-items: center;
    z-index: 10;
    padding: 35px 0;
  } */
  .mobile-only{
    display: flex !important;
    margin-top: 9vh;
  }
  .header-lower-texts{
    text-align: center;
  }
  .header-lower-containear{
    /* gap: 4vw; */
  }
  .mobile-header{
        background: var(--primary-color);
        /* background-color: red !important; */
        height: 8vh;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr !important;
        align-content: center;
        /* display: flex; */
        /* align-items: center; */
        z-index: 10;
        padding: 35px 0;
    }
}
.mobile-header-menu-icon {
  color: white;
  margin-left: 15px;
  cursor: pointer;
}

.contact {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  a {
    display: inline-block;
  }
  i {
    font-size: 1.7rem;
    margin-left: 14px;
    cursor: pointer;
  }
  .support {
    cursor: pointer;
    width: 123px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    border: 1px solid #4ac959;
    color: #4ac959;
    border-radius: 5px;
    i {
      margin-right: 4px;
    }
    .support-contacts {
      transform: translateX(160%);
      position: fixed;
      top: 40px;
      right: 142px;
      list-style: none;
      li {
        padding: 6px;
        padding-right: 14px;
        border: 1px solid #4ac959;
        border-radius: 6px;
        background-color: white;
        margin-bottom: 6px;
        &:hover {
          color: white;
          border: 1px solid $primary-color;
          background-color: $primary-color;
          i,
          a {
            color: white;
          }
        }
        i {
          font-size: 1rem;
        }
        a {
          color: #4ac959;
        }
      }
    }
  }
  .support-remote {
    cursor: pointer;
    width: 123px;
    height: 100%;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    border: 1px solid red;
    color: red;
    i {
      margin-right: 4px;
    }
  }
}
</style>
<template>
  <div>
    <div class="desktop-header">
      <div class="header-upper-containear not-print">
        <router-link
          :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }"
          class="header-upper-logo"
        >
          <!-- <img src="@/assets/Logo-BRANCA.svg" /> -->
          <img
            :class="
              this.whitelabel.data.imgs.img_logo != null ? 'logoHeaderStyle' : ''
            "
            :src="
              this.whitelabel.data.imgs.img_logo != null
                ? this.whitelabel.data.imgs.img_logo
                : require('@/assets/Logo-BRANCA.svg')
            "
            alt="Logo Clube da Cotação"
          />
        </router-link>
        <a
          href="https://www.facebook.com/clubdacotacao"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Facebook_black.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/clubdacotacao/"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Instagram_black.svg" alt="" />
        </a>
        <a
          href="mailto:contato@clubdacotacao.com.br"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Mail_black.svg" alt="" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCiP8ACo7VDoIzlUwVC5m5Xw"
          class="header-upper-icons"
          target="_blank"
        >
          <img src="@/assets/Youtube_black.svg" alt="" />
        </a>
        <!-- <div class="header-upper-suport-containear" @click="present_numbers = !present_numbers">
                    <img src="@/assets/WhatsApp_black.svg" alt="">
                    <div class="header-upper-icon-whats-text">Suporte</div>
                </div> -->
        <div v-if="present_numbers" class="header-contact-list">
          <div class="header-contact-list-row">
            <i class="fa fa-user" />
            <a
              class="header-contact-list-row-text"
              href="https://api.whatsapp.com/send?phone=5581996490176"
              target="_blank"
            >
              +55 (81) 99649-0176
            </a>
          </div>
          <div class="header-contact-list-row">
            <i class="fa fa-user" />
            <a
              class="header-contact-list-row-text"
              href="https://api.whatsapp.com/send?phone=5581997413889"
              target="_blank"
            >
              +55 (81) 99741-3889
            </a>
          </div>
          <div class="header-contact-list-row">
            <i class="fa fa-user" />
            <a
              class="header-contact-list-row-text"
              href="https://api.whatsapp.com/send?phone=5581997410500"
              target="_blank"
            >
              +55 (81) 99741-0500
            </a>
          </div>
        </div>
        <!-- <p @click="handleParagraphClick">testee</p> -->
        <div class="header-upper-icon-divider">
          <div
            @click="openPage('https://anydesk.com/pt/downloads')"
            class="header-upper-suport-containear"
          >
            <img src="@/assets/Rectangle_32.svg" />
            <div class="header-upper-icon-whats-text">Anydesk</div>
          </div>
        </div>
        <span class="material-icons account-icon">account_circle</span>
        <div class="header-upper-user-text">
          {{ user.user_name }}
        </div>
      </div>
      <div class="header-lower-containear not-print">
        <router-link
          :class="{
            'header-lower-texts-activate': $route.params.tab == 'cotacoes',
          }"
          :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }"
          class="header-lower-texts"
          >Monitor de Cotação</router-link
        >
        <router-link
          :class="{
            'header-lower-texts-activate': $route.params.tab == 'ressuprimento',
          }"
          :to="{ name: 'monitors-page', params: { tab: 'ressuprimento' } }"
          class="header-lower-texts"
          >Monitor de Ressuprimento</router-link
        >
        <router-link
          :class="{
            'header-lower-texts-activate': $route.name == 'repurchase-page',
          }"
          :to="{ name: 'repurchase-page' }"
          class="header-lower-texts"
          >Monitor de Divergência</router-link
        >
        <router-link
          :class="{
            'header-lower-texts-activate': $route.params.tab == 'mobile',
          }"
          :to="{ name: 'monitors-page', params: { tab: 'mobile' } }"
          class="header-lower-texts"
          >Lista Mobile</router-link>
        <router-link
          :class="{
            'header-lower-texts-activate': $route.params.tab == 'pedidos',
          }"
          :to="{ name: 'monitors-page', params: { tab: 'pedidos' } }"
          class="header-lower-texts"
          >Pedidos</router-link
        >
        <router-link
          :class="{
            'header-lower-texts-activate': $route.params.tab == 'entregas',
          }"
          :to="{ name: 'monitors-page', params: { tab: 'entregas' } }"
          class="header-lower-texts"
          >Monitor de entregas</router-link
        >
        <!-- <router-link :class="{ 'header-lower-texts-activate': $route.params.tab == 'price' }" :to="{ name: 'monitors-page', params: { tab: 'price' } }" class="header-lower-texts">Monitor Preço de Venda Varejo</router-link> -->
      </div>
    </div>
    <div class="mobile-header">
      <i
        class="fa-solid fa-bars fa-2x mobile-header-menu-icon"
        v-on:click="setMobileMenu(true)"
      ></i>
      <div class="mobile-header-logo-containear">
        <router-link
          :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }"
        >
          <!-- <img src="@/assets/Logo-BRANCA.svg" class="mobile-header-img-logo" /> -->
          <img
            :class="
              this.whitelabel.data.imgs.img_logo !== null
                ? 'logoHeaderStyleMobile'
                : 'mobile-header-img-logo'
            "
            :src="
              this.whitelabel.data.imgs.img_logo !== null
                ? this.whitelabel.data.imgs.img_logo
                : require('@/assets/Logo-BRANCA.svg')
            "
            alt="Logo Clube da Cotação"
          />
        </router-link>
      </div>
    </div>
    <div class="header-lower-containear not-print mobile-only">
                <router-link :class="{ 'header-lower-texts-activate': $route.params.tab == 'cotacoes' }" :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }" class="header-lower-texts">Cotação</router-link>
                <router-link :class="{ 'header-lower-texts-activate': $route.params.tab == 'ressuprimento' }" :to="{ name: 'monitors-page', params: { tab: 'ressuprimento' } }" class="header-lower-texts">Ressuprimento</router-link>
                <!-- <router-link :class="{ 'header-lower-texts-activate': $route.name == 'repurchase-page' }" :to="{ name: 'repurchase-page' }" class="header-lower-texts">Divergência</router-link> -->
                <router-link :class="{ 'header-lower-texts-activate': $route.params.tab == 'mobile' }" :to="{ name: 'monitors-page', params: { tab: 'mobile' } }" class="header-lower-texts">Mobile</router-link>
                <router-link :class="{ 'header-lower-texts-activate': $route.params.tab == 'pedidos' }" :to="{ name: 'monitors-page', params: { tab: 'pedidos' } }" class="header-lower-texts">Pedidos</router-link>
                <!-- <router-link :class="{ 'header-lower-texts-activate': $route.params.tab == 'price' }" :to="{ name: 'monitors-page', params: { tab: 'price' } }" class="header-lower-texts">Monitor Preço de Venda Varejo</router-link> -->
        </div>
    <div v-if="ENV != 'prod'" class="not-print env-row">
      Você esta em ambiente apresentação.
    </div>
  </div>
  <!-- <div class="container-fluid header not-print">
        <div class="row primary-header not-print">
            <div class="col col-2 pr-0 pl-0 flex-center">
                <router-link :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }" :title="version">
                    <img class="logo" src="/images/logotipo.png"/>
                </router-link>
            </div>
            <div class="col col-md-5">
                <span>
                    <i class="fa fa-user" />
                    Olá, <span>{{ user.user_name | capitalize }}</span>
                </span>
                <cc-breadcrumb v-bind:breadcrumbs="breadcrumbs" classes="gray"></cc-breadcrumb>
            </div>
            <div class="col contact right">
                <a href="https://www.facebook.com/clubdacotacao" target="_blank"><i style="color:#4267B2" class="fab fa-facebook-square"></i></a>
                <a href="https://www.instagram.com/clubdacotacao/" target="_blank"><i style="color:#FFDC80" class="fab fa-instagram"></i></a>
                <a href="mailto:contato@clubdacotacao.com.br" target="_blank"><i style="color:#DB4437" class="far fa-envelope"></i></a>
                <a href="https://www.youtube.com/channel/UCiP8ACo7VDoIzlUwVC5m5Xw" target="_blank"><i style="color:#FF0000" class="fab fa-youtube"></i></a>
                <span class="support py-1 ml-2" @click="present_numbers = !present_numbers">
                    <i class="fab fa-whatsapp" style="color:#4AC959"></i> Suporte
                    <ul id="contacts" class="support-contacts" >
                        <li><i class="fa fa-user" /><a href="https://api.whatsapp.com/send?phone=5581996490176" target="_blank">+55 (81) 99649-0176</a></li>
                        <li><i class="fa fa-user" /><a href="https://api.whatsapp.com/send?phone=5581997413889" target="_blank">+55 (81) 99741-3889</a></li>
                        <li><i class="fa fa-user" /><a href="https://api.whatsapp.com/send?phone=5581997410500" target="_blank">+55 (81) 99741-0500</a></li>
                    </ul>

                </span>

                <span class="support-remote py-1 ml-2" @click="openPage('https://anydesk.com/pt/downloads')">
                    <i class="far fa-arrow-alt-circle-down"></i> Anydesk
                </span>
            </div>
        </div>
        <div  class="row secondary-header flex-column shadow-sm mb-2">
            <div class="menus flex-row">
                <div @mouseover="isHidden = true" class="menu hvr-underline-from-center" :class="{ active: $route.params.tab == 'cotacoes' }">
                    <router-link :to="{ name: 'monitors-page', params: { tab: 'cotacoes' } }">Monitor de Cotações</router-link>
                </div>
                <div @mouseover="isHidden = true" class="menu hvr-underline-from-center" :class="{ active: $route.params.tab == 'ressuprimento' }">
                    <router-link :to="{ name: 'monitors-page', params: { tab: 'ressuprimento' } }">Monitor de Ressuprimento</router-link>
                </div>
                <div @mouseover="isHidden = true" class="menu hvr-underline-from-center" :class="{ active: $route.params.tab == 'mobile' }">
                    <router-link :to="{ name: 'monitors-page', params: { tab: 'mobile' } }">Lista Mobile</router-link>
                </div>
                <div  @mouseover="isHidden = false" @mouseout="isHidden = true" class="menu hvr-underline-from-center" :class="{ active: $route.params.tab == 'pedidos' }">
                    <router-link :to="{ name: 'monitors-page', params: { tab: 'pedidos' } }">Pedidos</router-link>
                </div>
            </div>
        </div>
    </div> -->
</template>

<script>
import * as CONSTANTS from "@/constants/constants";
import Breadcrumb from "@/components/shared/Breadcrumb";
import SessionService from "@/services/TokenService";
import { mapState, mapGetters } from "vuex";
import { gsap } from "gsap";

export default {
  components: {
    ccBreadcrumb: Breadcrumb,
  },
  props: ["breadcrumbs", "setMobileMenu"],
  data() {
    return {
      user_name: null,
      ENV: CONSTANTS.PROJECT_ENV,
      API_URL: CONSTANTS.API_URL,
      session: new SessionService(),
      isHidden: true,
      tab: "",
      present_numbers: false,
    };
  },
  methods: {
    handleParagraphClick() {
      console.log(this.whitelabel.data);
    },
    openPage(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
    present_numbers(current) {
      if (current) {
        gsap.fromTo(
          "#contacts",
          { x: "100%" },
          { x: "50%", duration: 1, ease: "back.out(1.7)" }
        );
      } else {
        gsap.fromTo(
          "#contacts",
          { x: "0%" },
          { x: "170%", duration: 1, ease: "back.out(1.7)" }
        );
      }
    },
  },
  computed: {
    ...mapState(["user", "whitelabel"]),
    ...mapGetters(["version"]),
  },
};
</script>

