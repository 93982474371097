<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="new-product__main-container">
        <div class="new-product__header-container">
          <span class="new-product__header-title">
            Novo Produto
          </span>
          <span @click="closeDialog" class="material-icons new-product__closebtn-icon">
            cancel
          </span>
        </div>
        <div class="standard-buttons__wrapper">
          <StandardButton class="standard-btn" :action="validaFormularioCadastro" :iconName="'save'" :text="'Salvar'"/>
          <StandardButton class="standard-btn" v-if="isGenerico && product.pro_id" :action="showMarcas" :iconName="'store'" :text="'Marcas'"/>
          <StandardButton class="standard-btn" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'"/>
        </div>
        <cc-loader v-if="loading" style="margin-top: 20vh;"/>
        <div v-else>
          <div class="new-product__actions-wrapper">
            <div class="new-product__input-wrapper" :class="{ '': !to_request, '': to_request }">
              <span class="new-product__input-title">Descrição <span style="color:red !important">*</span></span>
              <input  v-model="produtoModal" type="text" :placeholder="'Digite a Descrição do Produto'" class="new-product__input">
            </div>
            <div class="new-product__input-wrapper">
              <span class="new-product__input-title">Categorias <span style="color:red !important">*</span></span>
              <cc-select-v2 v-if="!editProductCategoryAndIntCode" class="new-product-select-width" :disabled="disabledInputs" :class="{erroInput:errorCategoriaModal}" @focus="errorCategoriaModal = false" :tracker="'cat_id'" :text="'cat_descricao'" :options="categories" v-model="categoriaModal"></cc-select-v2>
              <cc-select-v2 v-if="editProductCategoryAndIntCode" class="new-product-select-width" :class="{erroInput:errorCategoriaModal}" @focus="errorCategoriaModal = false" :tracker="'cat_id'" :text="'cat_descricao'" :options="categories" v-model="categoriaModal"></cc-select-v2>
            </div>
          </div>
          <div class="new-product__actions-wrapper">
            <div class="new-product__input-wrapper">
              <span class="new-product__input-title">
                Código Interno
                <!-- <i style="color: #26BD81 !important" class="fa fa-question-circle" aria-hidden="true"></i> -->
                <i v-if="!edit_internal_code_allowed && !isGenerico" style="cursor:pointer;margin-left: 10px;color: var(--primary-color) !important" class="fas fa-lock" aria-hidden="true" @click="edit_internal_code_allowed = !edit_internal_code_allowed"></i>
                <i v-else-if="!isGenerico" style="cursor:pointer;margin-left: 10px;color: #c2c2c3 !important" class="fas fa-lock-open" aria-hidden="true" @click="edit_internal_code_allowed = !edit_internal_code_allowed"></i>
              </span>
              <input v-if="!editProductCategoryAndIntCode" :disabled="(disabledInputs && !internal_code_only) || !edit_internal_code_allowed || isGenerico" v-model="codigoModal" type="text" :placeholder="'Código Interno'" class="new-product__input">
              <input v-if="editProductCategoryAndIntCode" v-model="codigoModal" type="text" :placeholder="'Código Interno'" class="new-product__input">
            </div>
            <div class="new-product__input-wrapper">
              <span class="new-product__input-title">Código<span style="color:red !important">*</span></span>
              <input :disabled="disabledInputs" v-model="codEanModal" type="number" :placeholder="'Digite o Código '" class="new-product__input">
            </div>
          </div>
          <div class="new-product__actions-wrapper">
            <div class="new-product__input-wrapper mobile-margin">
              <span class="new-product__input-title">Unidade <span style="color:red !important">*</span></span>
              <cc-select-v2 v-if="!isGenerico" class="new-product-select-width"  :class="{erroInput:errorCategoriaModal}" @focus="errorCategoriaModal = false" :tracker="'value'" :text="'name'" v-model="wrap" :options="wraps"></cc-select-v2>
              <div class="new-product-generic-input new-product-select-width" v-else>
                <cc-select-v2 v-if="!editGeneric" style="width: 90% !important;" v-model="genericWrap" :class="{erroInput:errorCategoriaModal}" @focus="errorCategoriaModal = false" :tracker="'value'" :text="'name'"  :options="[...wraps,{name: wrap}]"></cc-select-v2>
                <input v-else v-model="wrap" type="text" style="width: 90% !important;" :placeholder="'Digite a unidade '" class="new-product__input">
                <span v-if="!editGeneric" @click="editGeneric=true"  class="material-icons-outlined edit-generic-icon">edit</span>
                <span v-else @click="editGeneric=false" class="material-icons-outlined edit-generic-icon">close</span>
              </div>
              
            </div>
            <div class="new-product__input-wrapper ">
              <span class="new-product__input-title">QTD Embalagem <span style="color:red !important">*</span></span>
              <input :disabled="wrap && (wrap.value == 'UN' || wrap.value == 'DZ' || isGenerico)" @focus="errorQtdEmbalagemModal = false" :class="{erroInput:errorQtdEmbalagemModal}" v-model="qtdEmbalagemModal" type="text" :placeholder="'Selecione uma Quantidade Embalagem'" class="new-product__input">
            </div>
          </div>
          <div class="new-product__actions-wrapper">
            <div class="new-product__input-wrapper ">
              <span class="new-product__input-title">Fornecedor Exclusivo</span>
              <cc-select-v2 class="new-product-select-width" v-model="selectedProvider" :searchable="true"   :tracker="'value'" :text="'text'"  :options="providers"></cc-select-v2>
            </div>
            <div class="new-product__input-wrapper ">
              <span class="new-product__input-title">Vendedor</span>
              <cc-select-v2 class="new-product-select-width" :class="{erroInput: selectedBuyerError}" v-model="selectedBuyer"    :tracker="'value'" :text="'text'"  :options="buyers"></cc-select-v2>
            </div>
          </div>
          <div style="display: flex;">
            <div class="new-product__generic-option-wrapper">
              <div class="new-product__generic-checkbox-wrapper">
                <cc-checkbox  :checked="isGenerico" @change="(evt) => toggle_generic(evt.target.checked)"/>
              </div>
              <div class="new-product__generic-title-wrapper">
                <span class="new-product__generic-title" :class="{erroLabel:errorProdutoModal}">
                  Produto Genérico?
                </span>
              </div>
            </div>
            <div class="new-product__generic-option-wrapper">
              <div class="new-product__generic-checkbox-wrapper">
                <cc-checkbox :checked="product.pro_pedido_direto" @change="(evt) => product.pro_pedido_direto = evt.target.checked"/>
              </div>
              <div class="new-product__generic-title-wrapper">
                <span class="new-product__generic-title" :class="{erroLabel:errorProdutoModal}">
                  Produto Pedido direto?
                </span>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 py-3">
              <toggle-button v-model="statusModal" :color="'var(--primary-color)'" :height="30" :labels="{ checked: 'Ativo', unchecked: 'Inativo' }" :width="75" />
            </div>
          </div>


          <div class="new-product__actions-wrapper-centered">
            <div class="new-product__input-wrapper" v-if="to_request" :class="{ 'c': !to_request, '': to_request }">
              <span class="new-product__input-title" :class="{erroLabel:errorProdutoModal}">Quantidade <span style="color:red !important">*</span></span>
              <input :disabled="disabledInputs" v-model.number="produtoQuantidadeCotacaoModal" type="text" :placeholder="'Quantidade'" class="new-product__input">
            </div>
          </div>
          <div v-if="serverMsg" class="col-md-12 col-lg-12">
            <h4 class="py-4 text-center text-danger">{{serverMsg}}</h4>
          </div>
          <div class="new-product__table-container" v-if="!to_request">
            <table class="new-product__table-wrapper">
              <thead class="new-product__table-thead">
                <tr class="new-product__table-thead-tr">
                  <th class="new-product__table-th text-center">ID</th>
                  <th class="new-product__table-th">Empresa</th>
                  <th class="new-product__table-th text-center">QTD. Sugestão</th>
                </tr>
              </thead>
              <tbody class="new-product__table-tbody">
                <tr v-for="(store, index) in stores"
                    v-bind:key="'store-' + store.loj_id"
                    class="new-product__table-tbody-tr"
                    :class="getSpecialBackground(index)">
                  <td class="new-product__table-td text-center desktop">
                    {{store.loj_id}}
                  </td>
                  <td class="new-product__table-td desktop">
                    {{store.loj_nomeFantasia.toUpperCase()}}
                  </td>
                  <td class="new-product__table-td text-center">
                    <div class="new-product-table-mobile-header">{{store.loj_nomeFantasia.toUpperCase()}}</div>
                    <div class="ressuply-products__edit-amount-wrapper">
                      <input type="number"
                            min="0"
                            v-model="store.qtd_sugestao"
                            class="product-amount text-center w-25 focused-input"/>
                      <span @click="handle_navigation($event, item)" class="material-icons ressuply-products__edit-amount-icon not-print">
                        add
                      </span>
                    </div>
                  </td>
                </tr>
                <tr v-show="total_items > items_by_page_value" class="seemore-button-containear" >
                  <td class="text-center" colspan="6">
                    <Paginacao classes="orange justify-end"
                              :items_by_page_value="items_by_page_value"
                              :total="total_items"
                              :page="page"
                              v-on:change="updatePage($event)">
                    </Paginacao>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <ProductBrandsModal v-if="brands && showEditBrandsModal" :closeDialog="()=>{showEditBrandsModal=false}"
            :brands="brands" :product="product" />
  </div>
</div>
</template>

<script>

import * as CONSTANTS from "@/constants/constants";
import ProductService from '@/services/ProductService';
import ErrorHandlerService from "@/services/ErrorHandlerService";
import QuotationService from '../../services/QuotationService';
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import Paginacao from "@/components/cliente/base-components/Pagination";
import ProductBrandsModal from "@/modals/product-brands/product-brands.modal";
import SellersService from "@/services/v3/commons/sellers.service";
import ProviderService from "@/services/v3/provider.service";

export default {
    components: { StandardButton, Paginacao, ProductBrandsModal },
    props: {
        product: {
            type: Object,
            required: true
        },
        to_request: {
            type: Boolean,
            default: false
        },
        internal_code_only: {
            type: Boolean,
            default: false
        },
        closeDialog: {
            type: Function,
        },
        editProductCategoryAndIntCode: {
          type: Boolean,
        }
    },
    data() {
        return {
            page: 1,
            items_by_page_value: 30,
            total_items: 1000,
            edit_internal_code_allowed: false,
            modal: {
                title: "produto",
                submit: this.validaFormularioCadastro,
                submit_text: 'Salvar',
                subtitle: 'Crie ou altere informações do produto',
                icon_title: 'far fa-edit',
                unpermitted_message: "Aceite o termo de consentimento para continuar.",
                permitted: true,
                style: {
                    width: "50%"
                }
            },
            wraps_load: [],
            buyers: [],
            selectedBuyerError: false,
            selectedBuyer: { text: this.product.pro_vend_nome, value: {id: this.product.pro_vend_id}},
            wraps: [
                { name: 'Atado', value: 'AT' },
                { name: 'Unidade', value: 'UN' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
                { name: 'Kilograma', value: 'KG' },
                { name: 'Duzia', value: 'DZ' },
                { name: 'Display', value: 'DP' },
                { name: 'Pacote', value: 'PC' }
            ],
            wraps_generic: [
                { name: 'Unidade', value: 'UN' },
                { name: 'Litro', value: 'LT' },
                { name: 'Kilograma', value: 'KG' },
                { name: 'Caixa', value: 'CX' },
                { name: 'Fardo', value: 'FD' },
            ],
            image:"/images/search.png",
            imgList:"/images/number.png",
            imgDot:"/images/dot.png",
            imgA:"/images/font.png",
            botaoCadastrar: false,
            errorProdutoModal: false,
            errorCodigoModal: false,
            editGeneric: false,
            genericWrap: false,
            errorUnidadeModal: false,
            errorEmbalagemModal: false,
            errorQtdEmbalagemModal: false,
            errorCategoriaModal: false,
            errorCodEanModal: false,
            statusModal: true,
            loading: true,
            wrap:'',
            codigoModal: '',
            tituloModal: '',
            produtoModal: '',
            genericoMoqtdEmbalagemModaldal: 0,
            qtdEmbalagemModal: 1,
            produtoQuantidadeCotacaoModal: 0,
            categoriaModal: '',
            unidadeModal: '',
            serverMsg: '',
            codEanModal: '',
            qtdSugestaoModal: '',
            isGenerico: false,
            arrayEmbalagem: [],
            stores: [],
            categories: [],
            brands: [],
            providers: [],
            selectedProvider: { text: this.product.pro_for_nome, value: true},
            disabledInputs: false,
            disabledInputGenerico: false,
            showEditBrandsModal: false,
            product_service: new ProductService(),
            psvc: new ProviderService(),
            service: new QuotationService(),
            sellers_svc: new SellersService()
        }
    },
    methods: {
        getSpecialBackground(idx) {
          if (idx % 2 != 0) return 'page-table-line-special'
        },
        close() {
            this.$emit('close')
        },
        showMarcas() {
            this.loading = true;
            this.product_service
                .get_product_brands(this.product.pro_id)
                .then((data) => {
                    this.brands = data.data.produtos;
                    this.showEditBrandsModal = true;
                    this.loading = false;
                })
                .catch((error) => {
                    ErrorHandlerService.handle(error, this.$store);
                });
        },
        validaFormularioCadastro(){
            if(this.product && this.wrap  && this.categoriaModal && this.codEanModal && (this.produtoQuantidadeCotacaoModal || !this.to_request) && (!this.selectedProvider || this.selectedBuyer )){
                if(this.product.pro_id){
                    this.editarProduto()
                } else {
                    this.cadastroProduto()
                }
            } else {
                this.serverMsg = "Preencha os campos indicados acima"
            }

            if(!this.produtoModal){
                this.errorProdutoModal = true
            }
            if(!this.wrap){
                this.errorEmbalagemModal = true
            }
            if(!this.qtdEmbalagemModal){
                this.errorQtdEmbalagemModal = true
            }
            if(!this.categoriaModal){
                this.errorCategoriaModal = true
            }
            if(!this.codEanModal){
                this.errorCodEanModal = true
            }
            if (this.selectedProvider && !this.selectedBuyer){
              this.selectedBuyerError = true
            }
        },
        load_categories() {
            return this.service.getCategorias().then(response => response.data).then(data => {
                this.categories = data.categorias
            })
        },
        getListaProdutos() {
            this.$emit('reload_products');
        },
        editarProduto(){
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            let data = {
                codInterno: this.codigoModal,
                generico: (this.isGenerico) ? 1 : 0,
                'pro_pedido_direto': (this.product.pro_pedido_direto) ? 1 : 0,
                descricao: this.produtoModal,
                categoria: this.categoriaModal.cat_id,
                pro_id: this.prodId,
                codEan: this.codEanModal,
                pro_status: this.statusModal,
                lojas_sugestao: this.stores.filter(s => s.qtd_sugestao != null),
                unidade_compra: this.isGenerico ? this.wrap : '  ',
                embalagem: this.isGenerico ? 'UN 1' : `${this.wrap.value} ${this.qtdEmbalagemModal}`,
                vendedor_exclusivo: this.selectedBuyer ? this.selectedBuyer.value.id : null
            }
            let url = `${CONSTANTS.API_URL}/atualizarprodutonormalcliente`
            axios.post(url,data,config)
            .then((res)=>{
                this.loading = false;
                this.getListaProdutos();
                this.close();
                this.$swal.fire("Editado!", "Produto editado com sucesso.", "success");
            })
            .catch((err)=>{
                this.loading = false
                this.serverMsg = err.response.data.mensagem
            })
        },
        cadastroProduto(){
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
            };
            let wrap = this.wraps.find(u => u.value == this.wrap.value)
            if(this.isGenerico) {
                wrap = this.wraps_generic.find(u => u.value == this.wrap.value)
            }
            let data = {
                codInterno: this.codigoModal,
                generico: (this.isGenerico)? 1 : 0,
                'pro_pedido_direto': (this.product.pro_pedido_direto) ? 1 : 0,
                unidade_compra: this.isGenerico ? this.wrap : '  ',
                descricao: this.produtoModal,
                embalagem: this.isGenerico ? 'UN 1' : `${this.wrap.value} ${this.qtdEmbalagemModal}`,
                categoria: this.categoriaModal.cat_id,
                codEan: this.codEanModal,
                pro_status: this.statusModal,
                pro_sugestao: this.qtdSugestaoModal,
                lojas_sugestao: this.stores.filter(s => s.qtd_sugestao != null),
                vendedor_exclusivo: this.selectedBuyer ? this.selectedBuyer.value.id : null
            }

            let url = `${CONSTANTS.API_URL}/inserirprodutonormalcliente`
            axios.post(url,data,config)
            .then(res => res.data)
            .then((res)=>{
                this.loading = false;
                if(this.to_request) {
                    data.pro_id = res.id
                    data.pro_ean = data.codEan
                    data.new_amount = this.produtoQuantidadeCotacaoModal
                    this.$emit('emit_product', data)
                }
                this.$swal.fire("Inserido!", "Produto inserido com sucesso.", "success");
                this.close()
            })
            .catch((err)=>{
                this.loading = false
                this.serverMsg = err.response.data.mensagem;
                if(err.response.data.valorSugestao){
                    this.codEanModal = err.response.data.valorSugestao
                }
            })
        },
        getSugestaoCliente(){
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            let url = `${CONSTANTS.API_URL}/getsugestaocliente`;
            axios.get(url, config)
            .then(response => response.data)
            .then(data => {
                this.valorSugestao = data.valorSugestao;
                this.codEanModal = this.valorSugestao;
            });
        },
        getListaembalagens() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            let url = `${CONSTANTS.API_URL}/getembalagens`;
            return axios.get(url, config).then(data => {
                this.arrayEmbalagem = data.data.embalagens
            });
        },

        getStoresProcutSuggestion() {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            let url = `${CONSTANTS.API_URL}/v2/clients/${localStorage.getItem("cli_id")}/store/product/`;
            if(this.prodId){
                url += this.prodId;
            }
            return axios.get(url, config).then(data => {
                this.stores = data.data.lojas;
                this.total_items = data.data.lojas;
                this.loading = false;
                //this.arrayEmbalagem = data.data.embalagens
            });
        },
        get_packaging_by_code(code) {
            return this.wraps.filter(em => em.value === code)[0]
        },
        get_category_by_id(id) {
            return this.categories.filter(cat => cat.cat_id === id)[0]
        },
        load_fields() {
            this.tituloModal = 'Editar Produto'
            this.botaoCadastrar = false;
            this.produtoModal = this.product.pro_descricao;
            this.codigoModal = this.product.pro_codInterno;
            if(this.product.pro_id) {
                this.categoriaModal = this.get_category_by_id(this.product.cat_id)
                // this.wrap = this.get_packaging_by_code(this.product.pro_embalagem.split(' ')[0])
                this.qtdEmbalagemModal = this.product.pro_embalagem.split(' ')[1]
                this.statusModal = parseInt(this.product.pro_status) == 1;
                this.isGenerico = (this.product.pro_generico === 1) ? true : false;
                this.wrap = this.isGenerico ? this.product.pro_unidade_compra : this.get_packaging_by_code(this.product.pro_embalagem.split(' ')[0])
                this.genericWrap = this.isGenerico ? {name: this.product.pro_unidade_compra} : undefined;
                this.disabledInputGenerico = true;
            }

            this.unidadeModal = this.product.pro_unidade_compra;
            this.prodId = this.product.pro_id;
            this.codEanModal = this.product.pro_ean;
            this.qtdSugestaoModal = this.product.pro_sugestao;
            this.getStoresProcutSuggestion();
            if(!this.product.pro_id){
                this.getProductSuggestion();
            }

        },
        limpaCadastroProduto(){
            this.codigoModal = '';
            this.produtoModal = '';
            this.categoriaModal = '';
            this.embalagemModal = '';
            this.qtdEmbalagemModal = '';
            this.unidadeModal = '';
            this.isGenerico = false;
            this.serverMsg = '',
            this.errorCodigoModal = false,
            this.errorProdutoModal = false,
            this.errorEmbalagemModal = false,
            this.errorQtdEmbalagemModal = false,
            this.errorCategoriaModal = false,
            this.errorCodEanModal = false
        },
        getBuyers(){
          this.selectedBuyer = undefined
          if (!this.selectedProvider.value){
            this.buyers = [{text:'nenhum', value: {}}]
            return
          }
          this.sellers_svc.load_sellers({ provider_id: this.selectedProvider.value.id }).then(resp => {
              this.buyers = resp.data.map(element => {
              return {text: element.name, value: element}
            })
          })
        },
        load_providers() {
          this.psvc.loadProvidersNew({related: true}).then((response) => {
            this.providers.push({text: 'Nenhum', value: null})
            response.data.data.forEach(p => {
              this.providers.push( { text: p.name, value: p })
            })
          });
        },
        getProductSuggestion(){
            this.product_service.get_product_suggestion().then(data => {
                this.codEanModal = data.data.valorSugestao;
            }).catch(error => {
				ErrorHandlerService.handle( error, this.$store );
			});
        },
        toggle_generic(checked){
            this.wrap = '';
            this.codigoModal = '';
            if(checked){
                this.isGenerico = true;
                this.qtdEmbalagemModal = 1;
                this.wraps_load = this.wraps_generic;
            }else{
                this.isGenerico = false;
                this.wraps_load = this.wraps;
            }
        }
    },
    watch: {
        'wrap.value' : function(new_val) {
            if(new_val == 'UN') {
                this.qtdEmbalagemModal = 1
            } else if(new_val == 'DZ') {
                this.qtdEmbalagemModal = 12
            }
        },
        genericWrap(value){
          if (value) this.wrap = value.name
        },
        selectedProvider() {
          this.getBuyers()
        }
    },
    mounted() {
        this.wraps_load = this.wraps;
        if(this.product.pro_generico == 1 || this.internal_code_only){
            this.disabledInputs = true;
            if(this.internal_code_only) {
                this.edit_internal_code_allowed = true
            }
        }
        // this.selectedProvider = { text: this.product.pro_for_nome, value: true}
        // this.selectedBuyer = { text: this.product.pro_vend_nome, value: {id: this.product.pro_vend_id}}
        this.load_providers()
        this.getListaembalagens().then(() => this.load_categories().then(() => this.load_fields()))
    }
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.new-product-table-mobile-header{
  display: none;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.new-product__main-container {
  width: 80vw;
  height: 120vh;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5em;
}
.new-product-select-width{width: 35vw !important}
.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 3em;
  cursor: pointer;
}
.standard-buttons__wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin: 20px;
}
.standard-btn {
  margin-left: 20px;
}
.new-product__actions-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
  justify-content: space-between;
  margin: 0em 2em 1em 2em;
}
.new-product__actions-wrapper-centered {
  display: flex;
  align-items: center;
  margin-bottom: 2vh;
  justify-content: center;
  margin: 0em 2em 1em 2em;
}
.new-product-generic-input{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.edit-generic-icon{
  cursor: pointer;
  color: gray;
}
.new-product__input-wrapper {
  display: flex;
  flex-direction: column;
}
.new-product__input-title {
  color: #898989;
  font-weight: 400;
  font-size: 1.7em;
  letter-spacing: 0.15px;
  margin-bottom: 0.3em;
}
.new-product__input {
  width: 35vw;
  height: 5.5vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.2em;
  color: #A1A1A1;
}
.new-product__input-icon {
  position: relative;
  font-size: 1.7em;
  left: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #A1A1A1;
}
.new-product__generic-option-wrapper {
  display: flex;
  align-items: center;
  margin: 1em 2.2em;
}
.new-product__generic-checkbox-wrapper {}
.new-product__generic-title-wrapper {
  margin-left: 0.5em;
}
.new-product__generic-title {
  font-weight: 300;
  font-size: 1.3em;
  color: #605F5F;
}
.new-product__table-container {
  overflow-y: auto;
  margin: 2em 2em;
  max-height: 30vh;
}
.new-product__table-wrapper {}
.new-product__table-thead {}
.new-product__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 1.2em;
  font-weight: 400;
}
.new-product__table-th {
  font-size: 1.2em;
  font-weight: 600;
}
.erroInput{
  outline: 1px solid red;
  border-radius: 5px;
}
.new-product__table-tbody {}
.new-product__table-tbody-tr {}
.new-product__table-td {
  color: #605F5F;
  font-size: 1.2em;
  font-weight: 300;
}
.ressuply-products__edit-amount-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ressuply-products__edit-amount-icon {
  color: #fff;
  background-color: var(--primary-color);
  border: 1.5px solid #E5E5E5;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  position: relative;
  left: -5px;
  padding: 1px;
}
.product-amount {
  border: 1.5px solid #E5E5E5;
  margin-top: 6.2px;
  outline: none;
}
.focused-input:focus {
  border: 1.5px solid var(--primary-color);
}
.focused-input:focus-visible + .ressuply-products__edit-amount-icon {
  background-color: var(--primary-color);
}
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.page-table-line-special{
  background: #F7F7F7;
}

@media only screen and (max-width: 800px) {
  .new-product__main-container {
    width: 100vw;
    height: 115vh;
  }
  .standard-btn {
    margin: 5px;
  }
  .standard-buttons__wrapper {
    flex-direction: column-reverse;
  }
  .new-product__actions-wrapper {
    margin: 0em;
    justify-content: space-around;
  }
  .new-product__table-container {
    margin: 0 10px;
  }
  .new-product__table-container {
    max-height: 20vh;
  }
  .modal-mask{font-size: 2vw}
  .new-product__table-thead{display: none}
  .new-product__table-tbody-tr{
    flex-direction: column;
    display: flex;
    margin-bottom: 3vh;
    font-size: 1.3em;
  }
  .new-product__table-td{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%
  }
  .new-product-table-mobile-header{
    display: flex;
    font-size: 1em;
    font-weight: 700
  }
  .new-product__input-wrapper{
    width: 50vw;
  }
  .new-product__input-title{
    font-size: 1.5em;
  }
  .new-product__input{
    width: 90%;
  }
  .new-product-select-width{
    width: 90% !important;
  }
  .ressuply-products__edit-amount-wrapper{
    width: 20%;
  }
  .product-amount{
    width: 15vw !important;
  }
  .desktop{display: none;}
  .new-product-table-mobile-header{
    width: max-content;
  }
}
@media only screen and (max-width: 500px) {
  .modal-mask{font-size: 3vw}
  .new-product__main-container{width: 110vw}
}
@import "./new-product.modal";
</style>
