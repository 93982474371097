<template>
    <div class="first-tab-container">
    <table>
           <tr>
                <th style="width: 10%;">Id</th>
                <th style="width: 50%;">Nome</th>
                <th>Marca</th>
                <th>Data limite</th>
            </tr>
            <tr class="product-text" v-for="(p, idx) in products" :key="idx">
                <td>{{ p.pro_id }}</td>
                <td>{{ p.produto.pro_descricao }}  </td>
                <td>({{ p.produto.pro_observacao || '--' }})</td>
                <td>{{ p.data_limite }}</td>
            </tr> 
        </table>
    </div>
</template>

<script>
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'FirstTab',
    mounted() {
        this.loadPage()
    },
    props: {
        date: Object,
        setLoading: Function
    },
    data() {
        return {
            products: []
        }
    },
    methods: {
        loadPage() {
            this.setLoading(true)
            var requestUrl =
                `${CONSTANTS.API_V3_URL}/clients/dashboard/det/1_compra?datalimite=${this.date.primeiro_dia}`
            axios.get(requestUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(resp => {
                this.setLoading(false)
                this.products = resp.data
            });
        },
        updatePage(page) {
            this.page = page
            this.loadPage()
        }
    },
}
</script>

<style lang="scss" scoped>
.product-text {
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vh;
}
th{
    font-size: 1rem;
}
.first-tab-container {
    height: 70vh;
    padding: 2vh 3vw;
}
</style>