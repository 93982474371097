<template>
    <div class="identifier-filters">
        <div class="identifier-filters-text">{{ text }}</div>
        <span v-if="action" v-on:click="action()" class="material-icons identifier-filters-icon">close</span>
    </div>
</template>

<script>
    export default {
        name:"showIdentifier",
        props: {
            text: String,
            action: Function
        },
    }
</script>

<style lang="scss" scoped>
.identifier-filters {
    background: #fcf9f7;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    width: fit-content;
}

.identifier-filters-text {
    font-weight: 300;
    font-size: 1.19em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--primary-color);
    margin-right: 10px;
}

.identifier-filters-icon {
    color: var(--primary-color);
    cursor: pointer;
}
</style>